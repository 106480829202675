import { LoginRequest } from 'src/app/model/request/auth.request';

import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { mergeMap, map, catchError, concatMapTo, tap } from "rxjs/operators";

import { Action } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from "src/app/core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginUser, LoginUserFailure, LoginUserSuccess, UserActionTypes, LogOutUser, ResetUserState, Donothing, LogOutClearingUser } from './userdata.actions';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { GeneralUpdateError, ResetErrorState } from '../errormessages/errormessages.actions';
import { RESETBULKTRANSFERSTATE } from '../bulktransfers/bulktransfers.actions';
import { ResetCardsDataState } from '../carddata/carddata.actions';
import { ResetDashboardState } from '../dashboarddata/dashboarddata.actions';
import { ResetRequestMoneyLink } from '../requestmoneylink/requestmoneylink.actions';
import { ResetSettingsdataState } from '../settingsdata/settingsdata.actions';
import { ResetSubwalletState } from '../subwallets/subwallets.actions';
import { ResetTranasactionsDataState } from '../transactions/transactions.actions';
import * as dayjs from "dayjs";


@Injectable({
  providedIn: "root"
})
export class UserdataEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private loader: NgxSpinnerService,
    private userService: UserService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,) { }

  @Effect()
  loginUser$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.LoginUser),
    map((action: LoginUser) => action.payload),
    mergeMap((loginRequest) => {
      this.loader.show();

      return this.userService.login(loginRequest).pipe(
        map(data => {
          this.loader.hide();
          console.log(data, 'lated');
          
          if(data.otpRequired) {
            let genErrorData = {
              loginerror: {
                message: '',
                otpRequired: true
              }
            };
            return new GeneralUpdateError(genErrorData);
          }


          if (data.isSwitchDevice) {
            let loginData: LoginRequest = {
              ...loginRequest,
              isSwitchDevice: true
            }
            this.router.navigateByUrl("/authenticate/switchdevice", { state: loginData });
            return new Donothing();
          } else if (data.twoFactorAuthRequired) {
            let loginData: LoginRequest = {
              ...loginRequest,
              isSwitchDevice: false
            }


            loginData

            this.router.navigateByUrl("/authenticate/twofa", { state: loginData });
            return new Donothing();
          }
          this.analyticsService.trackEvent(
            "Auth",
            "Successfully Login user",
            loginRequest.email,
            10
          );


          localStorage.setItem("token", data.token);
          data.data.istwoFactorAuthOn = data.data.twoFactorTransactionsAuthEnabled;
          data.data.twoFactorAuthEnabled=data.data.twoFactorTransactionsAuthEnabled;
          this.router
            .navigate(["/dashboard"], {
              state: {
                userdata: JSON.stringify(data.data),
              },
            })
            .then((r) => {
              return () => { };
            }).catch((error) => {

            });

          let timeP = dayjs().add(29, 'minute');

          data.expiresin = timeP.format();
          return new LoginUserSuccess(data);
        }),
        catchError(error => {
          console.log(error);

          this.loader.hide();
          let message =
            error.error.message === ""
              ? "Invalid email or passsword, please try again."
              : error.error.message;


          this.analyticsService.trackEvent(
            "Auth",
            error.error.message,
            loginRequest.email,
            10
          );

          let genErrorData = {
            loginerror: {
              message: error.error.message
            }
          };

          return of(new GeneralUpdateError(genErrorData));
        })
      );
    }
    )
  );


  @Effect()
  logoutUser$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.LogOutUser),
    map((action: LogOutUser) => null),
    mergeMap(() => {
      localStorage.clear();
      this.modalService.dismissAll();
      this.router.navigate(["/login"]);
      return [
        // tslint:disable-next-line: new-parens
        new ResetUserState(),
        new RESETBULKTRANSFERSTATE(),
        new ResetCardsDataState(),
        new ResetDashboardState(),
        new ResetErrorState(),
        new ResetRequestMoneyLink(),
        new ResetSettingsdataState(),
        new ResetSubwalletState(),
        new ResetTranasactionsDataState()
      ]

    })
  );






}
