import { CryptoActions, CryptoActionTypes } from './crypto.actions'
import { WebStorageService } from 'src/app/service/web-storage.service';
import { CRYPTO_CONSTANTS, LOCAL_STORAGE_ITEMS } from 'src/app/config/constants';
  

export interface CryptoState {
  cryptoAddressCreated: any;
  error: any;
  exchangeRates: any[];
  fundWalletResponse: any;
  withdrawFromWalletResponse: any;
  transferToCryptoAddressResponse: any;
  cryptoTransactions: any;
  cryptoCurrencyWallet: any;
  BTCWallet: any;
}

const initialState: CryptoState = {
  cryptoAddressCreated: null,
  error: null,
  exchangeRates: [],
  fundWalletResponse: null,
  withdrawFromWalletResponse: null,
  transferToCryptoAddressResponse: null,
  cryptoTransactions: [],
  cryptoCurrencyWallet: null,
  BTCWallet: null
};

export function reducer(state = initialState, action: CryptoActions): CryptoState {

  switch (action.type) {
    case CryptoActionTypes.CreateCryptoAddressSuccess:
      // const cryptoWalletCreated = { 
      //   Address: action.payload['Address'],
      //   CurrencyType: action.payload['CurrencyType'] 
      // };
      return {
        ...state,
        cryptoAddressCreated: action.payload,
        cryptoCurrencyWallet: action.payload,
        error: ''
      };
    case CryptoActionTypes.CreateCryptoAddressFailure:
      return {
        ...state,
        error: action.payload,
        cryptoAddressCreated: null
      };
    case CryptoActionTypes.FundCryptoWallet:
      return {
        ...state,
        fundWalletResponse: null,
        error: ''
      };
    case CryptoActionTypes.FundCryptoWalletSuccess:
      // const cryptoWallet = {...state.cryptoCurrencyWallet, availableBalance: action.payload['currentBalance'] };
      // const prevTransactions = state.cryptoTransactions['totalItems'] > 4 ? [ ...state.cryptoTransactions['items'].slice(0,4) ] :[ ...state.cryptoTransactions['items']];
      return {
        ...state,
        fundWalletResponse: action.payload,
        // cryptoCurrencyWallet: cryptoWallet,
        // cryptoTransactions: { totalItems: state.cryptoTransactions['totalItems'] + 1, items: [ action.payload, ...prevTransactions ] },
        error: ''
      };
    case CryptoActionTypes.FundCryptoWalletFailure:
      return {
        ...state,
        // cryptoWallets: [],
        fundWalletResponse: null,
        error: action.payload
      };
    case CryptoActionTypes.WithdrawFromCryptoWallet:
      return {
        ...state,
        withdrawFromWalletResponse: null,
        error: ''
      };
    case CryptoActionTypes.WithdrawFromCryptoWalletSuccess:
      // const cryptoCurrencyWallet = {...state.cryptoCurrencyWallet, availableBalance: action.payload['currentBalance'] };
      // const prevTrxns = state.cryptoTransactions['totalItems'] > 4 ? [ ...state.cryptoTransactions['items'].slice(0,4) ] :[ ...state.cryptoTransactions['items']];
      return {
        ...state,
        // cryptoCurrencyWallet: cryptoCurrencyWallet,
        withdrawFromWalletResponse: action.payload,
        // cryptoTransactions: { totalItems: state.cryptoTransactions['totalItems'] + 1, items: [ action.payload, ...prevTrxns ] },
        error: ''
      };
    case CryptoActionTypes.WithdrawFromCryptoWalletFailure:
      return {
        ...state,
        withdrawFromWalletResponse: null,
        error: action.payload
      };
    case CryptoActionTypes.TransferToCryptoAddress:
      return {
        ...state,
        transferToCryptoAddressResponse: null,
        error: ''
      };
    case CryptoActionTypes.TransferToCryptoAddressSuccess:
      // const userCryptoWallet = {...state.cryptoCurrencyWallet, availableBalance: action.payload['currentBalance'] };
      // const currentTrxns = state.cryptoTransactions['totalItems'] > 4 ? [ ...state.cryptoTransactions['items'].slice(0,4) ] :[ ...state.cryptoTransactions['items']];
      return {
        ...state,
        // cryptoCurrencyWallet: userCryptoWallet,
        transferToCryptoAddressResponse: action.payload,
        // cryptoTransactions: { totalItems: state.cryptoTransactions['totalItems'] + 1, items: [ action.payload, ...currentTrxns ] },
        error: ''
      };
    case CryptoActionTypes.TransferToCryptoAddressFailure:
      return {
        ...state,
        // cryptoWallets: [],
        transferToCryptoAddressResponse: null,
        error: action.payload
      };
    case CryptoActionTypes.GetUserCryptoWalletSuccess:
      return {
        ...state,
        BTCWallet: action.payload,
        error: ''
      };
    case CryptoActionTypes.GetUserCryptoWalletFailure:
      return {
        ...state,
        BTCWallet: null,
        error: action.payload
      };
    case CryptoActionTypes.GetCryptoToFiatExchangeRateSuccess:
      return {
        ...state,
        exchangeRates: [...state.exchangeRates, action.payload],
        error: ''
      };
    case CryptoActionTypes.GetCryptoToFiatExchangeRateFailure:
      return {
        ...state,
        exchangeRates: [],
        error: action.payload
      };
    case CryptoActionTypes.GetUserCryptoTransactionsSuccess:
      return {
        ...state,
        cryptoTransactions: action.payload,
        error: ''
      };
    case CryptoActionTypes.GetUserCryptoTransactionsFailure:
      return {
        ...state,
        cryptoTransactions: [],
        error: action.payload
      };
    default:
      return {
        ...state
      };
  }
}    