import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class UtilitySerivce {

    constructor(private spinner: NgxSpinnerService) {

    }

    logger(data) {

    }

}
