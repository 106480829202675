
import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from "src/app/core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { FETCHSETTINGSDATA, SettinfgsActionTypes, UPDATESETTINGSDATA } from './settingsdata.actions';
import { Observable, of } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { UPDATEUSERSTRANSACTIONS } from '../transactions/transactions.actions';
import { Donothing } from '../userdata/userdata.actions';
import { SettingsProvider } from 'src/app/service/settings.service';
import { UpdataCurrentUserBalance } from '../dashboarddata/dashboarddata.actions';
import { documentUpledStatus } from 'src/app/model/response/settings.response';

@Injectable({
  providedIn: "root"
})
export class SettingsdataEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private loader: NgxSpinnerService,
    private userService: UserService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService, private settingsService: SettingsProvider) { }

  fetchSettingsdata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettinfgsActionTypes.FETCHSETTINGSDATA),
      mergeMap(() =>
        this.settingsService.getSettingsPageData().pipe(
          map(data => {
            data.isLoading=false;
            data.businessdocuments = {
              cacFile:documentUpledStatus.notuploaded,
              co2File:documentUpledStatus.notuploaded,
              mermatFile:documentUpledStatus.notuploaded,
              directorsID:documentUpledStatus.notuploaded,
              utilityBill:documentUpledStatus.notuploaded,
              applicationdoc:documentUpledStatus.notuploaded,
              c11File:documentUpledStatus.notuploaded,
              certificateofReg:documentUpledStatus.notuploaded,
              directorsID2:documentUpledStatus.notuploaded,
            }
            if (!data.documentsUploaded || data.documentsUploaded == null) {
              data.documentsUploaded = []
            }

            for (let index = 0; index < data.documentsUploaded.length; index++) {
              const docs = data.documentsUploaded[index];


              if (docs.documentType == "CACCertificate") {

                data.businessdocuments.cacFile = docs.status;

              }
              if (docs.documentType == "FormC02") {

                data.businessdocuments.co2File = docs.status;
              }
              if (docs.documentType == "MermatFile") {

                data.businessdocuments.mermatFile = docs.status;

              }
              if (docs.documentType == "DirectorsId") {

                data.businessdocuments.directorsID = docs.status;
              }
              if (docs.documentType == "UtilityBill") {

                data.businessdocuments.utilityBill = docs.status;
              }

              if (docs.documentType == "Form11") {

                data.businessdocuments.c11File = docs.status;
              }
              if (docs.documentType == "Director2Id") {

                data.businessdocuments.directorsID2 = docs.status;
              }
              if (docs.documentType == "ApplicationForRegisterationOfBusiness") {

                data.businessdocuments.applicationdoc = docs.status;
              }

              if (docs.documentType == "CertificateOfRegisteration") {

                data.businessdocuments.certificateofReg = docs.status;
              }

            }

            return new UPDATESETTINGSDATA(data);
          }),
          catchError(err => {
            return of(new Donothing());
          })
        )
      )
    )
  );


}
