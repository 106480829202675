import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import {
  AllTransactionsResponse,
  transactionType,
  Transferdetails,
} from "../model/response/transactions.response";
import { EndPoints } from "../config/endpoints";
import { catchError, map, retry } from "rxjs/operators";
import { DashboardStatsResponse } from "../model/response/dashboard.response";

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) {}

  getAllTransaction(
    parameters: string = ""
  ): Observable<AllTransactionsResponse> {
    return this.http
      .get<AllTransactionsResponse>(
        EndPoints.TRANSACTIONS.GET_ALL_TRANSACTIONS + parameters,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getAllTransactionToday(): Observable<DashboardStatsResponse> {
    return this.http
      .get<DashboardStatsResponse>(
        EndPoints.DASHBOARD.GET_ALL_TRANSACTIONSToday,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          console.log("today" + data);
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getAllTransactionYesterday(): Observable<DashboardStatsResponse> {
    return this.http
      .get<DashboardStatsResponse>(
        EndPoints.DASHBOARD.GET_ALL_TRANSACTIONSYesterday,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getAllTransactionThisMonth(): Observable<DashboardStatsResponse> {
    return this.http
      .get<DashboardStatsResponse>(
        EndPoints.DASHBOARD.GET_ALL_TRANSACTIONSThisMonth,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getSingleTransactionDetail(parameters: string): Observable<Transferdetails> {
    return this.http
      .get<Transferdetails>(
        EndPoints.TRANSACTIONS.GET_TRANSACTIONS_DETAILS + parameters,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getTransferStatus(parameters: string): Observable<any> {
    return this.http
      .get<Transferdetails>(
        EndPoints.TRANSACTIONS.GET_BANK_TRANSFER_STATUS +
          `?reference=${parameters}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        retry(3),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  reportTransaction(model): Observable<Transferdetails> {
    return this.http
      .post<Transferdetails>(EndPoints.TRANSACTIONS.REPORT_TRANSACTION, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  shareReceipt(model): Observable<any> {
    return this.http
      .post<Transferdetails>(EndPoints.TRANSACTIONS.SHARE_RECEIPT, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getAPITransaction(): Observable<AllTransactionsResponse> {
    let data: AllTransactionsResponse = {
      allTransactions: [
        {
          date: "01/11/2019 11:57:19",
          accountName: "Adeola Lawal",
          accountNumber: "0049388437",
          bank: "Access Bank",
          reference: "W798300",
          amount: "100000000",
        },

        {
          date: "01/11/2019 11:57:19",
          accountName: "Aisha Dawodu",
          accountNumber: "0049388437",
          bank: "GTBank",
          reference: "G675140",
          amount: "100000",
        },

        {
          date: "01/11/2019 11:57:19",
          accountName: "Bisola Oladeinde",
          accountNumber: "0049388437",
          bank: "UBA",
          reference: "P209920",
          amount: "100000000",
        },

        {
          date: "01/11/2019 11:57:19",
          accountName: "Joseph Benson - Aruna",
          accountNumber: "0049388437",
          bank: "Zenith",
          reference: "U378582",
          amount: "768900",
        },
      ],
    };
    return of(data);

    // return this.http.get<AllTransactionsResponse>(
    //   EndPoints.TRANSACTIONS.GET_ALL_TRANSACTIONS,
    //   {
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   }
    // )
    // .pipe(map(data => {
    //   return data;
    // }), catchError((error) => {
    //   return Observable.throw(error);
    // }));
  }

  getAirtimeTransaction(): Observable<AllTransactionsResponse> {
    let data: AllTransactionsResponse = {
      allTransactions: [
        {
          date: "01/11/2019 11:57:19",
          biller: "Airtel",
          category: "Internet",
          reference: "W798300",
          amount: "100000",
        },

        {
          date: "01/11/2019 11:57:19",
          biller: "MTN",
          category: "Airtime",
          reference: "G675140",
          amount: "100000",
        },

        {
          date: "01/11/2019 11:57:19",
          biller: "Smile",
          category: "Internet",
          reference: "P209920",
          amount: "100000",
        },

        {
          date: "01/11/2019 11:57:19",
          biller: "EKEDC",
          category: "Utilities",
          reference: "U378582",
          amount: "1000",
        },

        {
          date: "01/11/2019 11:57:19",
          biller: "LIRS",
          category: "Taxes",
          reference: "N105913",
          amount: "768900",
        },
      ],
    };
    return of(data);

    // return this.http.get<AllTransactionsResponse>(
    //   EndPoints.TRANSACTIONS.GET_ALL_TRANSACTIONS,
    //   {
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   }
    // )
    // .pipe(map(data => {
    //   return data;
    // }), catchError((error) => {
    //   return Observable.throw(error);
    // }));
  }
}
