import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CryptoCurrencyType } from '../../../../model/request/crypto_currency.type';
import { ModalTitle, DefaultModalTitle } from 'src/app/shared/model/modal.ui';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';

import * as cryptoReducer from '../../../../state/crypto/crypto.reducer';
import * as cryptoActions from '../../../../state/crypto/crypto.actions';
import * as fromCrypto from '../../../../state/crypto/index';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-create-crypto-wallet-modal',
  templateUrl: './create-crypto-wallet-modal.component.html',
  styleUrls: ['./create-crypto-wallet-modal.component.scss']
})
export class CreateCryptoWalletModalComponent implements OnInit {

  createCryptoWalletForm: FormGroup;
  modalContent: ModalTitle = DefaultModalTitle;
  cryptoCurrencyType: CryptoCurrencyType;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
  successSubtitle: string;
  successTitle: string;
  componentActive = true;
  createBtnText: string;
  formInvalid: boolean;

  constructor(public activeModal: NgbActiveModal,
              private store: Store<cryptoReducer.CryptoState>) { }

  ngOnInit() {
    this.initializeForm();
    this.createCryptoWallet();
  }

  private initializeForm() {
    this.createCryptoWalletForm = new FormGroup({
      WalletName: new FormControl(''),
      CryptoCurrencyType: new FormControl(Number(this.cryptoCurrencyType), [Validators.required]),
    });
  }

  createCryptoWallet() {
    if(this.createCryptoWalletForm.valid) {

        this.store.dispatch(new cryptoActions.CreateCryptoAddress());

        this.store.pipe(select(fromCrypto.getCreatedAddress),
        takeWhile(() => this.componentActive))
        .subscribe(res => {

          if(res != null) {
            this.isSuccess = true;
            this.successSubtitle = this.cryptoCurrencyType == CryptoCurrencyType.Bitcoin? 'You now have a BTC Wallet' : 'ETH Wallet Creation Successful';
            this.successTitle= this.cryptoCurrencyType == CryptoCurrencyType.Bitcoin? `We have created a BTC Wallet for you, and it's free!!!`: 'Your ETH Wallet was created successfully';
          }

          if(res == null) {
            this.store.pipe(select(fromCrypto.getError),
            takeWhile(() => this.componentActive))
            .subscribe(err => {

              this.isError = true;
              this.errorMessage = err.message;
            })
          }
        });
    } else {
        this.formInvalid = true;
    }
  }

}
