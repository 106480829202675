import {
  BankListandCodeDo,
  ValidateBankAccountDo,
  BankTransferDo,
  BulkWalletListRecipientDO,
  BulkBankListRecipientDO,
  BulkInternationalListRecipientDO,
  ConvertInternationalFeesDo,
  InternationalBankModel,
  InternationalRatesModel,
  InternationalBankTransferRequest,
  InternationalBankTransferResponse,
  InternationalBranchesModel, SinglebusinesstransferDo, AllBussinessDataDo
} from "./../model/response/transfer.response";
import {
  ValidateWalletNumberDto,
  WalletTransferDto,
  ValidateBankAccountDto,
  BankTransferDTO,
  BulkBankListRecipientDTO,
  BulkWalletListRecipientDTO,
  DeleteBulkListDto, BulkTransferDto, ConvertInternationalFeesDto, SingleInternationalTransferDto, AddtoWalletBulkList, AddtoBankBulkList, AddtoInternationalBulkList, DeleteRecipientFromBulkList, Maketransfertobusiness
} from "./../model/request/transfer.request";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { WalletRecipientDO, ValidateWalletNumberDO, WalletTransferDo } from "../model/response/transfer.response";
import { catchError, map } from "rxjs/operators";
import { EndPoints } from "../config/endpoints";
import { throwError } from "rxjs";
import { DashboardService } from "./dashboard.service";

@Injectable()
export class TransferProvider {
  constructor(private http: HttpClient, private dashBoardService: DashboardService) {
  }

  getBanks(): Observable<Array<BankListandCodeDo>> {
    return this.http.get(EndPoints.TRANSFER.BANK.GET_BANKS, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  getInternationalBanks(code: string): Observable<Array<InternationalBankModel>> {
    return this.http.get(`${EndPoints.INTERNATIONAL_TRANSFER.BANK.GET_BANKS}${code}`, {
      headers: {
        "Content-Type": "application/json"
      }
    }).pipe(map(data => data as any), catchError(error => throwError(error)));
  }

  getBankBranchesById(id: number): Observable<Array<InternationalBranchesModel>> {
    return this.http.get(`${EndPoints.INTERNATIONAL_TRANSFER.BANK.GET_BRANCHES}${id}`, {
      headers: {
        "Contenty-Type": "application/json"
      }
    }).pipe(map(data => data as any), catchError(error => throwError(error)));
  }

  getInternationalRates(from: string, to: string, amount: number): Observable<InternationalRatesModel> {
    return this.http.post(EndPoints.INTERNATIONAL_TRANSFER.RATES, {
      FromCurrency: from,
      ToCurrency: to,
      Amount: amount
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    }).pipe(map(data => data as InternationalRatesModel), catchError(error => throwError(error)));
  }

  getBanksBeneficiaries(): Observable<Array<WalletRecipientDO>> {
    return this.http.get(EndPoints.TRANSFER.BANK.GET_FREQUENT_BENEFICIARIES, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  getBulkBankBeneficaries(): Observable<Array<BulkBankListRecipientDO>> {
    return this.http.get(EndPoints.TRANSFER.BULKBANK.GET_BENEFICIARIES, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }


  getBulkInternationalBeneficaries(): Observable<Array<BulkInternationalListRecipientDO>> {
    return this.http.get(EndPoints.TRANSFER.BULKINTERNATIONAL.GET_BENEFICIARIES, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  getBulkWalletBenefciaries(): Observable<Array<BulkWalletListRecipientDO>> {
    return this.http.get(EndPoints.TRANSFER.BULKWALLET.GET_BENEFICIARIES, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  createBulkWalletListGoogleSheet(model: BulkWalletListRecipientDTO): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKWALLET.CREATE_BULK_LIST, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  addRecipienttoWalletList(model: AddtoWalletBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKWALLET.ADD_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  addRecipientToBankList(model: AddtoBankBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKBANK.ADD_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  addRecipienttoInternationalList(model: AddtoInternationalBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKINTERNATIONAL.ADD_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }


  editRecipienttoWalletList(model: AddtoWalletBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKWALLET.EDIT_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  editRecipientToBankList(model: AddtoBankBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKBANK.EDIT_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  editRecipienttoInternationalList(model: AddtoInternationalBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKINTERNATIONAL.EDIT_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }


  deleteRecipienttoWalletList(model: DeleteRecipientFromBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKWALLET.DELETE_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  deleteRecipientToBankList(model: DeleteRecipientFromBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKBANK.DELETE_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  deleteRecipienttoInternationalList(model: DeleteRecipientFromBulkList): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKINTERNATIONAL.DELETE_RECIPIENT, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }


  createBulkBankListGoogleSheet(model: BulkBankListRecipientDTO): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKBANK.CREATE_BULK_LIST, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  createBulkInternationalListGoogleSheet(model: BulkBankListRecipientDTO): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKINTERNATIONAL.CREATE_BULK_LIST, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  createBulkWalletListExcel(model): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKWALLET.CREATE_BULK_LIST_VIA_FILE, model)
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }
  createBulkBankListExcel(model): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKBANK.CREATE_BULK_LIST_VIA_FILE, model)
      .pipe(map(data => {
        return data as ValidateWalletNumberDO;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  createBulkInternationalListExcel(model): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKINTERNATIONAL.CREATE_BULK_LIST_VIA_FILE, model)
      .pipe(map(data => {
        return data as ValidateWalletNumberDO;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  bulkWalletTranfer(model: BulkTransferDto): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKWALLET.BULK_TRANSFER, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  bulkBankTranfer(model: BulkTransferDto): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKBANK.BULK_TRANSFER, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  bulkInternationalTranfer(model: BulkTransferDto): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKINTERNATIONAL.BULK_TRANSFER, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  deleteBulkWalletList(model: DeleteBulkListDto): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKWALLET.DELETE_BULK_LIST, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  deleteBulkBankList(model: DeleteBulkListDto): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKBANK.DELETE_BULK_LIST, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }


  deleteBulkInternationalList(model: DeleteBulkListDto): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.BULKINTERNATIONAL.DELETE_BULK_LIST, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }


  getWalletRecipients(): Observable<Array<WalletRecipientDO>> {
    return this.http.get(EndPoints.TRANSFER.WALLET.GET_FREQUENT_BENEFICIARIES, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  validateBankAccount(model: ValidateBankAccountDto): Observable<ValidateBankAccountDo> {
    return this.http.post(EndPoints.TRANSFER.BANK.ACCOUNT_ENQUIRE, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as ValidateBankAccountDo;
      }), catchError(error => {
        return throwError(error);
      }));
  }


  singleBankTransfer(model: BankTransferDTO): Observable<BankTransferDo> {
    return this.http.post(EndPoints.TRANSFER.BANK.TRANSFER, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {

        this.dashBoardService.fetchTheNewBalance();
        return data as BankTransferDo;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  internationalBankTransfer(model: InternationalBankTransferRequest): Observable<InternationalBankTransferResponse> {
    return this.http.post(EndPoints.INTERNATIONAL_TRANSFER.TRANSFER.SINGLE, model, {
      headers: {
        "Content-Type": "application/json"
      }
    }).pipe(map(data => {
      this.dashBoardService.fetchTheNewBalance();
      return data as InternationalBankTransferResponse;
    }), catchError(error => throwError(error)));
  }

  singleinternationalTransfer(model: SingleInternationalTransferDto): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.INTERNATIONAL.TRANSFER, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {

        this.dashBoardService.fetchTheNewBalance();
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  // authorizeTransaction(model: AuthorizeTransferRequest): Observable<AuthorizeTransactionDO> {
  //     model.DeviceId = this.deviceData.Id;
  //     return this.http.post<AuthorizeTransactionDO>(EndPoints.SEND_MONEY.AUTHORIZE_TRANSACTION, model, {
  //         headers: {
  //             'Content-Type': 'application/json'
  //         }
  //     })
  //         .pipe(map(data => {
  //             return data;
  //         }), catchError(error => {
  //             return Observable.throw(error);
  //         }));
  // }

  singleWalletTransfer(model: WalletTransferDto): Observable<WalletTransferDo> {
    return this.http.post(EndPoints.TRANSFER.WALLET.TRANSFER, model, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .pipe(map(data => {

        this.dashBoardService.fetchTheNewBalance();
        return data as WalletTransferDo;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  ValidatetWalletPhoneNumber(model: ValidateWalletNumberDto): Observable<ValidateWalletNumberDO> {
    return this.http.post(EndPoints.TRANSFER.WALLET.VALIDATE_PHONE_NUMBER, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as ValidateWalletNumberDO;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  ValidatetInternationFees(model: ConvertInternationalFeesDto): Observable<ConvertInternationalFeesDo> {
    return this.http.post(EndPoints.TRANSFER.INTERNATIONAL.CONVERTCURRENCY, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as ConvertInternationalFeesDo;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  getInternationalBeneficiaries(): Observable<Array<ValidateWalletNumberDO>> {
    return this.http.get(EndPoints.TRANSFER.INTERNATIONAL.GET_FREQUENT_BENEFICIARIES, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }

  ValidateBusiness(Email: string,Amount:number): Observable<SinglebusinesstransferDo> {
    return this.http.post(`${EndPoints.TRANSFER.TRANSFER_TO_BUSINESS.VALIDATE_BUSINESS}`, { Email,Amount }, {

      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  searchforBusiness(model): Observable<AllBussinessDataDo> {
    return this.http.get(`${EndPoints.TRANSFER.TRANSFER_TO_BUSINESS.SEARCH_BUSIBESS}?${model}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  transferMoneytoBusiness(model: Maketransfertobusiness): Observable<any> {
    return this.http.post(EndPoints.TRANSFER.TRANSFER_TO_BUSINESS.TRANSFER_TO_BUSINESS, model, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {

        this.dashBoardService.fetchTheNewBalance();
        return data as any;
      }), catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }));
  }

  getTransferToBusinessBeneficiaries(): Observable<Array<any>> {
    return this.http.get(EndPoints.TRANSFER.TRANSFER_TO_BUSINESS.GET_FREQUENT_BENEFICIARIES, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError(error => {
        return throwError(error);
      }));
  }

}
