import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location, JsonPipe } from "@angular/common";
import { filter } from "rxjs/operators";
import { Observable, BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class FormatService {
  private loadingData = new BehaviorSubject<any>({
    showToast: false,
    message: "",
    type: "none",
  });
  constructor(private location: Location, private router: Router) {}

  formatNumberToCurrency(amount: number) {
    let newAmount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return newAmount;
  }

  showToasts(message, type) {
    this.loadingData.next({ showToast: true, message, type });
    setTimeout(() => {
      this.loadingData.next({ showToast: false, message: "", type: "none" });
    }, 3000);
  }

  getToastState(): Observable<any> {
    return this.loadingData.asObservable();
  }

  firstEight(message){
    return message.substring(0, 8)
  }

}

// check this slack for more formatters
// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-string
