import { Injectable } from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import { Observable } from "rxjs";

import { UserService } from "./user.service";
import { take } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private userService: UserService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
      if (localStorage.getItem("token")) {
        return true;
      }

      // navigate to login page
      this.router.navigate(["/login"]).then(r => {
        return () => {

        };
      });
      // you can save redirect url so after authing we can move them back to the page they requested
      return false;
    }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
