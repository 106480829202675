import {Directive, ElementRef, Input} from "@angular/core";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[appChangeText]"
})

export class ChangeTextDirective {
  constructor(private el: ElementRef) {
    this.changeText("Changed");
  }

  @Input() changedText: string;

  private changeText(text: string) {
    this.el.nativeElement.innerText = text;
  }

}
