import { AllsubwalletDo } from 'src/app/model/response/subwallet.response';
import { SubwalletActionTypes, SubwalletdataActions } from './subwallets.actions';


export interface SubwalletState extends AllsubwalletDo {
  message?: string;
}



const initialState: SubwalletState = {
  isLoading: true,
  isFilter: false,
  isFirstLoad: false,
  users: [],
  page: {
    totalPages: 0,
  },
};

export function Subwalletdatareducer(state = initialState, action: SubwalletdataActions): SubwalletState {

  switch (action.type) {

    case SubwalletActionTypes.ResetSubwalletState:
      return {
        ...initialState,
      };
      break;
    case SubwalletActionTypes.OnLoadingSubwalletList:
      return {
        ...state,
        isLoading: true
      };
      break;
    case SubwalletActionTypes.AddNewSubwallet:

      return { ...state, users: [...state.users, action.payload] }
      break;

    case SubwalletActionTypes.UpdateSingleSubwallet:
      let userData1 = action.payload
      let allUsers1 = state.users;
      let users = allUsers1.map((user, i) => {
        if (user.phoneNumber == userData1.phoneNumber) {
          return userData1;
        }
        return user;
      });



      return {
        ...state,
        users: [...users]
      }
      break;
    case SubwalletActionTypes.DeleteSubwallet:
      let userData2 = action.payload
      let allUsers2 = state.users;
      let users2 = allUsers2.map((user, i) => {
        if (user.phoneNumber == userData2.phoneNumber) {
          return userData2;
        }
        return user;
      });
      return {
        ...state,
        users: [...users]
      }
      break;
    case SubwalletActionTypes.UpdateSubwallets:

      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
}

