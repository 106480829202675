import { AllsubwalletDo } from "./../model/response/subwallet.response";
import {
  CreateuserDto,
  GenerateuserDto,
  PhoneuserDto,
  CreditDto,
  Debitdto,
  PndRequest,
} from "./../model/request/subwallet.request";

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { EndPoints } from "../config/endpoints";
import { catchError, map } from "rxjs/operators";
import {
  SubwalletUserDo,
  Createuser,
  SubwalletuserBalanceDo,
  TransactionDo,
} from "../model/response/subwallet.response";
import {
  transaction,
  AllTransactionsResponse,
} from "../model/response/transactions.response";
import { DashboardService } from "./dashboard.service";

@Injectable()
export class SubwalletProvider {
  constructor(
    private http: HttpClient,
    private dashBoardService: DashboardService
  ) {}

  getAlluser(page, pageSize, searchText): Observable<AllsubwalletDo> {
    let UrlParam=`?page=${page}&pageSize=${pageSize}`;
    if(searchText){
      UrlParam=`?page=${page}&pageSize=${pageSize}&searchText=${searchText}`;
    }
    return this.http
      .get<any>(
        `${EndPoints.SUBWALLETS.GET_ALL_USERS}${UrlParam}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getuserTransactions(
    phonenumber: string,
    parameters: string
  ): Observable<AllTransactionsResponse> {
    return this.http
      .get<any>(
        `${EndPoints.SUBWALLETS.TRANSACTIONS}?phoneNumber=${phonenumber}${parameters}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getuserBalance(model): Observable<SubwalletuserBalanceDo> {
    return this.http
      .post(EndPoints.SUBWALLETS.GET_BALANCE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deactivateUser(model): Observable<SubwalletuserBalanceDo> {
    return this.http
      .post(EndPoints.SUBWALLETS.DEACTIVATE_USER, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  activateUser(model): Observable<SubwalletuserBalanceDo> {
    return this.http
      .post(EndPoints.SUBWALLETS.ACTIVATE_USER, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deactivateUserPND(model): Observable<SubwalletuserBalanceDo> {
    return this.http
      .post(EndPoints.SETTINGS.REMOVEPND, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  activateUserPND(model): Observable<SubwalletuserBalanceDo> {
    return this.http
      .post(EndPoints.SETTINGS.ADDPND, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  resetUserPass(model: string): Observable<any> {
    return this.http
      .get(`${EndPoints.SUBWALLETS.RESET_PASSWORD}?phonenumber=${model}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  generateAccount(model: GenerateuserDto): Observable<SubwalletUserDo> {
    return this.http
      .post(EndPoints.SUBWALLETS.GENERATE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data: any) => {
          return data as any;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  editAccount(model: GenerateuserDto): Observable<SubwalletUserDo> {
    return this.http
      .post(EndPoints.SUBWALLETS.EDIT_USER, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data: any) => {
          return data as any;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  getUserDetails(model: PhoneuserDto): Observable<SubwalletUserDo> {
    return this.http
      .post(EndPoints.SUBWALLETS.GET_USER, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  fundAccount(model: CreditDto): Observable<TransactionDo> {
    return this.http
      .post(EndPoints.SUBWALLETS.CREDIT, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data: any) => {
          this.dashBoardService.fetchTheNewBalance();
          return data as any;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  debitAccount(model: Debitdto): Observable<TransactionDo> {
    return this.http
      .post(EndPoints.SUBWALLETS.DEBIT, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data: any) => {
          this.dashBoardService.fetchTheNewBalance();
          return data as any;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  addPnd(model: PndRequest): Observable<any> {
    return this.http.post(EndPoints.SETTINGS.ADDPND, model, {
      headers: {
        "Content-Type": "application/json",
      },}).pipe(map((data: any) => data), catchError((error: HttpErrorResponse) => throwError(error)))
  }

  removePnd(model: PndRequest): Observable<any> {
    return this.http.post(EndPoints.SETTINGS.REMOVEPND, model, {
      headers: {
        "Content-Type": "application/json",
      },}).pipe(map((data: any) => data), catchError((error: HttpErrorResponse) => throwError(error)))
  }
}
