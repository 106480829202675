import { LogOutUser } from './../store/userdata/userdata.actions';
import { DashboardService } from "./dashboard.service";
import { BuyAirtimeDto } from "./../model/request/bill.request";

import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { AirtimeNetworksDo } from "../model/response/bill.response";
import { EndPoints } from "../config/endpoints";
import { catchError, map, takeWhile } from "rxjs/operators";
import {
  SignUpDto,
  RequestAccess,
  CheckTransactionPin,
  PersonalwalletSignupRequest,
  SiguUpData,
  Switch2FADto,
} from "../model/request/auth.request";
import { LoginResponse } from "../model/response/auth.response";
import {
  NgbModalRef,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { PinchangemodalComponent } from "../shared/components/modal/pinchangemodal/pinchangemodal.component";
import { Router } from "@angular/router";
import { select, Store } from '@ngrx/store';
import { DashboardState } from '../store/dashboarddata/dashboarddata.reducer';
import { SettingsState } from '../store/settingsdata/settingsdata.reducer';
import { UserdataState } from '../store/userdata/userdata.reducer';
import { selectAllUserData } from '../store/userdata';
import { LoginUserSuccess } from '../store/userdata/userdata.actions';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthProvider {
  changePINModal: NgbModalRef;
  loginData: LoginResponse;
  constructor(

    private loader: NgxSpinnerService,
    private http: HttpClient,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
    private store: Store<{
      userdata: UserdataState,
      settingsdata: SettingsState,
      dashboarddata: DashboardState
    }>
  ) {
    this.store.pipe(select(selectAllUserData),
      takeWhile(() => true))
      .subscribe(data => {


        this.loginData = data;
      });
  }

  verifyEmail(
    email,
    accesscode,
    usertype = "0",
    smsotp = false
  ): Observable<any> {
    return this.http
      .get(
        EndPoints.ACCOUNT.VERIFY_EMAIL +
        `?Email=${email}&AccessCode=${accesscode}&usertype=${usertype}&smsotp=${smsotp}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  signup(model: SignUpDto): Observable<any> {
    return this.http
      .post(EndPoints.ACCOUNT.SIGNUP, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  signupRequest(model: RequestAccess): Observable<any> {
    return this.http
      .post(EndPoints.ACCOUNT.REQUEST_ACCESS, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getTransactionToken(model: CheckTransactionPin): Observable<any> {
    return this.http
      .post(EndPoints.ACCOUNT.CHECK_TOKEN, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  switch2FA(model: Switch2FADto): Observable<any> {
    return this.http
      .post(EndPoints.ACCOUNT.SWITH2FA, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  requestResetPassword(model): Observable<any> {
    return this.http
      .post(EndPoints.ACCOUNT.REQUEST_RESET_PASSWORD, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  resetPassword(model): Observable<any> {
    return this.http
      .post(EndPoints.ACCOUNT.RESET_PASSWORD, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  verifyRequestPassword(email, token, smsotp = false): Observable<any> {
    return this.http
      .get(
        EndPoints.ACCOUNT.VERIFY_RESET_DETAILS +
        `?Email=${email}&Token=${token}&issmsotp=${smsotp}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  verifyDevelopers(email, token): Observable<any> {
    return this.http
      .get(
        EndPoints.ACCOUNT.VERIFY_DEVELOPER + `?Email=${email}&Code=${token}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  refreshToken() {

    return this.http
      .post(
        EndPoints.ACCOUNT.REFRESH_TOKEN,
        { RefreshToken: this.loginData.refreshToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => {
          this.saveTokens(data);
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveTokens(data) {

    localStorage.setItem("token", data.token);
  }
  getRefreshToken() {

    return this.loginData.refreshToken;
  }
  getLoginData(): UserdataState {


    return this.loginData;
  }

  logout(isRefresh: boolean = false) {
    this.modalService.dismissAll();
    this.loader.hide();
    this.store.dispatch(new LogOutUser());
    this.router.navigate(["/login"]);
    if (isRefresh) {
      location.reload();
    }
  }


  changePinModal() {
    this.changePINModal = this.modalService.open(PinchangemodalComponent, {
      windowClass: "cardModalForm fundWalletModal",
      centered: true,
      backdrop: "static",
    });

    this.changePINModal.componentInstance.modalContent = {
      title: "Create PIN",
    };
    this.changePINModal.componentInstance.isEdit = false;

    return this.changePINModal.result;
  }

  sendotp(model: PersonalwalletSignupRequest) {
    return this.http
      .post(EndPoints.PERSONALWALLETAUTH.sendotp, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  resendotp(model: PersonalwalletSignupRequest) {
    return this.http
      .post(EndPoints.PERSONALWALLETAUTH.resendotp, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  validateOtp(model: PersonalwalletSignupRequest) {
    return this.http
      .post(EndPoints.PERSONALWALLETAUTH.validateotp, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  signupPersonalWallet(model: SiguUpData) {
    return this.http
      .post(EndPoints.PERSONALWALLETAUTH.personalsignupdata, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
