import { Action } from "@ngrx/store";

export enum SubwalletActionTypes {
  ResetSubwalletState = "[SUBWALLETDATA] Reset Subwallrtwallet Data",
  OnLoadingSubwalletList = "[SUBWALLETDATA] Loading Subwallet list",
  FecthSubwallets = "[SUBWALLETDATA] Fetch Subwallets",
  UpdateSubwallets = "[SUBWALLETDATA] Update Subwallets",
  AddNewSubwallet = "[SUBWALLETDATA] Add new Subwallet",
  UpdateSingleSubwallet = "[SUBWALLETDATA] Update Single Subwallet",
  DeleteSubwallet = "[SUBWALLETDATA] Delete Subwallet",
}

// Action Creators
export class ResetSubwalletState implements Action {
  readonly type = SubwalletActionTypes.ResetSubwalletState;
}
export class OnLoadingSubwalletList implements Action {
  readonly type = SubwalletActionTypes.OnLoadingSubwalletList;
}

export class FecthSubwallets implements Action {
  readonly type = SubwalletActionTypes.FecthSubwallets;
  constructor(public payload: any) {
    this.type = SubwalletActionTypes.FecthSubwallets;
  }
}


export class AddNewSubwallet implements Action {
  readonly type = SubwalletActionTypes.AddNewSubwallet;

  constructor(public payload: any) {
    this.type = SubwalletActionTypes.AddNewSubwallet;
  }
}



export class UpdateSubwallets implements Action {
  readonly type = SubwalletActionTypes.UpdateSubwallets;

  constructor(public payload: any) {
    this.type = SubwalletActionTypes.UpdateSubwallets;
  }
}



export class UpdateSingleSubwallet implements Action {
  readonly type = SubwalletActionTypes.UpdateSingleSubwallet;

  constructor(public payload: any) {
    this.type = SubwalletActionTypes.UpdateSingleSubwallet;
  }
}


export class DeleteSubwallet implements Action {
  readonly type = SubwalletActionTypes.DeleteSubwallet;

  constructor(public payload: any) {
    this.type = SubwalletActionTypes.DeleteSubwallet;
  }
}







export type SubwalletdataActions =
  ResetSubwalletState
  | UpdateSubwallets
  | OnLoadingSubwalletList
  | AddNewSubwallet
  | UpdateSingleSubwallet
  | DeleteSubwallet;
