export const LOCAL_STORAGE_ITEMS = {
    USER_DATA: 'userdata',
    HAS_BTC_WALLET: 'HasBTCWallet',
    BTC_WALLET: 'BTCWallet',
    HAS_ETH_WALLET: 'HasETHWallet',
    ETH_WALLET: 'ETHWallet',
    BTC_EXCHANGE_RATE: 'BTCExchangeRate',
    DASHBOARD_DATA: 'dashboarddata'
};

export const CRYPTO_CONSTANTS = {
    DOLLAR_RATE: 485
};