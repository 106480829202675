import { CreateCryptoWalletModalComponent } from './create-crypto-wallet-modal.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonlyUsedModule } from 'src/app/shared/modules/commonlyused.module';


@NgModule({
  declarations: [CreateCryptoWalletModalComponent],
  exports: [CreateCryptoWalletModalComponent],
  entryComponents: [CreateCryptoWalletModalComponent],
  imports: [
    CommonModule,
    CommonlyUsedModule
  ]
})
export class CreateCryptoWalletModalModule { }
