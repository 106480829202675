import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CryptoTransactionModalComponent } from './crypto-transaction-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonlyUsedModule } from 'src/app/shared/modules/commonlyused.module';



@NgModule({
  declarations: [CryptoTransactionModalComponent],
  exports: [CryptoTransactionModalComponent],
  entryComponents: [CryptoTransactionModalComponent],
  imports: [
    CommonModule,
    // SharedModule,
    CommonlyUsedModule
  ]
})
export class CryptoTransactionModalModule { }
