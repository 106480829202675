import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { RequestMoneyResponse, RequestMoneyLinkResponse } from "../model/response/requestmoney.response";
import { RequestmoneyPeriod, newLinkDto, verifyLinkDto, DeleteLinkDto } from "../model/request/requestmoney.request";
import { NewLinkDo, VerifyLinkDo } from "../model/response/requestlink.response";
import { EndPoints } from "../config/endpoints";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class RequestmoneyService {
  constructor(private http: HttpClient) { }

  RequestMoneyStats(): Observable<RequestMoneyLinkResponse> {
    return this.http.get(EndPoints.REQUEST.PAYMENT_LINKS, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error) => {
        return throwError(error);
      }));
  }

  // @ts-ignore
  RequestMoneyLinkResponse(urlLink): Observable<RequestMoneyLinkResponse> {
    return this.http.get(EndPoints.REQUEST.FETCH_TRANSACTIONS + `?url=${urlLink}`, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .pipe(map(data => {
        return data as any;
      }), catchError((error) => {
        return throwError(error);
      }));
  }


  createLink(model: newLinkDto): Observable<NewLinkDo> {
    return this.http.post(EndPoints.REQUEST.CREATE_LINK, model, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return throwError(error);
      }));
  }
  verifyLink(model: verifyLinkDto): Observable<VerifyLinkDo> {
    return this.http.post(EndPoints.REQUEST.CHECK_LINK, model, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return throwError(error);
      }));
  }

  getAllLinks(model): Observable<VerifyLinkDo> {
    return this.http.get(EndPoints.REQUEST.PAYMENT_LINKS, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return throwError(error);
      }));
  }

  getAllSingleLink(model): Observable<VerifyLinkDo> {
    return this.http.get(EndPoints.REQUEST.FETCH_TRANSACTIONS, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return throwError(error);
      }));
  }

  deleteLink(model: DeleteLinkDto): Observable<any> {
    return this.http.post(EndPoints.REQUEST.DELETE, model, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return throwError(error);
      }));
  }
}
