import { WalletrecentrecipientComponent } from './walletrecentrecipient.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonlyUsedModule } from 'src/app/shared/modules/commonlyused.module';


@NgModule({
  declarations: [WalletrecentrecipientComponent],
  exports: [WalletrecentrecipientComponent],
  entryComponents: [WalletrecentrecipientComponent],
  imports: [
    CommonModule,
    CommonlyUsedModule
  ]
})
export class WalletrecentrecipientModule { }
