
import { SavedCardsResponse } from "src/app/model/request/funding.request";
import { LoginResponse } from "src/app/model/response/auth.response";
import { DashboardResponse } from "src/app/model/response/dashboard.response";
import { transaction } from "src/app/model/response/transactions.response";
import { BankListandCodeDo } from 'src/app/model/response/transfer.response';
import { DashboarddataActions, DashboardActionTypes } from "./dashboarddata.actions";


export interface DashboardState extends DashboardResponse, SavedCardsResponse {
  message?: string;
  cryptoFee?: number;
  isLoadingDashboardData?: boolean;
  isLoadingRecentTransactions?: boolean;
  banks?: BankListandCodeDo[];
  networkList?: [];
}


const initialState: DashboardState = {
  recentTransactions: [],
  stats: [
    {
      data: {}
      , type: 0
    },
    { data: {}, type: 1 },
    { data: {}, type: 2 }
  ],
  walletBalance: 0,
  overdraftAmount: 0.00,
  overdraftDate: "",
  eligibleOverdraftAmount: 0,
  accountNumber: null,
  bankName: "Providus Bank",
  documentUploaded: null,
  isDocumentVerified: false,
  hasPendingDocument: false,
  cardChargeCap: 0,
  cardChargePercentage: 0,
  cards: [],
  isLoadingDashboardData: true,
  isLoadingRecentTransactions: true,
  banks: [],
  documentsUploaded: [],
  businessdocuments: {}
};

export function Dashboarddatareducer(state = initialState, action: DashboarddataActions): DashboardState {

  switch (action.type) {
    case DashboardActionTypes.ResetDashboardState:

      return {
        ...initialState
      };

    case DashboardActionTypes.UpdataDashboardData:



      return {
        ...state,
        isLoadingDashboardData: false,
        ...action.payload,
      };

      break;
    case DashboardActionTypes.UpdataRecentTransactions:
      
      return {
        ...state,
        isLoadingRecentTransactions: false,
        ...action.payload,
      };

      break;
    case DashboardActionTypes.UpdataCurrentUserBalance:

      return {
        ...state,
        ...action.payload,
      };
      break;
    case DashboardActionTypes.UpdatacryptoFee:

      return {
        ...state,
        cryptoFee: action.payload,
      };
      break;
    case DashboardActionTypes.UpdataBanks:

      return {
        ...state,
        banks: action.payload,
      };
      break;
    case DashboardActionTypes.UpdateSOmeDashboardData:

      return {
        ...state,
        banks: action.payload,
      };
      break;
    case DashboardActionTypes.UpdataUsersCards:

      return {
        ...state,
        ...action.payload,
      };
      break;
    default:
      return {
        ...state
      };

  }
}

