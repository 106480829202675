import { NavigationEnd, Router } from "@angular/router";
import { Location, JsonPipe } from "@angular/common";
import { filter } from "rxjs/operators";
import { Observable, BehaviorSubject, Subject } from "rxjs";

import { Injectable } from "@angular/core";
import { AllTransactionsResponse } from "../model/response/transactions.response";
import { ReportService } from "../service/reports.service";
@Injectable()
export class TransationdataProvider {
  defaultData: AllTransactionsResponse = {
    isLoading: true,
    isFilter: false,
    isFirstLoad: false,
    allTransactions: [],
    transactionsReport: {
      totalPages: 0,
    },
  };
  private transactionData$ = new BehaviorSubject<AllTransactionsResponse>(
    this.defaultData
  );
  constructor(private reportService: ReportService) {}
  resetdata() {
    this.updateDetails({
      isLoading: true,
      isFilter: false,
      isFirstLoad: false,
      allTransactions: [],
      transactionsReport: {
        totalPages: 0,
      },
    });
  }
  fetchTransactionData(params = "?page=1&pageSize=15") {
    this.reportService.getAllTransaction(params).subscribe(
      (data) => {
        data.isLoading = false;
        data.isFirstLoad = true;
        this.updateDetails(data);
      },
      (error) => {}
    );
  }

  filterTransactionData(parameter: string) {
    this.reportService.getAllTransaction(parameter).subscribe(
      (data) => {
        data.isFilter = false;
        this.updateDetails(data);
      },
      (error) => {}
    );
  }

  updateDetails(details: AllTransactionsResponse) {
    this.transactionData$.next(details);
  }

  gettransactionData(): Observable<AllTransactionsResponse> {
    return this.transactionData$.asObservable();
  }
}
