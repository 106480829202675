import { Action } from "@ngrx/store";

export enum TransactionsActionTypes {
  ResetTranasactionsDataState = "[TRANSACTIONSDATA] Reset Data",
  FETCHUSERSTRANSACTIONS = "[TRANSACTIONSDATA] Fetch User transaction",
  UPDATEUSERSTRANSACTIONS = "[TRANSACTIONSDATA] Update User transaction",
  FETCHUSERSTRANSACTIONSToday = "[TRANSACTIONSDATA] Fetch User transaction Today",
  FETCHUSERSTRANSACTIONSYesterday = "[TRANSACTIONSDATA] Fetch User transaction Yesterday",
  FETCHUSERSTRANSACTIONSThisMonth = "[TRANSACTIONSDATA] Fetch User transaction This Month",
  UPDATEUSERSTRANSACTIONSToday = "[TRANSACTIONSDATA] Update User transaction Today",
  UPDATEUSERSTRANSACTIONSYesterday = "[TRANSACTIONSDATA] Update User transaction Yesterday",
  UPDATEUSERSTRANSACTIONSThisMonth = "[TRANSACTIONSDATA] Update User transaction This Month",
  TurnOnLoaderFortransactions = "[TRANSACTIONSDATA] Turn On Loader For transactions",
}

// Action Creators
export class ResetTranasactionsDataState implements Action {
  readonly type = TransactionsActionTypes.ResetTranasactionsDataState;
}

export class TurnOnLoaderFortransactions implements Action {
  readonly type = TransactionsActionTypes.TurnOnLoaderFortransactions;
}

export class FETCHUSERSTRANSACTIONS implements Action {
  readonly type = TransactionsActionTypes.FETCHUSERSTRANSACTIONS;

    constructor(public payload: any) {
    this.type = TransactionsActionTypes.FETCHUSERSTRANSACTIONS;
  }
}

export class UPDATEUSERSTRANSACTIONS implements Action {
  readonly type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONS;

  constructor(public payload: any) {
    this.type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONS;
  }
}

export class FETCHUSERSTRANSACTIONSToday implements Action {
  readonly type = TransactionsActionTypes.FETCHUSERSTRANSACTIONSToday;

    constructor() {
    this.type = TransactionsActionTypes.FETCHUSERSTRANSACTIONSToday;
  }
}

export class FETCHUSERSTRANSACTIONSYesterday implements Action {
  readonly type = TransactionsActionTypes.FETCHUSERSTRANSACTIONSYesterday;

    constructor() {
    this.type = TransactionsActionTypes.FETCHUSERSTRANSACTIONSYesterday;
  }
}

export class FETCHUSERSTRANSACTIONSThisMonth implements Action {
  readonly type = TransactionsActionTypes.FETCHUSERSTRANSACTIONSThisMonth;

    constructor() {
    this.type = TransactionsActionTypes.FETCHUSERSTRANSACTIONSThisMonth;
  }
}

export class UPDATEUSERSTRANSACTIONSToday implements Action {
  readonly type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSToday;

    constructor(public payload: any) {
    this.type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSToday;
  }
}

export class UPDATEUSERSTRANSACTIONSYesterday implements Action {
  readonly type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSYesterday;

    constructor(public payload: any) {
    this.type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSYesterday;
  }
}

export class UPDATEUSERSTRANSACTIONSThisMonth implements Action {
  readonly type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSThisMonth;

    constructor(public payload: any) {
    this.type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSThisMonth;
  }
}




export type TransactionsdataActions =
  ResetTranasactionsDataState
  | UPDATEUSERSTRANSACTIONS
  | UPDATEUSERSTRANSACTIONSToday
  | UPDATEUSERSTRANSACTIONSYesterday
  | UPDATEUSERSTRANSACTIONSThisMonth
  |TurnOnLoaderFortransactions;

  export class TransactionsdataActionsToday implements Action {
    readonly type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSToday;

    constructor(public payload: any) {}
  }
  export class TransactionsdataActionsYesterday implements Action {
    readonly type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSYesterday;

    constructor(public payload: any) {
      console.log("log1 " + payload)
    }
  }
  export class TransactionsdataActionsThisMonth implements Action {
    readonly type = TransactionsActionTypes.UPDATEUSERSTRANSACTIONSThisMonth;

    constructor(public payload: any) {
      console.log("log2 " + payload)
    }
  }

