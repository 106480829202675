import { LandingModule } from './pages/landing/landing.module';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { ChangeTextDirective } from "./directive/change-text.directive";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { OpenMenuDirective } from "./directive/open-menu.directive";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppServices } from "./service/index.service";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { AppdataServices } from "./dataservice/index.service";
// import { NgProgressModule } from 'ngx-progressbar';
// import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { PinchangemodalModule } from "./shared/components/modal/pinchangemodal/pinchangemodal.module";
import { NgProgressModule } from "@ngx-progressbar/core";
import { NgProgressHttpModule } from "@ngx-progressbar/http";
import { NgProgressRouterModule } from "@ngx-progressbar/router";
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { getAllDataFromLocalForage, default as localForage } from 'ngrx-store-persist';
import { CryptoModule } from './state/crypto/crypto.module';


export function tokenGetter() {
  return localStorage.getItem("token");
}
import { AuthGuard } from "./core/services/auth-guard.service";
import { StateModule } from "./store/state.module";



@NgModule({
  declarations: [AppComponent, OpenMenuDirective, ChangeTextDirective],
  imports: [
    BrowserAnimationsModule,
    NgbModule,
    CreditCardDirectivesModule,
    HttpClientModule,
    BrowserModule,
    SharedModule,
    CoreModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    PinchangemodalModule,
    NgProgressModule,
    NgProgressRouterModule.withConfig({}),
    NgProgressHttpModule,

    StateModule,
    // LandingModule
  ],
  providers: [NgbActiveModal, AppServices, Title, AppdataServices, AuthGuard],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
