import { AllTransactionsResponse } from "./../../model/response/transactions.response";
import { CryptoTransactionsResponse } from "./../../model/response/transactions.response";
import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { transaction } from "src/app/model/response/transactions.response";
import { CryptoTransaction } from "src/app/model/response/transactions.response";
import { FormatService } from "src/app/shared/services/formatter.service";
import { CryptoTransactionResponse } from "src/app/model/request/fund_crypto_wallet.model";

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  constructor(private formatService: FormatService) {}

  excelType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  excelExtension = "";

  public exportExcel(fileType: string, jsonData, fileName: string): void {
    this.excelExtension = fileType;
    let transactionData = this.formatTransactions(jsonData);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transactionData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveFile(excelBuffer, fileName);
  }

  private saveFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.excelType });
    FileSaver.saveAs(data, fileName + this.excelExtension);
  }

  formatTransactions(transactions: CryptoTransaction[]) {
    let transactionArray = [];
    for (let index = 0; index < transactions.length; index++) {
      let aTransaction: CryptoTransaction = transactions[index];

      let newFormat = {
        Date: this.formatDate(aTransaction.dateOfTransaction),
        Source: aTransaction.sourceAddress,
        Destination: aTransaction.destinationAddress,
        Amount: `ETH${this.formatService.formatNumberToCurrency(
          Number(aTransaction.amount)
        )}`,
        Reference: aTransaction.transactionRef,
        "Transaction Type":
          aTransaction.transactionType === 2 ? "Debit" : "Credit",
      };
      transactionArray.push(newFormat);
    }

    return transactionArray;
  }

  formatDate(datee): string {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let date = new Date(datee);
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return day + " " + monthNames[monthIndex] + " " + year;
  }
}
