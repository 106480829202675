import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { WalletsCryptoService } from './crypto.service';

import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as cryptoActions from './crypto.actions';
import { Router } from '@angular/router';
import { CreateCryptoAddressRequest } from 'src/app/model/request/create_crypto_address.request';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThrowStmt } from '@angular/compiler';
import { FundCryptoWalletRequest, ExchangeRateQueryParams, TransferToCryptoAddressRequest, GetUserCryptoTransactionsRequest, GetUserCryptoWalletRequest, SendCryptoRequest } from '../../model/request/fund_crypto_wallet.model';
import { WebStorageService } from 'src/app/service/web-storage.service';
import { LOCAL_STORAGE_ITEMS } from 'src/app/config/constants';
import { CryptoCurrencyType } from '../../model/request/crypto_currency.type';

@Injectable({
    providedIn: 'root'
})
export class CryptoEffects {
    constructor(private cryptoService: WalletsCryptoService,
                private actions$: Actions,
                private router: Router,
                private loader: NgxSpinnerService,
                private webStorageService: WebStorageService) { }


    @Effect()
    createCryptoAddress$: Observable<Action> = this.actions$.pipe(
        ofType(cryptoActions.CryptoActionTypes.CreateCryptoAddress),
        mergeMap((request: CreateCryptoAddressRequest) => {
          this.loader.show();
          return this.cryptoService.createNewCryptoWallet(request).pipe(
            map(data => {
                this.loader.hide();

                if(data.currencyType == CryptoCurrencyType.Bitcoin) {
                  this.webStorageService.keepItemInLocalStorage(LOCAL_STORAGE_ITEMS.HAS_BTC_WALLET, JSON.stringify(true));
                  this.webStorageService.keepItemInLocalStorage(LOCAL_STORAGE_ITEMS.BTC_WALLET, JSON.stringify(data));
                }
                return new cryptoActions.CreateCryptoAddressSuccess(data);
              }),
              catchError(err => {
                this.loader.hide();

                return of(new cryptoActions.CreateCryptoAddressFailure(err.error['message']))
            })
          )
        }
      )
    );

    @Effect()
    fundCryptoWallet$: Observable<Action> = this.actions$.pipe(
        ofType(cryptoActions.CryptoActionTypes.FundCryptoWallet),
        map((action: cryptoActions.FundCryptoWallet) => action.payload),
        mergeMap((request: FundCryptoWalletRequest) => {
          this.loader.show();
          return this.cryptoService.fundCryptoWallet(request).pipe(
            map(data => {


                this.loader.hide();
                // if(data.currencyType == CryptoCurrencyType.Bitcoin) {
                //   let BTCWallet = this.webStorageService.getItemFromLocalStorage(LOCAL_STORAGE_ITEMS.BTC_WALLET);
                //   this.webStorageService.removeItemFromLocalStorage(LOCAL_STORAGE_ITEMS.BTC_WALLET);
                //   BTCWallet['availableBalance'] = data['currentBalance'];
                //   this.webStorageService.keepItemInLocalStorage(LOCAL_STORAGE_ITEMS.BTC_WALLET, JSON.stringify(BTCWallet));
                // }

                return new cryptoActions.FundCryptoWalletSuccess(data);
              }),
              catchError(err => {


                this.loader.hide();
                return of(new cryptoActions.FundCryptoWalletFailure(err.error.message))
            })
          )
        }
      )
    );

    @Effect()
    withdrawFromCryptoWallet$: Observable<Action> = this.actions$.pipe(
        ofType(cryptoActions.CryptoActionTypes.WithdrawFromCryptoWallet),
        map((action: cryptoActions.WithdrawFromCryptoWallet) => action.payload),
        mergeMap((request: FundCryptoWalletRequest) => {
          this.loader.show();
          return this.cryptoService.withdrawFromCryptoWallet(request).pipe(
            map(data => {
                this.loader.hide();


                return new cryptoActions.WithdrawFromCryptoWalletSuccess(data);
              }),
              catchError(err => {
                this.loader.hide();
                return of(new cryptoActions.WithdrawFromCryptoWalletFailure(err.error['message']))
            })
          )
        }
      )
    );

    @Effect()
    getUserCryptoWallets$: Observable<Action> = this.actions$.pipe(
        ofType(cryptoActions.CryptoActionTypes.GetUserCryptoWallet),
        map((action: cryptoActions.GetUserCryptoWallet) => action.payload),
        mergeMap((request: GetUserCryptoWalletRequest) => {
          this.loader.show();
          return this.cryptoService.getUserCryptoWallet().pipe(
            map(data => {
                this.loader.hide();


                return new cryptoActions.GetUserCryptoWalletSuccess(data);
              }),
              catchError(err => {
                this.loader.hide();
                return of(new cryptoActions.GetUserCryptoWalletFailure(err.error['message']))
            })
          )
        }
      )
    );

    @Effect()
    getUserCryptoTransactions: Observable<Action> = this.actions$.pipe(
        ofType(cryptoActions.CryptoActionTypes.GetUserCryptoTransactions),
        map((action: cryptoActions.GetUserCryptoTransactions) => action.payload),
        mergeMap((request: GetUserCryptoTransactionsRequest) => {
          this.loader.show();
          return this.cryptoService.getUserCryptoTransactions(request).pipe(
            map(data => {
                this.loader.hide();


                return new cryptoActions.GetUserCryptoTransactionsSuccess(data);
              }),
              catchError(err => {
                this.loader.hide();
                return of(new cryptoActions.GetUserCryptoTransactionsFailure(err.error['message']))
            })
          )
        }
      )
    );


    @Effect()
    transferToCryptoAddress$: Observable<Action> = this.actions$.pipe(
        ofType(cryptoActions.CryptoActionTypes.TransferToCryptoAddress),
        map((action: cryptoActions.TransferToCryptoAddress) => action.payload),
        mergeMap((request: SendCryptoRequest) => {
          this.loader.show();
          return this.cryptoService.transferToCryptoAddress(request).pipe(
            map(data => {
                this.loader.hide();


                return new cryptoActions.TransferToCryptoAddressSuccess(data);
              }),
              catchError(err => {
                this.loader.hide();
                return of(new cryptoActions.TransferToCryptoAddressFailure(err.error['message']))
            })
          )
        }
      )
    );

}
