import { Action } from "@ngrx/store";

export enum CardActionTypes {
  ResetCardsDataState = "[CARDDATA] Reset Data",
  UpdateCardsSection = "[CARDDATA]Update Cardw",
  FethDollarCards = "[CARDDATA] Fetch Dollar Cards",
  FethNairaCards = "[CARDDATA] Fetch Naira Cards",
  FethCardsRequests = "[CARDDATA] Fetch Cards Requests",
  UpdateDollarCards = "[CARDDATA] Update Dollar Cards",
  UpdateNairaCards = "[CARDDATA] Update Naira Cards",
  UpdateCardsRequests = "[CARDDATA] Update Cards Requests",
  UpdateCardsActiondata = "[CARDDATA] Update Cards Actiondata"
}

// Action Creators
export class ResetCardsDataState implements Action {
  readonly type = CardActionTypes.ResetCardsDataState;
}

export class FethDollarCards implements Action {
  readonly type = CardActionTypes.FethDollarCards;
}
export class FethNairaCards implements Action {
  readonly type = CardActionTypes.FethNairaCards;
}
export class FethCardsRequests implements Action {
  readonly type = CardActionTypes.FethCardsRequests;
}

export class UpdateDollarCards implements Action {
  readonly type = CardActionTypes.UpdateDollarCards;

  constructor(public payload: any) {
    this.type = CardActionTypes.UpdateDollarCards;
  }
}

export class UpdateNairaCards implements Action {
  readonly type = CardActionTypes.UpdateNairaCards;

  constructor(public payload: any) {
    this.type = CardActionTypes.UpdateNairaCards;
  }
}

export class UpdateCardsRequests implements Action {
  readonly type = CardActionTypes.UpdateCardsRequests;

  constructor(public payload: any) {
    this.type = CardActionTypes.UpdateCardsRequests;
  }
}


export class UpdateCardsSection implements Action {
  readonly type = CardActionTypes.UpdateCardsSection;

  constructor(public payload: any) {
    this.type = CardActionTypes.UpdateCardsSection;
  }
}


export class UpdateCardsActiondata implements Action {
  readonly type = CardActionTypes.UpdateCardsActiondata;

  constructor(public payload: any) {
    this.type = CardActionTypes.UpdateCardsActiondata;
  }
}



export type CarddataActions =
  ResetCardsDataState
  | UpdateDollarCards
  | UpdateNairaCards
  | UpdateCardsRequests
  | UpdateCardsSection
  |UpdateCardsActiondata;
