import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CryptoTransationCategoriesList, AllTransactionsResponse, Transactionmeta, CryptoTransaction } from 'src/app/model/response/transactions.response';
import { ExportExcelService } from 'src/app/core/services/export-excel.service';
import { ReportService } from 'src/app/service/reports.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintjsService } from 'src/app/service/printjs.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormatService } from 'src/app/shared/services/formatter.service';
import { Observable } from 'rxjs';

import { Location } from "@angular/common";
import * as cryptoReducer from '../../state/crypto/crypto.reducer';
import * as cryptoActions from '../../state/crypto/crypto.actions';
import * as fromCrypto from '../../state/crypto/index';
import { Store, select } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { CryptoTransactionType, GetUserCryptoTransactionsRequest } from '../../model/request/fund_crypto_wallet.model';
import { WebStorageService } from 'src/app/service/web-storage.service';
import { LOCAL_STORAGE_ITEMS } from 'src/app/config/constants';
import { CryptoTransactionModalComponent } from 'src/app/shared/components/modal/crypto-transaction-modal/crypto-transaction-modal.component';
import { Router } from '@angular/router';
import { CryptoCurrencyWallet, CryptoWalletTransaction } from 'src/app/model/response/create_crypto_address.response';
import { EtherWallet } from 'src/app/model/response/auth.response';
import { getLoggedinUserdata } from "src/app/store/userdata";
import { LoginResponse } from "../../model/response/auth.response";
import { UserdataState } from "src/app/store/userdata/userdata.reducer";
@Component({
  selector: 'app-bitcoin-transactions',
  templateUrl: './bitcoin-transactions.component.html',
  styleUrls: ['./bitcoin-transactions.component.scss']
})
export class BitcoinTransactionsComponent implements OnInit {

  @ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;
  @ViewChild("top", { static: false }) divTop: ElementRef;

  base64Img = null;
  isScrollUp: boolean = false;
  isLoading: boolean = true;
  isFilter: boolean = false;
  showScroll = false;
  showClear = false;
  margins = {
    top: 70,
    bottom: 40,
    left: 30,
    width: 550,
  };

  transationCategoriesLists = CryptoTransationCategoriesList;

  allTransactionsData: AllTransactionsResponse = {
    allTransactions: [],
    transactionsReport: {
      totalPages: 0,
    },
  };

  counter = [1, 1, 2, 4, 6, 8];

  currentPageDetails: Transactionmeta = {};
  search: string = "";
  searchTransactionType: string = "All";
  transactionSuccessfulModal: any;
  extentionType = "";
  name = "Angular 4";
  base64Image: any;
  startDate = "";
  endDate = "";
  startDatemin;
  startDatemax;
  endDatemin;
  endDatemax;
  imageUrl =
    "https://miro.medium.com/fit/c/256/256/2*FqXlmyRXZZOg80lauI4DDA.png";
  searchParameters: string = "?";
  page = 1;
  totalpages = "1";
  componentActive: boolean = true;
  cryptoTransactions: Array<CryptoWalletTransaction>;
  isError: boolean;
  errorMessage: any;
  BTCWallet: CryptoCurrencyWallet;
  ETHWallet: EtherWallet;
  transactionType: CryptoTransactionType;
  skip: number;
  total: number;
  pageSize: number = 15;
  LoginData: LoginResponse;
  constructor(
    private exportService: ExportExcelService,
    private reportService: ReportService,
    private modalService: NgbModal,
    private PrintjsServicep: PrintjsService,
    private analyticsService: AnalyticsService,
    private loader: NgxSpinnerService,
    public formatService: FormatService,
    private store: Store<cryptoReducer.CryptoState>,
    private userStore: Store<UserdataState>,
    private webStorageService: WebStorageService,
    private router: Router,
    private location: Location,
    // private routerLink: RouterLink
  ) {
  }

  ngOnInit() {
    this.ETHWallet = this.ETHWallet = JSON.parse(this.webStorageService.getItemFromLocalStorage(LOCAL_STORAGE_ITEMS.ETH_WALLET));
    this.cryptoTransactions = [];
    const today = new Date();
    this.startDate = "2017-01-01";
    this.endDate = `${today.toUTCString()}`;
    this.transactionType = 0;
    this.skip = 0;

    this.userStore.pipe(select(getLoggedinUserdata),
    takeWhile(() => this.componentActive))
    .subscribe(data => {
      this.LoginData = data;
      this.ETHWallet = this.LoginData.data.etherWallet;
    });

    this.getWalletTransactions();

    // if(this.BTCWallet != null) {
    //   this.getWalletTransactions();
    // } else {
    //   this.cryptoTransactions = [];
    //   this.isLoading = false;
    // }
  }

  getWalletTransactions() {
    const getTransactionsRequest: GetUserCryptoTransactionsRequest = {
      Take: this.pageSize,
      Skip: this.skip,
      SearchString: this.search,
      DateFrom: this.startDate,
      DateTo: this.endDate,
      TransactionType: Number(this.transactionType)
    };



    this.store.dispatch(new cryptoActions.GetUserCryptoTransactions(getTransactionsRequest));

    this.store.pipe(select(fromCrypto.getCryptoTransactions),
    takeWhile(() => this.componentActive))
    .subscribe(res => {
      this.cryptoTransactions = [];
      this.cryptoTransactions = res['items'];
      this.total = res['totalItems']


      this.isLoading = false;

      if(res == null) {
        this.store.pipe(select(fromCrypto.getError),
        takeWhile(() => this.componentActive))
        .subscribe(err => {

          this.isError = true;
          this.errorMessage = err;
        });
      }

    },
    (error) => {
      this.cryptoTransactions = [];
      this.isLoading = false;
    })

  }

  changePage(event) {

    this.skip = this.pageSize * (event - 1);
    this.getWalletTransactions();
  }

  clearSearch() {
    const today = new Date();
    this.startDate = "2017-01-01";
    this.endDate = `${today.toUTCString()}`;
    this.showClear = false;
    this.search = "";
    this.searchTransactionType = "";
  }

  scrollUp(): void {
    this.divTop.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  amountclass(destinationAddress: string) {
    return destinationAddress === this.ETHWallet.address.toLowerCase() ? "amount credit" : "amount debit";
  }

  downLoadPdfFile(transactions: CryptoTransaction[]) {
    let content = this.PrintjsServicep.generateInvoice(transactions);

    this.loader.hide();
    this.PrintjsServicep.initiate(content);
  }

  exportData(format) {
    this.loader.show();
    const exportRequest: GetUserCryptoTransactionsRequest = {
      Take: this.pageSize,
      Skip: this.skip,
      SearchString: this.search,
      DateFrom: this.startDate,
      DateTo: this.endDate,
      TransactionType: Number(this.transactionType)
    };

    this.store.dispatch(new cryptoActions.GetUserCryptoTransactions(exportRequest));

    this.store.pipe(select(fromCrypto.getCryptoTransactions),
    takeWhile(() => this.componentActive))
    .subscribe(res => {
      var data = res['items'];
      this.total = res['totalItems'];


      this.isLoading = false;

      if(res == null) {
        this.store.pipe(select(fromCrypto.getError),
        takeWhile(() => this.componentActive))
        .subscribe(err => {

          this.isError = true;
          this.errorMessage = err;
        });
      }
      if (data.length === 0) {
        this.formatService.showToasts(
          "There are no transactions to export",
          "success"
        );
        return;
      } else if (format === ".xlsx") {
        console.log(data);
        this.exportExcel(format, data);
      } else if (format === ".csv") {
        this.exportExcel(format, data);
      } else if (format === "pdf") {
        this.downLoadPdfFile(data);
      }
      this.loader.hide();
    },
    (error) => {
      this.cryptoTransactions = [];
      this.loader.hide();
    })
 
  }

  exportExcel(extype, transactions) {
    this.analyticsService.trackEvent(
      "Transactions",
      `Export transaction as ${extype} `,
      null,
      10
    );

    this.exportService.exportExcel(extype, transactions, "Transactions Report");
  }

  getTotalPageNumber(pages: number) {
    return Math.ceil(pages);
  }

  onScroll(event, pagt) {
    // let newParameters = this.searchParameters;
    // if (newParameters.length > 1) {
    //   newParameters += "&";
    // }

    // newParameters += `page=${event}&pageSize=${15}`;
    // this.allTransactionsData.isLoading = true;
    // this.transationdataProvider.updateDetails(this.allTransactionsData);
    // this.transationdataProvider.fetchTransactionData(newParameters);
  }

  searchTransactions() {
    if (this.allTransactionsData.allTransactions == null) {
      return [];
    }
    this.reportService.getAllTransaction().subscribe((data) => {
      this.allTransactionsData.allTransactions = data.allTransactions.filter(
        (item) => {
          if (this.searchTransactionType === "All") {
            return (
              item.narration.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
              item.reference.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1
            );
          } else {
            return (
              (item.narration.toLowerCase().indexOf(this.search) > -1 ||
                item.reference.toLowerCase().indexOf(this.search) > -1) &&
              item.category.toLowerCase() ===
                this.searchTransactionType.toLowerCase()
            );
          }
        }
      );
    });
  }

  goBack() {
    this.location.back();
  }
  openNewTab(transactionRef){
    var url = "https://etherscan.io/tx/" + transactionRef;
    window.open(url, '_blank').focus();
  }

  openDetails(transaction, walletAddress) {
    this.transactionSuccessfulModal = this.modalService.open(
      CryptoTransactionModalComponent,
      {
        windowClass: "successModal",
        centered: true,
        backdrop: "static",
      }
    );
    this.transactionSuccessfulModal.componentInstance.transaction = transaction;
    this.transactionSuccessfulModal.componentInstance.walletAddress = walletAddress;
    this.transactionSuccessfulModal.result
      .then((result: any) => {})
      .catch((error) => {});
  }

  getTransactionType(destinationAddress: string, type: string) {
    if(destinationAddress.toLowerCase() === this.BTCWallet.address)
    {
      return 'Credit';
    }
    if(destinationAddress.toLowerCase() !== this.BTCWallet.address) {
      return 'Debit';
    }
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

}
