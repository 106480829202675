// export interface settingsDetails {
//   businessName?: string;
//   email?: string;
//   phoneNumber?: string;
//   businessLogo?: string;
//   businessAddress?: string;
//   twoFactorAuthenticationStatus?: string;
//   publicKey?: string;
//   secretKey?: string;
//   profilePicture?: string;
// }

import { MerchantType } from '../request/auth.request';

export interface Settingsdetails {
  isLoading?: boolean;
  businessName: string;
  phoneNumber?: string;
  email: string;
  address?: string;
  city?: string;
  rcNumber?: string;
  bvn?: string;
  businessDocument?: string;
  logo?: string;
  developerKeys?: Developerkeys;
  hasPin?: boolean;
  lastPasswordReset?: string;
  lastTransactionPinReset?: string;
  hasTransactionPin?: boolean;
  diailyLimit?: number;
  isDocumentVerified?: boolean;
  notifications?: NotificationsSettings;
  allowDirectoryListing?: boolean;
  documentsUploaded?: documentsUploaded[];
  businessdocuments?: businessDocuments;
  merchantType?: MerchantType;
}

export interface businessDocuments {
  mermatFile?: documentUpledStatus;
  co2File?: documentUpledStatus;
  c11File?: documentUpledStatus;
  cacFile?: documentUpledStatus;
  directorsID?: documentUpledStatus;
  directorsID2?: documentUpledStatus;
  utilityBill?: documentUpledStatus;
  applicationdoc?: documentUpledStatus;
  certificateofReg?: documentUpledStatus;
}

export interface documentsUploaded {
  documentType?: string;
  fileName?: string;
  status?: documentUpledStatus;
}

export enum documentUpledStatus {
  approved = 'Approved',
  verifying = "PendingApproval",
  notuploaded = "Rejected"
}

export interface NotificationsSettings {
  emailNotifications?: boolean;
  pushNotifications?: boolean;
  smsNotifications?: boolean;
}

export interface Developerkeys {
  hasKeys?: boolean;
  requestedKeys?: boolean;
  developerAccountStatus?: DeveloperAccountStatus;
  sandboxPublicKey?: string;
  sandboxSecretKey?: string;
  publicKey?: string;
  secretKey?: string;
  webHook?: string;
}
export enum DeveloperAccountStatus {
  Inactive = 0,
  Active = 1,
}
export const DefaultsettingsDetails = {
  businessName: "Enterprise Wallet Payments Limited",
  email: "admin@wallets.africa",
  phoneNumber: "09067328833",
  businessLogo: "../../../assets/images/kim-carpenter-307030-unsplash.png",
  businessAddress:
    "Block 6, House 1b, Mobolaji Johnson Estate, Adewunmi Adebimpe Street, Lekki, Lagos",
  twoFactorAuthenticationStatus: "",
  publicKey: "3ss9nmhedq3u",
  secretKey: "ek3woyefq8oh",
};
