import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { EndPoints } from "../config/endpoints";
import { catchError, map } from "rxjs/operators";
import {
  Settingsdetails,
  Developerkeys,
  NotificationsSettings,
} from "../model/response/settings.response";
import {
  Updateprofile,
  Changepassword,
  ChangeTransactionCode,
  UpdateWebhook,
} from "../model/request/settings.request";

@Injectable()
export class SettingsProvider {
  constructor(private http: HttpClient) { }

  getSettingsPageData(): Observable<Settingsdetails> {
    return this.http
      .get(EndPoints.SETTINGS.BUSINESS_DATA, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  claimOverdraft(model = {}): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.CLAIM_OVERDRAFT, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getDeveloperKeys(): Observable<Developerkeys> {
    return this.http
      .get(EndPoints.SETTINGS.GET_DEVELOPER_KEYS, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  updateWebHook(model: UpdateWebhook): Observable<Developerkeys> {
    return this.http
      .post(EndPoints.SETTINGS.UPDATE_WEBHOOK, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  uploaddocument(model, type=6): Observable<any> {
    return this.http
      .post(`${EndPoints.SETTINGS.UPLOAD_DOCUMENT}/${type}`, model, {
        headers: {
          // "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  updateTransfertoBusinessSettings(model: boolean): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.TOGGLE_BUSINESSTRANSFER_SETTINGS, { "AllowDirectoryListing": model }, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  updateNotificationsSettings(model: NotificationsSettings): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.NOTIFICATIONS_SETTINGS, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  updateMerchantType(model): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.MERCHANT_TYPE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  updateProfile(model: Updateprofile): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.UPDATE_PROFILE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  updateKyc(model): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.UPDATE_KYC, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  updateDocument(model): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.UPLOAD_DOCUMENT, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  updateLogo(model): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.UPLOAD_LOGO, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  changePassword(model: Changepassword): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.CHANGE_PASSWORD, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  changeTransactioncode(model: ChangeTransactionCode): Observable<any> {
    return this.http
      .post(EndPoints.SETTINGS.CHANGE_TRANSACTION_CODE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
