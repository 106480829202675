import { Action } from "@ngrx/store";

export enum SettinfgsActionTypes {
  ResetSettingsdataState = "[SETTINGSDATA] Reset Data",
  FETCHSETTINGSDATA = "[SETTINGSDATA] Fetch Settings Data",
  UPDATESETTINGSDATA = "[SETTINGSDATA] UPDATE Settings Data",
  UPDATESOMESETTINGSDATA = "[SETTINGSDATA] UPDATE SOME Settings Data",
}

// Action Creators
export class ResetSettingsdataState implements Action {
  readonly type = SettinfgsActionTypes.ResetSettingsdataState;
}

export class FETCHSETTINGSDATA implements Action {
  readonly type = SettinfgsActionTypes.FETCHSETTINGSDATA;
  constructor(public payload: any) {
    this.type = SettinfgsActionTypes.FETCHSETTINGSDATA;
  }
}


export class UPDATESETTINGSDATA implements Action {
  readonly type = SettinfgsActionTypes.UPDATESETTINGSDATA;

  constructor(public payload: any) {
    this.type = SettinfgsActionTypes.UPDATESETTINGSDATA;
  }
}


export class UPDATESOMESETTINGSDATA implements Action {
  readonly type = SettinfgsActionTypes.UPDATESOMESETTINGSDATA;

  constructor(public payload: any) {
    this.type = SettinfgsActionTypes.UPDATESOMESETTINGSDATA;
  }
}




export type SettingsdataActions = ResetSettingsdataState | UPDATESETTINGSDATA | UPDATESOMESETTINGSDATA;
