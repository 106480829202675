import {CapitalCasePipe} from './capital-case.pipe';
import {DebouncePipe} from './debounce.pipe';
import {DefaultValuePipe} from './default-value.pipe';
import {ElementPositionPipe} from './element-position.pipe';
import {EncryptedPipe} from './encrypted.pipe';
import {FilterPipe} from './filter.pipe';
import {NoSpacesPipe} from './no-spaces.pipe';
import {OrderByPipe} from './order-by.pipe';
import {ReversePipe} from './reverse.pipe';
import {TitleCasePipe} from './title-case.pipe';
import {NumberWithCommasPipe} from './number-with-commas.pipe';
import {PluralPipe} from './plural.pipe';
import {RoundPipe} from './round.pipe';
import {TimingPipe} from './timing.pipe';
import {EllipsePipe} from './ellipse.pipe';

export const pipes = [
  CapitalCasePipe,
  DebouncePipe,
  DefaultValuePipe,
  ElementPositionPipe,
  EncryptedPipe,
  FilterPipe,
  NoSpacesPipe,
  OrderByPipe,
  ReversePipe,
  TitleCasePipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  EllipsePipe
];




