export interface AllTransactionsResponse {
  isLoading?: boolean;
  isFilter?: boolean;
  isFirstLoad?: boolean;
  allTransactions?: transaction[];
  transactionsReport?: Transactionmeta;
}

export interface CryptoTransactionsResponse{
  totalItems?: number;
  items?: CryptoTransaction[]
}

export interface CryptoTransaction{
  address: string;
  sourceAddress: string;
  destinationAddress: string;
  transactionType: number;
  gas: number;
  amount: number;
  transactionRef?: string;
  blockchainHash?: string;
  dateOfTransaction: string;
  message?: string;
  narration?: string;
  status: string;
}

export interface Transactionmeta {
  totalItems?: number;
  currentPage?: number;
  pageSize?: number;
  totalPages?: number;
  startPage?: number;
  endPage?: number;
}
// tslint:disable-next-line:class-name
export interface transaction {
  recipient?: string;
  accountNumber?: string;
  amount?: string;
  date?: string;
  reference?: string;
  fee?: number;
  time?: string;
  status?: string;
  sessionID?: string;
  category?: string;
  narration?: string;
  accountName?: string;
  bank?: string;
  networkProvider?: string;
  phoneNumber?: string;
  topupVia?: string;
  biller?: string;
  type?: transactionType;
  merchantRefrence?: string;
  bulkTransferList?: RecipientDetails[];
  transactionType?: number;
  balance?: number;
}

export interface RecipientDetails {
  accountNumber?: number;
  accountName?: string;
  bank?: string;
  refrence?: string;
  amount?: number;
  status?: number;
}
export enum transactionType {
  bankTransfer = "Bank Transfer",
  bankTransferCharge = "Bank Transfer Charges",
  airtimePurchase = "Airtime Purchase",
  billPayment = "Bill Payment",
  walletTransfer = "Wallet Transfer",
  apiBankTransfer = "Api Bank Transfer",
  bulkBankTransfer = "Bulk Bank Transfer",
  bulkWalletTransfer = "Bulk Wallet Transfer",
  internationalTransferfee = "International Transfer Fee",
  internationalTransferKenya = "International Transfer (KES)",
  internationalTransferGhana = "International Transfer (MPESA)",
  bulkAirtime = "Bulk Airtime Purchase",
  transferToBusiness = "Transfer To Business",
  Funding = "Funding",
  all = "",
}

export const transationCategoriesList = [
  { name: "All", id: 1 },
  { name: "Bank Transfer", id: 2 },
  { name: "Bank Transfer Charges", id: 3 },
  { name: "Airtime Purchase", id: 4 },
  { name: "Bill Payment", id: 5 },
  { name: "Wallet Transfer", id: 6 },
  { name: "Transfer To Business", id: 15 },
  { name: "Api Bank Transfer", id: 7 },
  { name: "Bulk Bank Transfer", id: 8 },
  { name: "Bulk Wallet Transfer", id: 9 },
  { name: "International Transfer Fee", id: 10 },
  { name: "International Transfer (GHS)", id: 11 },
  { name: "International Transfer (KES)", id: 12 },
  { name: "Bulk Airtime Purchase", id: 13 },
  { name: "Wallet Topup", id: 14 },
  { name: "Reversal", id: 15 }
];

export const CryptoTransationCategoriesList = [
  { name: "All", id: 0 },
  { name: "Credit", id: 1 },
  { name: "Debit", id: 2 }
];

export const transationCategoriesP = [
  { name: "Bank Transfer", id: 2 },
  { name: "Airtime Purchase", id: 4 },
  { name: "Bill Payment", id: 5 },
  { name: "Wallet Transfer", id: 6 },
  { name: "Api Bank Transfer", id: 7 },
  { name: "Bulk Bank Transfer", id: 8 },
  { name: "Bulk Wallet Transfer", id: 9 },
  { name: "Transfer To Business", id: 15 },
];

export const DefaultTransaction: transaction = {
  date: "",
  category: "",
  reference: "",
  narration: "",
  amount: "",
  accountName: "",
  accountNumber: "",
  bank: "",
  biller: "",
  type: transactionType.bankTransfer,
};

export interface Transferdetails {
  category?: string;
  recipient?: string;
  sender?: string;
  phoneNumber?: string;
  billName?: string;
  customerId?: string;
  accountNumber?: string;
  bank?: string;
  amount?: number;
  data?: string;
  transactionRefrence?: string;
  charge?: number;
  date?: string; //datetime format like dashboard
  status?: string;
  sessionId?: string;
  networkProvider?: string;
  merchantReference?: string;
  bankRecipientList?: Bankrecipient[];
  walletRecipientList?: Walletrecipient[];
  internationalRecipientList?: Internationalrecipient[];
  airtimeRecipientList?: AirtimeRecipient[];
  transactionType?: transactionType;
  narration?: string;
  businessName?: string;
  businessReference?:string;
}

export interface AirtimeRecipient {
  amount?: number;
  billName?: string;
  bulkAirtimeTransferProcessing?: string;
  bulkAirtimeTransferProcessingId?: number;
  bulkAirtimeTransferProcessingItemId?: number;
  name?: string;
  paymentCode?: string;
  phoneNumber?: string;
  status?: string;
  transactionReference?: string;
}

export interface Bankrecipient {
  accountNumber?: string;
  accountName?: string;
  refrence?: string;
  amount?: number;
  bank?: string;
  transactionReference?: string;
  status?: string;
}
export interface Walletrecipient {
  phoneNumber?: string;
  name?: string;
  refrence?: string;
  amount?: number;
  status?: string;
  firstName?: string;
  lastName?: string;
}
export interface Internationalrecipient {
  phoneNumber?: string;
  name?: string;
  refrence?: string;
  amount?: number;
  country?: string;
  status?: string;
}
