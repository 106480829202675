import { Component } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AnalyticsService } from "./shared/services/analytics.service";
import { Title } from "@angular/platform-browser";
import { filter, map } from "rxjs/operators";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { AuthProvider } from "./service/auth.service";
import { DashboardService } from "./service/dashboard.service";
import { AuthdataProvider } from "./dataservice/authdata.service";
import * as dayjs from "dayjs";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  idleState = "Not started.";
  timedOut = false;
  isKommunicate = false;
  lastPing?: Date = null;
  title = "angular-idle-timeout";
  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthProvider,
    private titleService: Title,
    private idle: Idle,
    private keepalive: Keepalive,
    private dashBoardService: DashboardService,
    private authdataService: AuthdataProvider,
  ) {
    console.log(`Welcome to wallets console 😎 🙌🏿 🚀 😎`);

    this.analyticsLoging();
    this.setIdle();
  }

  setupOutsideInterCom() {
    // (window as any).intercomSettings = {
    //   app_id: "q0xoucr2",
    // };
    if (this.isKommunicate) {
      return;
    }

    ((d, m) => {
      let kommunicateSettings = { appId: "39726de824d81a94c442c8e5978314eab" };
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://api.kommunicate.io/v2/kommunicate.app";
      let h = document.getElementsByTagName("head")[0];
      h.appendChild(s);
      (window as any).kommunicate = m;
      m._globals = kommunicateSettings;
    })(document, (window as any).kommunicate || {});
    this.isKommunicate = true;
  }

  setupLoginInterCom() {
    // (window as any).intercomSettings = {
    //   app_id: "q0xoucr2",
    //   email: this.authService.getLoginData().data.email,
    //   user_id: this.authService.getLoginData().data.phoneNumber,
    //   created_at: this.authService.getLoginData().data.createdat
    // };

    if (this.isKommunicate) {
      return;
    }
    ((d, m) => {
      let kommunicateSettings = {
        appId: "39726de824d81a94c442c8e5978314eab",
        userId: this.authService.getLoginData().data.phoneNumber,
        userName: this.authService.getLoginData().data.businessName,
        email: this.authService.getLoginData().data.email,
        contactNumber: this.authService.getLoginData().data.phoneNumber,
      };
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://api.kommunicate.io/v2/kommunicate.app";
      let h = document.getElementsByTagName("head")[0];
      h.appendChild(s);
      (window as any).kommunicate = m;
      m._globals = kommunicateSettings;
    })(document, (window as any).kommunicate || {});

    this.isKommunicate = true;
  }

  analyticsLoging() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.analyticsService.trackPageViews(event);

        // this.analyticsService.trackEvent(null, null, null)
      }
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data["title"]) {
              return child.snapshot.data["title"];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.authdataService.setTitle("Krypto");
          // this.authdataService.setTitle("Wallets Business | " + data );
          if (
            data === "Login"
            || data === "Sign Up"
            || data === "Forgot Password"

          ) {
            this.idle.stop();
            // this.setupOutsideInterCom();
            let tokens = localStorage.getItem('token');

            if (tokens) {
              // this.router.navigate(["/dashboard"]);
            }
          } else if (data === "My Dashboard") {
            this.idle.watch();
            // this.setupLoginInterCom();
            this.dashBoardService.fetchTheNewBalance();
          } else if (data === "Subwallets") {
            let loginData = this.authService.getLoginData();

            if (loginData && loginData.data && loginData.data.isSubWallet) {
              this.router.navigate(["/dashboard"]);
            }
          } else {
            if (data == "Set Password"
              || data == "Verify Account"
              || data == "Verify Developer Account"
              || data == "Reset Password") {
              return
            }
            this.dashBoardService.fetchTheNewBalance();
            // this.setupLoginInterCom();
          }

          let logindata = this.authService.getLoginData();
          const date1 = dayjs()
          const date2 = dayjs(logindata.refreshtoken_expires_in)

          let diff = date2.diff(date1)

          if (diff < 0) {
            this.authService.logout();
          }
        }
      });
  }

  setIdle() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(180);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(120);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      this.authService.logout(true);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
}
