import { AppState } from './../app.state';
import { UserdataState } from "src/app/store/userdata/userdata.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DashboardState } from '../dashboarddata/dashboarddata.reducer';


const getUserdata = createFeatureSelector<UserdataState>("userdata");

export const getLoggedinUserdata = createSelector(
  getUserdata,
  state => state
);

export const selectUser = (state: AppState) => state.userdata;
export const selectDashboarddata = (state: AppState) => state.dashboarddata;

export const selectAllUserData = createSelector(
  selectUser,
  selectDashboarddata,
  (selectedUser: UserdataState, dashboardData: DashboardState) => {
    return { ...selectedUser, ...dashboardData }
  }
);
