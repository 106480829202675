import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CryptoWalletTransaction } from 'src/app/model/response/create_crypto_address.response';

@Component({
  selector: 'app-crypto-transaction-modal',
  templateUrl: './crypto-transaction-modal.component.html',
  styleUrls: ['./crypto-transaction-modal.component.scss']
})
export class CryptoTransactionModalComponent implements OnInit {

  transaction: CryptoWalletTransaction = {};
  walletAddress: string = '';
  isLoading = false;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.transaction == null? this.isLoading = true : this.isLoading = false;
  }

  getNairaTransactionFee(amount, amountInNaira, transactionFee) {
    return ((amountInNaira * transactionFee) / amount).toFixed(2);
  }

  getTransactionType(destinationAddress: string) {
    if(destinationAddress.toLowerCase() === this.walletAddress)
    {
      return 'Credit';
    }
    if(destinationAddress.toLowerCase() !== this.walletAddress) {
      return 'Debit';
    }
  }

}
