import { data } from "jquery";
import {
  NairaCardbalance,
  DollarCardDetail,
  DollarCardData,
  DollarCards,
  DollarCardTransaction,
  NairaCardTransaction,
  StateResponseModel,
} from "./../model/response/card.response";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import {
  NewCardRequest,
  FundNairaCard,
  RequestNairaCard,
  DollarCardActions,
  CreatedollarCard,
  Nairacardactions,
  NairacardWithdraw,
  NairacardwithdrawPin,
} from "../model/request/card.request";
import { NairaCardDetail, Cardrequests } from "../model/response/card.response";
import { EndPoints } from "../config/endpoints";
import { catchError, map } from "rxjs/operators";
import { BankListandCodeDo } from "../model/response/transfer.response";
import { DashboardService } from "./dashboard.service";
import { FullcardResponse } from '../model/response/funding.response';

@Injectable()
export class CardService {
  constructor(
    private http: HttpClient,
    private dashBoardService: DashboardService
  ) { }

  fetchNairaCardRequests(
    phonenumber: string = null
  ): Observable<Array<Cardrequests>> {
    const parameters = phonenumber ? `?PhoneNumber=${phonenumber}` : "";
    return this.http
      .get(EndPoints.CARDS.NAIRA_CARD.GET_CARDS_REQUESTS + parameters, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  fetchNairaCards(
    phonenumber: string = null
  ): Observable<Array<NairaCardDetail>> {
    const parameters = phonenumber ? `?PhoneNumber=${phonenumber}` : "";
    return this.http
      .get(EndPoints.CARDS.NAIRA_CARD.GETCARDS + parameters, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getNairaCardBalance(
    barterid: string,
    last4: string,
    phonenumber: string = null,
  ): Observable<NairaCardbalance> {
    const searchparam = barterid ? `BarterCardId=${barterid}` : `last4=${last4}`;
    const parameters = phonenumber
      ? `?${searchparam}&PhoneNumber=${phonenumber}`
      : `?${searchparam}`;
    return this.http
      .get(EndPoints.CARDS.NAIRA_CARD.GETBALANCE + parameters, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getNairaCardDetails(
    barterid: string,
    last4: string,
    phonenumber: string = null
  ): Observable<Array<NairaCardTransaction>> {
    const searchparam = barterid ? `BarterCardId=${barterid}` : `last4=${last4}`;
    return this.http
      .get(EndPoints.CARDS.NAIRA_CARD.GET_CARD_DATA + `?${searchparam}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getNairaCardData(): Observable<any> {
    return this.http
      .get(EndPoints.CARDS.NAIRA_CARD.NAIRACARDDATA, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getStates(): Observable<StateResponseModel[]> {
    return this.http
      .get(EndPoints.CARDS.NAIRA_CARD.STATES, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  withdrawPinCard(model: NairacardwithdrawPin): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.NAIRA_CARD.FUND, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  fundnairaCard(model: FundNairaCard): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.NAIRA_CARD.FUND, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          this.dashBoardService.fetchTheNewBalance();
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  withdrawnairacard(model: FundNairaCard): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.NAIRA_CARD.WITHDRAW2, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          this.dashBoardService.fetchTheNewBalance();
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  requestNairaCard(model: RequestNairaCard): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.NAIRA_CARD.REQUEST_NEW_CARD, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          this.dashBoardService.fetchTheNewBalance();
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  freezeNairaCard(model: Nairacardactions): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.NAIRA_CARD.FREEZE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  unfreezeNairaCard(model: Nairacardactions): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.NAIRA_CARD.UNFREEZE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  fundDollarCard(model: DollarCardActions): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.DOLLAR_CARD.FUND, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          this.dashBoardService.fetchTheNewBalance();
          return data;
        }),

        catchError((error) => {
          return throwError(error);
        })
      );
  }
  withdrawNairaCard(model: NairacardWithdraw): Observable<FullcardResponse> {
    return this.http
      .post(EndPoints.CARDS.NAIRA_CARD.WITHDRAW, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          this.dashBoardService.fetchTheNewBalance();
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  withdrawDollarCard(model: DollarCardActions): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.DOLLAR_CARD.WITHDRAW, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          this.dashBoardService.fetchTheNewBalance();
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  freezeDollarCard(model: DollarCardActions): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.DOLLAR_CARD.FREEZE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deactivateDollarCard(model: DollarCardActions): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.DOLLAR_CARD.DEACTIVATE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  unfreezeDollarCard(model: DollarCardActions): Observable<any> {
    return this.http
      .post(EndPoints.CARDS.DOLLAR_CARD.UNFREEZE, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getDollarCards(params: string = ""): Observable<DollarCards> {
    return this.http
      .get(EndPoints.CARDS.DOLLAR_CARD.GETCARDS + params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getDollarData(
    barterCardId: string,
    phoneNumber: string = null
  ): Observable<DollarCardDetail> {
    let parametars = phoneNumber
      ? `?BarterCardId=${barterCardId}&PhoneNumber=${phoneNumber}`
      : `?BarterCardId=${barterCardId}`;
    return this.http
      .get(EndPoints.CARDS.DOLLAR_CARD.DATA + parametars, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getDollarTransactions(
    barterCardId: string,
    phoneNumber: string = null
  ): Observable<Array<DollarCardTransaction>> {
    let parametars = phoneNumber
      ? `?BarterCardId=${barterCardId}&PhoneNumber=${phoneNumber}`
      : `?BarterCardId=${barterCardId}`;
    return this.http
      .get(EndPoints.CARDS.DOLLAR_CARD.TRANSACTIONS + parametars, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  requestDollarCard(
    newCardRequest: CreatedollarCard
  ): Observable<DollarCardDetail> {
    return this.http
      .post(EndPoints.CARDS.DOLLAR_CARD.CREATE_NEW_CARD, newCardRequest, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          this.dashBoardService.fetchTheNewBalance();
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
