import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
@Injectable()

export class GoogleExcelService {
    constructor(private http: HttpClient) {
    }

    isGoogleSignedIn() {
        return (window as any).gapi.auth2.getAuthInstance().isSignedIn.get();
    }

    signInGoogle() {
        (window as any).gapi.auth2.getAuthInstance().signIn();
    }
    listentosignin() {
        // (window as any). gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);;
    }

    getUserData() {
        (window as any).gapi.client.gmail.users.getProfile({
            'userId': 'me'
        }).then(function (response) {
        });
        return "fkdfjkd"
    }
    signOutGoogle() {
        (window as any).gapi.auth2.getAuthInstance().signOut();
    }

    fetchExcelId(url) {
        //todo: test if it is a wrong url.
        let excelUrlArray = url.split('/');
        return excelUrlArray[5]
    }

    fetchexcelData(sheetId) {
        return (window as any).gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: sheetId,
            range: "Sheet1!A2:E"
        });
    }
}
