import { Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { DashboardResponse } from "../model/response/dashboard.response";
import { CryptoService } from "../service/crypto.service";
import { FullcardResponse } from "../model/response/funding.response";
import { SavedCardsResponse } from "../model/request/funding.request";

@Injectable()
export class DashboarddataProviders {
  defaultData: DashboardResponse = {
    stats: [],
    recentTransactions: [],
    walletBalance: 0,
  };
  allCards: SavedCardsResponse = {
    cardChargeCap: 0,
    cardChargePercentage: 0,
    cards: [],
    isLoading: false,
  };
  private cryptoFee$ = new BehaviorSubject<number>(0);
  private cards$ = new BehaviorSubject<SavedCardsResponse>(this.allCards);
  private dashboardData$ = new BehaviorSubject<DashboardResponse>(
    this.defaultData
  );
  constructor(private cryptoService: CryptoService) {
    this.dashboardData$.next(JSON.parse(localStorage.getItem("userdata")));
  }

  resetdata() {
    // this.updateDetails({
    //   stats: [],
    //   recentTransactions: [],
    //   walletBalance: 0,
    // });
    this.dashboardData$.next({
      stats: [],
      recentTransactions: [],
      walletBalance: 0,
    });
    this.updateSavedCards({
      cardChargeCap: 0,
      cardChargePercentage: 0,
      cards: [],
      isLoading: false,
    });
  }

  fetchCryptoData() {
    this.cryptoService.getfee().subscribe((data) => {
      this.cryptoFee$.next(data.feeInPercentage);
    });
  }
  fetchSavedCards() {
    this.cryptoService.getfee().subscribe((data) => {
      this.cryptoFee$.next(data.feeInPercentage);
    });
  }
  updateDetails(details: DashboardResponse) {
    this.dashboardData$.next(details);
    localStorage.setItem("userdata", JSON.stringify(details));
  }
  updateSavedCards(details: SavedCardsResponse) {
    this.cards$.next(details);
  }

  getLoginDetails(): Observable<DashboardResponse> {
    return this.dashboardData$.asObservable();
  }
  getCryptoDetails(): Observable<number> {
    return this.cryptoFee$.asObservable();
  }
  getSavedCards(): Observable<SavedCardsResponse> {
    return this.cards$.asObservable();
  }
}
