
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonlyUsedModule } from 'src/app/shared/modules/commonlyused.module';
import { PinchangemodalComponent } from './pinchangemodal.component';


@NgModule({
  declarations: [PinchangemodalComponent],
  exports: [PinchangemodalComponent],
  entryComponents: [PinchangemodalComponent],
  imports: [
    CommonModule,
    CommonlyUsedModule
  ]
})
export class PinchangemodalModule { }
