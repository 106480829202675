
/* NgRx */
import { Action } from '@ngrx/store';
import { CreateCryptoAddressRequest } from 'src/app/model/request/create_crypto_address.request';
import { FundCryptoWalletRequest, CryptoTransactionResponse, ExchangeRateQueryParams, TransferToCryptoAddressRequest, GetUserCryptoTransactionsRequest, GetUserCryptoWalletRequest, SendCryptoRequest, SendETHRequest } from '../../model/request/fund_crypto_wallet.model';
import { AnyFn } from '@ngrx/store/src/selector';

export enum CryptoActionTypes {
  CreateCryptoAddress = '[Crypto] Create Crypto Address',
  CreateCryptoAddressSuccess = '[Crypto] Create Crypto Address Success',
  CreateCryptoAddressFailure = '[Crypto] Create Crypto Address Failure',
  TransferToCryptoAddress = '[Crypto] Transfer To Crypto Address',
  TransferToCryptoAddressSuccess = '[Crypto] Transfer To Crypto Address Success',
  TransferToCryptoAddressFailure = '[Crypto] Transfer To Crypto Address Failure',
  FundCryptoWallet = '[Crypto] Fund Crypto Wallet',
  FundCryptoWalletSuccess = '[Crypto] Fund Crypto Wallet Success',
  FundCryptoWalletFailure = '[Crypto] Fund Crypto Wallet Failure',
  WithdrawFromCryptoWallet = '[Crypto] Withdraw From Crypto Wallet',
  WithdrawFromCryptoWalletSuccess = '[Crypto] Withdraw From Crypto Wallet Success',
  WithdrawFromCryptoWalletFailure = '[Crypto] Withdraw From Crypto Wallet Failure',
  GetUserCryptoWallet = '[Crypto] Get User Crypto Wallets',
  GetUserCryptoWalletSuccess = '[Crypto] Get User Crypto Wallets Success',
  GetUserCryptoWalletFailure = '[Crypto] Get User Crypto Wallets Failure',
  GetCryptoToFiatExchangeRate = '[Crypto] Get Crypto To Fiat Exchange Rate',
  GetCryptoToFiatExchangeRateSuccess = '[Crypto] Get Crypto To Fiat Exchange Rate Success',
  GetCryptoToFiatExchangeRateFailure = '[Crypto] Get Crypto To Fiat Exchange Rate Failure',
  GetUserCryptoTransactions = '[Crypto] Get User Crypto Transactions',
  GetUserCryptoTransactionsSuccess = '[Crypto] Get User Crypto Transactions Success',
  GetUserCryptoTransactionsFailure = '[Crypto] Get User Crypto Transactions Failure',
}

// Action Creators

export class CreateCryptoAddress implements Action {
  readonly type = CryptoActionTypes.CreateCryptoAddress;

  constructor() {
      
  }
}

export class CreateCryptoAddressSuccess implements Action {
  readonly type = CryptoActionTypes.CreateCryptoAddressSuccess;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.CreateCryptoAddressSuccess
  }
}

export class CreateCryptoAddressFailure implements Action {
  readonly type = CryptoActionTypes.CreateCryptoAddressFailure;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.CreateCryptoAddressFailure
  }
}

export class TransferToCryptoAddress implements Action {
  readonly type = CryptoActionTypes.TransferToCryptoAddress;

  constructor(public payload: SendCryptoRequest) {
      this.type = CryptoActionTypes.TransferToCryptoAddress;
  }
}
export class TransferToETHAddress implements Action {
  readonly type = CryptoActionTypes.TransferToCryptoAddress;

  constructor(public payload: SendETHRequest) {
      this.type = CryptoActionTypes.TransferToCryptoAddress;
  }
}

export class TransferToCryptoAddressSuccess implements Action {
  readonly type = CryptoActionTypes.TransferToCryptoAddressSuccess

  constructor(public payload: CryptoTransactionResponse) {
      this.type = CryptoActionTypes.TransferToCryptoAddressSuccess;
  }
}

export class TransferToCryptoAddressFailure implements Action {
  readonly type = CryptoActionTypes.TransferToCryptoAddressFailure;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.TransferToCryptoAddressFailure;
  }
}

export class FundCryptoWallet implements Action {
  readonly type = CryptoActionTypes.FundCryptoWallet;

  constructor(public payload: FundCryptoWalletRequest) {
      this.type = CryptoActionTypes.FundCryptoWallet;
  }
}

export class FundCryptoWalletSuccess implements Action {
  readonly type = CryptoActionTypes.FundCryptoWalletSuccess

  constructor(public payload: CryptoTransactionResponse) {
      this.type = CryptoActionTypes.FundCryptoWalletSuccess;
  }
}

export class FundCryptoWalletFailure implements Action {
  readonly type = CryptoActionTypes.FundCryptoWalletFailure;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.FundCryptoWalletFailure;
  }
}
export class WithdrawFromCryptoWallet implements Action {
  readonly type = CryptoActionTypes.WithdrawFromCryptoWallet;

  constructor(public payload: FundCryptoWalletRequest) {
      this.type = CryptoActionTypes.WithdrawFromCryptoWallet;
  }
}

export class WithdrawFromCryptoWalletSuccess implements Action {
  readonly type = CryptoActionTypes.WithdrawFromCryptoWalletSuccess

  constructor(public payload: CryptoTransactionResponse) {
      this.type = CryptoActionTypes.WithdrawFromCryptoWalletSuccess;
  }
}

export class WithdrawFromCryptoWalletFailure implements Action {
  readonly type = CryptoActionTypes.WithdrawFromCryptoWalletFailure;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.WithdrawFromCryptoWalletFailure;
  }
}

export class GetUserCryptoWallet implements Action {
  readonly type = CryptoActionTypes.GetUserCryptoWallet;

  constructor(public payload: GetUserCryptoWalletRequest) {
      this.type = CryptoActionTypes.GetUserCryptoWallet;
  }
}

export class GetUserCryptoWalletSuccess implements Action {
  readonly type = CryptoActionTypes.GetUserCryptoWalletSuccess;

  constructor(public payload: any[]) {
      this.type = CryptoActionTypes.GetUserCryptoWalletSuccess;
  }
}

export class GetUserCryptoWalletFailure implements Action {
  readonly type = CryptoActionTypes.GetUserCryptoWalletFailure;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.GetUserCryptoWalletFailure;
  }
}

export class GetCryptoToFiatExchangeRate implements Action {
  readonly type = CryptoActionTypes.GetCryptoToFiatExchangeRate;

  constructor(public payload: ExchangeRateQueryParams) {
      this.type = CryptoActionTypes.GetCryptoToFiatExchangeRate;
  }
}

export class GetCryptoToFiatExchangeRateSuccess implements Action {
  readonly type = CryptoActionTypes.GetCryptoToFiatExchangeRateSuccess;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.GetCryptoToFiatExchangeRateSuccess;
  }
}

export class GetCryptoToFiatExchangeRateFailure implements Action {
  readonly type = CryptoActionTypes.GetCryptoToFiatExchangeRateFailure;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.GetCryptoToFiatExchangeRateFailure;
  }
}

export class GetUserCryptoTransactions implements Action {
  readonly type = CryptoActionTypes.GetUserCryptoTransactions;

  constructor(public payload: GetUserCryptoTransactionsRequest) {
      this.type = CryptoActionTypes.GetUserCryptoTransactions;
  }
}

export class GetUserCryptoTransactionsSuccess implements Action {
  readonly type = CryptoActionTypes.GetUserCryptoTransactionsSuccess;

  constructor(public payload: any[]) {
      this.type = CryptoActionTypes.GetUserCryptoTransactionsSuccess;
  }
}

export class GetUserCryptoTransactionsFailure implements Action {
  readonly type = CryptoActionTypes.GetUserCryptoTransactionsFailure;

  constructor(public payload: any) {
      this.type = CryptoActionTypes.GetUserCryptoTransactionsFailure;
  }
}




export type CryptoActions = CreateCryptoAddress
| CreateCryptoAddressSuccess
| CreateCryptoAddressFailure
| TransferToCryptoAddress
| TransferToCryptoAddressSuccess
| TransferToCryptoAddressFailure
| FundCryptoWallet
| FundCryptoWalletSuccess
| FundCryptoWalletFailure
| WithdrawFromCryptoWallet
| WithdrawFromCryptoWalletSuccess
| WithdrawFromCryptoWalletFailure
| GetUserCryptoWallet
| GetUserCryptoWalletSuccess
| GetUserCryptoWalletFailure
| GetCryptoToFiatExchangeRate
| GetCryptoToFiatExchangeRateSuccess
| GetCryptoToFiatExchangeRateFailure
| GetUserCryptoTransactions
| GetUserCryptoTransactionsSuccess
| GetUserCryptoTransactionsFailure;