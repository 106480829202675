import { DashboardService } from "./dashboard.service";
import { ReportService } from "./reports.service";
import { CardService } from "./cards.service";
import { RequestmoneyService } from "./requestmoney.service";
import { GoogleExcelService } from "./googleexcel.service";
import { PrintjsService } from "./printjs.service";
import { TransferProvider } from "./transfer.service";
import { AirtimeProvider } from "./airtime.service";
import { SettingsProvider } from "./settings.service";
import { AuthProvider } from "./auth.service";
import { SubwalletProvider } from "./subwallet.service";
import { CryptoService } from "./crypto.service";
import { CardFundingService } from "./cardfunding.service";
import { ReceiptDownloadervice } from './receiptdownload.service';
import { WalletsCryptoService } from '../state/crypto/crypto.service';
import { WebStorageService } from './web-storage.service';

export const AppServices = [
         DashboardService,
         ReportService,
         CardService,
         RequestmoneyService,
         GoogleExcelService,
         PrintjsService,
         TransferProvider,
         AirtimeProvider,
         SettingsProvider,
         AuthProvider,
         SubwalletProvider,
         CryptoService,
         CardFundingService,
         ReceiptDownloadervice,
         WalletsCryptoService,
         WebStorageService
       ];
