import { SettingsdataProvider } from "./settingsdata.service";
import { AuthdataProvider } from "./authdata.service";
import { NigeriastatesDataProvider } from "./nigeriastates.service";
import { TransationdataProvider } from "./transactiondata.service";
import { CardsdataProvider } from "./cardsdata.service";
import { DashboarddataProviders } from "./dashboarddata.service";
import { SubwalletdataProvider } from './subwalletsdata.service';

export const AppdataServices = [
  AuthdataProvider,
  NigeriastatesDataProvider,
  SettingsdataProvider,
  TransationdataProvider,
  CardsdataProvider,
  SubwalletdataProvider,
  DashboarddataProviders,
];
