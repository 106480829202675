
import { LoginResponse } from 'src/app/model/response/auth.response';
import { RequestMoneyResponse } from 'src/app/model/response/requestmoney.response';
import { RequestmoneydataActions, RequestmoneyLinkActionTypes } from './requestmoneylink.actions';



export interface RequestMoneyState extends RequestMoneyResponse {
  message?: string;

}



const initialState: RequestMoneyState = {

  message: "",
  stats: [],
  requestLinks: []

};

export function Requestmoneydatareducer(state = initialState, action: RequestmoneydataActions): RequestMoneyState {

  switch (action.type) {
    case RequestmoneyLinkActionTypes.ResetRequestMoneyLink:
      return {
        ...initialState

      };
    case RequestmoneyLinkActionTypes.UpdateRequestmoney:
      return {
        ...state,
        ...action.payload

      };
    case RequestmoneyLinkActionTypes.AddItemRequestmoney:
      return {
        ...state,
        requestLinks: [...state.requestLinks, action.payload]

      };
    case RequestmoneyLinkActionTypes.DeleteItemRequestmoney:
      return {
        ...state,
        requestLinks: state.requestLinks.filter((moneyrequests) => {

          return moneyrequests.meLinkId !== action.payload.meLinkId;
        })

      };
    default:
      return {
        ...state
      };
  }
}

