import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { filter } from "rxjs/operators";
import {
  transaction,
  AllTransactionsResponse, CryptoTransaction
} from "../model/response/transactions.response";
import { FormatService } from "../shared/services/formatter.service";
import { AuthProvider } from "./auth.service";
import { DollarCardTransaction } from "../model/response/card.response";

declare const ga: any;

@Injectable()
export class PrintjsService {
  startDate = "";
  enddate = "";
  openingBalance;
  closingBalance;
  cashInflow;
  cashOutflow;

  dollarstartDate = "";
  dollarenddate = "";
  dollaropeningBalance;
  dollarclosingBalance;
  dollarcashInflow;
  dollarcashOutflow;
  constructor(
    private formatService: FormatService,
    private authService: AuthProvider
  ) {}

  initiate(content) {
    let WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=600,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(content);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    // WinPrint.close()
  }

  generateInvoice(transaction) {
    let rowsm = this.generateTransactionRows(transaction);
    return `
        <html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>Account Statement</title>
    <link href="https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="style.css">
    <style>

        * {
            margin: 0;
            padding: 0;
        }
        body {
            font-size: 8px;
            font-family: 'IBM Plex Sans', sans-serif;
            font-weight: normal;
            font-style: normal;
            color: rgba(0, 0, 0, 0.8);
            -webkit-print-color-adjust: exact;
        }
        .container {
            position: relative;
            width: 600px;
            height: 100vh;
            margin: auto;
            padding: 40px 30px;
        }
        .nav {
            display: flex;
            justify-content: space-between;
        }
        .nav .brand img {
            width: 100px;
            border-radius: 100px;
        }

        .nav .customer-info {
            width: 40%;
            text-align: right;
        }
        .nav .customer-info .customer-name {
            font-size: 12px;
            margin-bottom: 0;
            text-transform: uppercase;
        }
        .nav .customer-info .customer-address {
            margin-top: 10px;
            text-transform: capitalize;
        }
        .other-page .nav {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 5px 6px;
            -webkit-print-color-adjust: exact;
        }
        .statement-header {
            margin-top: 24px;
        }
        .statement-header .statement-title {
            font-weight: 500;
        }
        .statement-header .statement-details {
            background: #F5F5F5;
            -webkit-print-color-adjust: exact;
            display: flex;
            padding: 15px 25px;
            border-radius: 4px;
            margin-top: 20px;
        }
        .statement-header .statement-details .statement-detail {
            margin-right: 40px;
        }
        .statement-header .statement-details .statement-detail:last-child {
            margin-right: 0;
        }
        .statement-header .statement-details .statement-detail .title {
            font-weight: 500;
        }
        .statement-header .statement-details .statement-detail .value {
            margin-top: 6px;
        }
        table {
            position: relative;
            margin-top: 40px;
            width: 100%;
            border-collapse: inherit;
            border-spacing: 0 3px;
        }
        table tr th, table tr td {
            padding: 10px 20px 10px 10px;
            border: none !important;
            font-size: 8px;
        }

        @media print
        {
            table tr th, table tr td {
                border: none !important;
                -webkit-border: none !important;
                -moz-border: none !important;
                -o-border: none !important;
            }
        }

        table tr .description {
            width: 50%;
        }
        table tr .dateTime, table tr .debit, table tr .credit, table tr .balance{
            width: 12.5%;
        }
        table thead tr th {
            font-weight: 500;
        }
        .other-page table thead {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        table tbody tr:nth-child(odd) {
            background-color: rgba(0, 0, 0, 0.08);
            -webkit-print-color-adjust: exact
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        th {
            text-align: left;
        }
        .footer {
            position: relative;
            margin-top: 60px;
            bottom: 20px;
            width: inherit;
        }
        .footer .wallets-info {
            opacity: .6;
            font-size: 6px;
        }
        .footer .footer-info {
            position: relative;
            text-align: center;
            margin-top: 13px;
        }
        .footer .footer-info .disclaimer {
            opacity: .6;
            font-size: 6px;
        }
    </style>
</head>
<body>
    <div class="container">
        <section class="nav">
            <div class="brand">
            <img src="${
              this.authService.getLoginData().data.businessLogo
            }" alt="Wallets"></div>

            <div class = "customer-info" >
            <h3 class="customer-name"> ${
              this.authService.getLoginData().data.businessName
            } </h3>
                <p class="customer-address" > ${
                  this.authService.getLoginData().data.businessAddress || ""
                } </p>
                    </div>
                    </section>
                     <section class="statement-header">
    <p class="statement-title">
        Account Statement
    </p>
    <div class="statement-details" style="background: #F5F5F5;">
        <div class="statement-detail">
            <p class="title">Period</p>
            <p class="value">${this.startDate} - ${this.enddate}</p>
        </div>
        <div class="statement-detail">
            <p class="title"></p>
            <p class="value"></p>
        </div>
        <div class="statement-detail">
            <p class="title"></p>
            <p class="value"></p>
        </div>
        <div class="statement-detail">
            <p class="title">Total Cash Inflow</p>
            <p class="value">ETH${this.cashInflow}</p>
        </div>
        <div class="statement-detail">
            <p class="title">Total Cash Outflow</p>
            <p class="value">ETH${this.cashOutflow}</p>
        </div>
    </div>
</section>
                    <table >
                    <thead>
                    <tr>
                    <th class="dateTime" > Date  </th>
                    <th class="balance" > Amount </th>
                        <th class="credit" > Source </th>
                            <th class="debit" > Destination </th>
                                <th class="description" > Reference </th>
                                   
                                        </tr>
                                        </thead>
                                        <tbody >
                                        ${rowsm}
</tbody>
    </table>
    <section class="footer" >
        <div class="wallets-info" >
            <p>Krypto is a simple ethereum wallet created for Wallets customers. <br />
            
                </p>
                    <p > 
                </p>
    </div>
    <div class="footer-info" >
        <span class="disclaimer" >** This information is intended only for the person or entity to which it is addressed ** </span>
            <!-- <span class="page-number" > Page 1 0f 1 </span> -->
                </div>
                </section>
                </div>
                </body>
                </html>`;
  }

  generateTransactionRows(transactions: CryptoTransaction[]): string {
    let tString = ``;
    let cashIn = 0;
    let cashout = 0;
    for (let index = 0; index < transactions.length; index++) {
      const data: CryptoTransaction = transactions[index];
      if (index === 0) {
        this.enddate = this.formatDate(data.dateOfTransaction);
         
      }

      if (data.transactionType === 2) {
        cashout = cashout + Number(data.amount);
      }
      if (data.transactionType === 1) {
        cashIn = cashIn + Number(data.amount);
      }
      if (index === transactions.length - 1) {
        
        this.startDate = this.formatDate(data.dateOfTransaction);
        this.cashInflow = this.formatService.formatNumberToCurrency(cashIn);
        this.cashOutflow = this.formatService.formatNumberToCurrency(cashout);
      }
      tString += `
        <tr class="data-row">
        <td class="date-time">
          <span class="date">${this.formatDate(data.dateOfTransaction)}</span></td>
          <td style="color:${
            data.transactionType == 2 ? "#E4445D" : "#00C9B6"
          }" class="amount">ETH${this.formatService.formatNumberToCurrency(
          Number(data.amount)
        )}</td>
        <td class="source">${this.formatService.firstEight(data.sourceAddress)}....</td><td class="dest">${this.formatService.firstEight(data.destinationAddress)}....</td>
        <td class="reference">${data.blockchainHash}</td>
       
        </tr>
        `;
    }
    return tString;
  }

  generateDollarcardtransactions(transaction) {
    let rowsm = this.generateDollarcardrows(transaction);
    return `
        <html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>Dollar card Statement</title>
    <link href="https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="style.css">
    <style>

        * {
            margin: 0;
            padding: 0;
        }
        body {
            font-size: 8px;
            font-family: 'IBM Plex Sans', sans-serif;
            font-weight: normal;
            font-style: normal;
            color: rgba(0, 0, 0, 0.8);
            -webkit-print-color-adjust: exact;
        }
        .container {
            position: relative;
            width: 600px;
            height: 100vh;
            margin: auto;
            padding: 40px 30px;
        }
        .nav {
            display: flex;
            justify-content: space-between;
        }
        .nav .brand img {
            width: 100px;
            border-radius: 100px;
        }

        .nav .customer-info {
            width: 40%;
            text-align: right;
        }
        .nav .customer-info .customer-name {
            font-size: 12px;
            margin-bottom: 0;
            text-transform: uppercase;
        }
        .nav .customer-info .customer-address {
            margin-top: 10px;
            text-transform: capitalize;
        }
        .other-page .nav {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 5px 6px;
            -webkit-print-color-adjust: exact;
        }
        .statement-header {
            margin-top: 24px;
        }
        .statement-header .statement-title {
            font-weight: 500;
        }
        .statement-header .statement-details {
            background: #F5F5F5;
            -webkit-print-color-adjust: exact;
            display: flex;
            padding: 15px 25px;
            border-radius: 4px;
            margin-top: 20px;
        }
        .statement-header .statement-details .statement-detail {
            margin-right: 40px;
        }
        .statement-header .statement-details .statement-detail:last-child {
            margin-right: 0;
        }
        .statement-header .statement-details .statement-detail .title {
            font-weight: 500;
        }
        .statement-header .statement-details .statement-detail .value {
            margin-top: 6px;
        }
        table {
            position: relative;
            margin-top: 40px;
            width: 100%;
            border-collapse: inherit;
            border-spacing: 0 3px;
        }
        table tr th, table tr td {
            padding: 10px 20px 10px 10px;
            border: none !important;
            font-size: 8px;
        }

        @media print
        {
            table tr th, table tr td {
                border: none !important;
                -webkit-border: none !important;
                -moz-border: none !important;
                -o-border: none !important;
            }
        }

        table tr .description {
            width: 50%;
        }
        table tr .dateTime, table tr .debit, table tr .credit, table tr .balance{
            width: 12.5%;
        }
        table thead tr th {
            font-weight: 500;
        }
        .other-page table thead {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        table tbody tr:nth-child(odd) {
            background-color: rgba(0, 0, 0, 0.08);
            -webkit-print-color-adjust: exact
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        th {
            text-align: left;
        }
        .footer {
            position: relative;
            margin-top: 60px;
            bottom: 20px;
            width: inherit;
        }
        .footer .wallets-info {
            opacity: .6;
            font-size: 6px;
        }
        .footer .footer-info {
            position: relative;
            text-align: center;
            margin-top: 13px;
        }
        .footer .footer-info .disclaimer {
            opacity: .6;
            font-size: 6px;
        }
    </style>
</head>
<body>
    <div class="container">
        <section class="nav">
            <div class="brand">
            <img src="${
              this.authService.getLoginData().data.businessLogo
            }" alt="Wallets"></div>

            <div class = "customer-info" >
            <h3 class="customer-name"> ${
              this.authService.getLoginData().data.businessName
            } </h3>
                <p class="customer-address" > ${
                  this.authService.getLoginData().data.businessAddress||""
                } </p>
                    </div>
                    </section>
                     <section class="statement-header">
    <p class="statement-title">
        Dollar card Statement
    </p>
    <div class="statement-details" style="background: #F5F5F5;">
        <div class="statement-detail">
            <p class="title">Period</p>
            <p class="value">${this.dollarstartDate} - ${this.dollarenddate}</p>
        </div>
        <div class="statement-detail">
            <p class="title">Opening Balance</p>
            <p class="value">$${this.dollaropeningBalance}</p>
        </div>

    </div>
</section>
                    <table >
                    <thead>
                    <tr>
                    <th class="dateTime" > Date  </th>
                                <th class="description" > Narration </th>
                                    <th class="balance" > Amount </th>
                                        </tr>
                                        </thead>
                                        <tbody >
                                        ${rowsm}
</tbody>
    </table>
    <section class="footer" >
        <div class="wallets-info" >
            <p>Krypto is a simple ethereum wallet created for Wallets customers. <br />
                Banking services provided by ProvidusBank Limited.
                </p>
                    <p > Block 6, House 1b, Mobolaji Johnson Place, Adewunmi Adebimpe Street, Lekki Phase 1, <br/>
Lagos, Nigeria.
                </p>
    </div>
    <div class="footer-info" >
        <span class="disclaimer" >** This information is intended only for the person or entity to which it is addressed ** </span>
            <!-- <span class="page-number" > Page 1 0f 1 </span> -->
                </div>
                </section>
                </div>
                </body>
                </html>`;
  }

  generateDollarcardrows(transactions: DollarCardTransaction[]): string {
    let tString = ``;
    let cashIn = 0;
    let cashout = 0;
    for (let index = 0; index < transactions.length; index++) {
      const data: DollarCardTransaction = transactions[index];
      if (index === 0) {
        this.dollarclosingBalance = this.formatService.formatNumberToCurrency(
          Number(data.amount)
        );
        this.dollarenddate = this.formatDate(data.transaction_date);
        this.dollaropeningBalance = this.formatService.formatNumberToCurrency(
          10
        );
      }

      if (data.direction === "Debit") {
        cashout = cashout + Number(data.amount);
      }
      if (data.direction === "Credit") {
        cashIn = cashIn + Number(data.amount);
      }
      if (index === transactions.length - 1) {
        this.dollarstartDate = this.formatDate(data.transaction_date);
        this.dollarcashInflow = this.formatService.formatNumberToCurrency(
          cashIn
        );
        this.dollarcashOutflow = this.formatService.formatNumberToCurrency(
          cashout
        );
      }
      tString += `
        <tr class="data-row">
        <td class="date-time">
          <span class="date">${this.formatDate(data.transaction_date)}</span>
        <td class="narration">${data.description}</td>
        <td style="color:${
          data.direction === "Debit" ? "#E4445D" : "#00C9B6"
        }" class="amount">
        $${this.formatService.formatNumberToCurrency(Number(data.amount))}</td>
        </tr>
        `;
    }
    return tString;
  }
  formatDate(datee): string {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let date = new Date(datee);
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return day + " " + monthNames[monthIndex] + " " + year;
  }
  getConstructTime(datefromDb) {
    let dateTransaction = new Date(datefromDb);

    let isSafari = navigator.vendor.toLowerCase().indexOf("apple") > -1;
    if (isSafari) {
      dateTransaction.setHours(dateTransaction.getHours() - 1);
    }
    return dateTransaction
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        hour12: true,
        minute: "numeric",
      })
      .toLowerCase();
  }
}


// <div class="statement-detail">
// <p class="title">Closing Balance</p>
// <p class="value">$${this.dollarclosingBalance}</p>
// </div>
// <div class="statement-detail">
// <p class="title">Total Cash Inflow</p>
// <p class="value">$${this.dollarcashInflow}</p>
// </div>
// <div class="statement-detail">
// <p class="title">Total Cash Outflow</p>
// <p class="value">$${this.dollarcashOutflow}</p>
// </div>
