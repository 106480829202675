import { AnQrcodeModule } from "an-qrcode";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

@NgModule({
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RouterModule,
    NgxSkeletonLoaderModule,

  ],
  exports:[
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RouterModule,
    NgxSkeletonLoaderModule,],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonlyUsedModule {}
