
import { LoginResponse } from 'src/app/model/response/auth.response';
import { Settingsdetails } from 'src/app/model/response/settings.response';
import { SettinfgsActionTypes, SettingsdataActions } from './settingsdata.actions';


export interface SettingsState extends Settingsdetails {
  message?: string;
}



const initialState: SettingsState = {

  message: "",
  isLoading: true,
  businessName: null,
  email: null,
  address: null,
  city: null,
  rcNumber: null,
  bvn: null,
  businessDocument: null,
  logo: null,
  hasTransactionPin: false,
  lastPasswordReset: null,
  lastTransactionPinReset: null,
  developerKeys: {
    hasKeys: false,
    requestedKeys: false,
    developerAccountStatus: 0,
    sandboxPublicKey: null,
    sandboxSecretKey: null,
    publicKey: null,
    secretKey: null,
    webHook: null,
  },
  notifications:{},
  documentsUploaded: [],
  businessdocuments: {}

};

export function Settingsdatareducer(state = initialState, action: SettingsdataActions): SettingsState {

  switch (action.type) {
    case SettinfgsActionTypes.ResetSettingsdataState:
      return {
        ...initialState
      };
    case SettinfgsActionTypes.UPDATESETTINGSDATA:
      return {
        ...state,
        ...action.payload
      };
    case SettinfgsActionTypes.UPDATESOMESETTINGSDATA:
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
}

