
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { DashboardStatsResponse, DashboardResponse, BalanceResponse, RecentTransactionsResponse } from "../model/response/dashboard.response";
import { EndPoints } from "../config/endpoints";
import { catchError, map } from "rxjs/operators";
import { DashboardRequest, DashboardPeriod } from "../model/request/dashboard.request";
import { Store } from '@ngrx/store';
import { DashboardState } from '../store/dashboarddata/dashboarddata.reducer';
import { FetchCurrentUserBalance } from '../store/dashboarddata/dashboarddata.actions';

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient,
    private dashboardStore: Store<DashboardState>,) {
    let data: DashboardResponse = {
      recentTransactions: [
        {
          date: "01/11/2019 11:57:19",
          category: "Bank Transfer",
          reference: "W798300",
          narration: "Sent money to Seun ELEGBOJA.",
          amount: "100,000,000",
        },

        {
          date: "01/11/2019 11:57:19",
          category: "Airtime",
          reference: "W798300",
          narration: "Mtn for 08090789041.",
          amount: "10,000",
        },

        {
          date: "01/11/2019 11:57:19",
          category: "Airtime",
          reference: "W798300",
          narration: "Etisalat for 08090789041.",
          amount: "1000",
        },

        {
          date: "01/11/2019 11:57:19",
          category: "Bank Transfer",
          reference: "W798300",
          narration: "Sent money to Isiwat Adebukanla Adenopo's bank account.",
          amount: "768,900",
        },

        {
          date: "01/11/2019 11:57:19",
          category: "Topup",
          reference: "W798300",
          narration: "Topped up Wallets via card.",
          amount: "8,900",
        },
      ],
      walletBalance: 18976677778,
      stats: [
        {
          type: DashboardPeriod.today,
          data: {
            amountReceived: "1000000000",
            transactionCount: 12,
            amountSpent: "300000000"
          }
        },
        {
          type: DashboardPeriod.yesterday,
          data: {
            amountReceived: "7000000000",
            transactionCount: 8,
            amountSpent: "900000000"
          }
        },
        {
          type: DashboardPeriod.thisWeek,
          data: {
            amountReceived: "500000000",
            transactionCount: 30,
            amountSpent: "8000000"
          }
        }
      ]
    };
  }

  // on load, it loads all the data on the dashboard
  dashboardData(): Observable<DashboardResponse> {

    return this.http.get<DashboardResponse>(
      EndPoints.DASHBOARD.DASHBOARD_DATA,
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .pipe(map(data => {
        data.accounts.map(
          account => {
            if(account.bank.startsWith("Rubies") || account.bank.startsWith("Sterling")) {
              account.disabled = true;
            }
            else {
              account.disabled = false;
            }
          }
        )
        return data as any;
      }), catchError((error) => {
        return throwError(error);
      }));
  }

  recentTransactions(): Observable<RecentTransactionsResponse> {

    return this.http.get<RecentTransactionsResponse>(
      EndPoints.TRANSACTIONS.GET_ALL_TRANSACTIONS,
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .pipe(map(data => {
        return data as any;
      }), catchError((error) => {
        return throwError(error);
      }));
  }

  fetchingCurrentBalance(): Observable<BalanceResponse> {
    return this.http.get<DashboardResponse>(
      EndPoints.DASHBOARD.DASHBOARD_BALANCE,
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .pipe(map(data => {
        return data as any;
      }), catchError((error) => {
        return throwError(error);
      }));
  }

  async fetchTheNewBalance() {
    this.dashboardStore.dispatch(new FetchCurrentUserBalance());
  }


}
