import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { BitcoinTransactionsComponent } from './bitcoin-transactions.component';
import { CommonlyUsedModule } from 'src/app/shared/modules/commonlyused.module';
import { CryptoTransactionModalModule } from 'src/app/shared/components/modal/crypto-transaction-modal/crypto-transaction-modal.module';

const routes: Routes = [
  { path: '', component: BitcoinTransactionsComponent },
];

@NgModule({
  declarations: [BitcoinTransactionsComponent],
  imports: [
    CommonModule,
    CommonlyUsedModule,
    CryptoTransactionModalModule,
    RouterModule.forChild(routes)
  ]
})
export class BitcoinTransactionsModule { }
