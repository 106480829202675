import { ChangeTransactionCode } from './../../../../model/request/settings.request';
import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { DefaultModalTitle, ModalTitle } from "../../../model/modal.ui";
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SettingsProvider } from 'src/app/service/settings.service';
import { CheckTransactionPin } from 'src/app/model/request/auth.request';
import { AuthProvider } from 'src/app/service/auth.service';
import { LoginResponse } from 'src/app/model/response/auth.response';
import { select, Store } from '@ngrx/store';
import { DashboardState } from 'src/app/store/dashboarddata/dashboarddata.reducer';
import { SettingsState } from 'src/app/store/settingsdata/settingsdata.reducer';
import { UpdateLoggedinUserdata } from 'src/app/store/userdata/userdata.actions';
import { UserdataState } from 'src/app/store/userdata/userdata.reducer';
import { takeWhile } from 'rxjs/operators';
import { selectAllUserData } from 'src/app/store/userdata';
import * as dayjs from "dayjs";

@Component({
  selector: "app-pinchangemodal",
  templateUrl: "./pinchangemodal.component.html",
  styleUrls: ["./pinchangemodal.component.scss"]
})
export class PinchangemodalComponent implements OnInit {

  pinChangeForm: FormGroup;
  pinChangeFormSubmitAttempt: boolean;
  newChangeForm: FormGroup;
  newChangeFormSubmitAttempt: boolean;
  errorMessage = "";
  isError = false;
  @Input() isEdit = false;
  @Input() public modalContent: ModalTitle = DefaultModalTitle;
  loginData: LoginResponse;
  componentActive = true;
  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private loader: NgxSpinnerService,
    private analyticsService: AnalyticsService,
    private settingsService: SettingsProvider,
    private authService: AuthProvider,
    private store: Store<{
      userdata: UserdataState,
      settingsdata: SettingsState,
      dashboarddata: DashboardState
    }>) {
    this.pinChangeForm = this.formBuilder.group({
      oldPIN: ["", Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ])],

      newPIN: ["", Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ])],

      newPINagain: ["", Validators.compose([
        Validators.required,
        PinchangemodalComponent.matchValues("newPIN")
      ])],
    });

    this.newChangeForm = this.formBuilder.group({

      newPIN: ["", Validators.compose([
        Validators.required,
      ])],

      newPINagain: ["", Validators.compose([
        Validators.required,
        PinchangemodalComponent.matchValues("newPIN")
      ])],
    });
  }

  public static matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  ngOnInit() {
    console.log('log fdfdfÎ');
    this.store.pipe(select(selectAllUserData),
      takeWhile(() => this.componentActive))
      .subscribe(data => {
        this.loginData = data;
        let logindata = localStorage.getItem('token')

        if (!logindata) {

          this.activeModal.close()
        }

      });

  }

  pinChangeFormSubmit() {
    this.pinChangeFormSubmitAttempt = true;

    if (!this.pinChangeForm.valid) {
      return;
    }

    this.loader.show();
    this.analyticsService.trackEvent("Settings", `Successfully changed Pin `, null, 10);


    this.loader.hide();
    let passwordData: ChangeTransactionCode = {
      ConfirmNewPin: this.pinChangeForm.controls["newPINagain"].value,
      NewPin: this.pinChangeForm.controls["newPIN"].value,
      OldPin: this.pinChangeForm.controls["oldPIN"].value
    };
    this.loader.show();
    this.settingsService.changeTransactioncode(passwordData)
      .subscribe(() => {
        this.loader.hide();
        this.activeModal.close({
          model: "change"
        });
      }, (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
        // handle error
        this.loader.hide();
      });
  }

  newChangeFormSubmit() {
    this.newChangeFormSubmitAttempt = true;

    if (!this.newChangeForm.valid) {
      return;
    }

    this.analyticsService.trackEvent("Settings", `Successfully created Pin `, null, 10);


    let passwordData: CheckTransactionPin = {
      TransactionPin: this.newChangeForm.controls["newPINagain"].value
    };
    this.loader.show();
    this.authService.getTransactionToken(passwordData)
      .subscribe(() => {
        // this.loginData.data.hasTransactionPin = true;
        // this.loginData
        this.activeModal.close({
          model: "set"
        });
        this.loader.hide();
        this.store.dispatch(new UpdateLoggedinUserdata({ hasTransactionPin: true }));
      }, (error) => {
        this.loader.hide();
        this.isError = true;
        this.errorMessage = error.error.message;
        // handle error
      });
  }

  ngOnDestroy() {
    this.componentActive = false;
  }
}
