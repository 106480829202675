const live = true;
export const AppLive = live;
const beta = true;
export enum EnvState {
  live,
  beta,
  dev,
  local
}

export let EnvStatus: EnvState = EnvState.live;

export const BASE_URL = live
  ? "https://spring.wallet-server.com" : !beta ? "https://devapi.wallets.africa"
    : "https://apilivetest.wallets.africa";
;
const CRYPTO_BASE_URL = `${BASE_URL}/ether`;
/*
*https://apilivetest.wallets.africa/
*https://devapi.wallets.africa
*https://appapi.wallets.africa
*https://businessappapi.wallet.ke
*/

const BASE_VERSION = "api/v2";
export const BASE_ENDPOINT: string = `${BASE_URL}`;

const account = `${BASE_ENDPOINT}/Account`;
const personalsignupData = `${BASE_ENDPOINT}/Account/Signup/Personal`;
const personalsignup = `${BASE_ENDPOINT}/Account/signup/SMSOTP`;
const dashboard = `${BASE_ENDPOINT}/data`;
const report = `${BASE_ENDPOINT}/reports`;
const fund = `${BASE_ENDPOINT}/fund`;
const transfer = `${BASE_ENDPOINT}/send`;
const bill = `${BASE_ENDPOINT}/bill`;
const cards = `${BASE_ENDPOINT}/card`;
const request = `${BASE_ENDPOINT}/requestmoney`;
const settings = `${BASE_ENDPOINT}/Settings`;
const developer = `${BASE_ENDPOINT}/Developer`;
const subwallets = `${BASE_ENDPOINT}/wallet`;
const crypto = `${BASE_ENDPOINT}/Crypto`;
const intl = `${BASE_ENDPOINT}/api/v2/internationalpayment`;
const transfertobusiness = `${BASE_ENDPOINT}/api/v2/transfer`;
const ether = `${BASE_ENDPOINT}/ether`;

export const EndPoints = {
  TOKEN_ENDPOINT: BASE_URL + "/wallet/token",
  FETCH_BALANCE_ENDPOINT: `${fund}/balance`,

  // onboarding endpoints
  ACCOUNT: {
    LOGIN: `${account}/login`,
    REQUEST_ACCESS: `${account}/Signup/Request`,
    CHECK_TOKEN: `${account}/TransactionToken`,
    VERIFY_EMAIL: `${account}/SignUp/RequestDetails`,
    SIGNUP: `${account}/Signup`,
    REQUEST_RESET_PASSWORD: `${account}/Recover`,
    VERIFY_RESET_DETAILS: `${account}/ResetDetails`,
    VERIFY_DEVELOPER: `${account}/VerifyDeveloperAccess`,
    RESET_PASSWORD: `${account}/Reset`,
    REFRESH_TOKEN: `${account}/Token/Refresh`,
    SWITH2FA: `${account}/AuthPinStatus`,
  },

  UPDATE_PROFILE: `${account}/updateprofile`,

  // dashboard endpoints
  DASHBOARD: {
    DASHBOARD_DATA: `${dashboard}/dashboard/`,
    DASHBOARD_BALANCE: `${dashboard}/balance/`,
    GET_ALL_TRANSACTIONSToday: `${dashboard}/transaction/summary/daily`,
    GET_ALL_TRANSACTIONSYesterday: `${dashboard}/transaction/summary/yesterday`,
    GET_ALL_TRANSACTIONSThisMonth: `${dashboard}/transaction/summary/monthly`,
  },

  // transactions endpoints
  TRANSACTIONS: {
    GET_ALL_TRANSACTIONS: `${report}/transactions`,
    GET_TRANSACTIONS_DETAILS: `${report}/transactions/details`,
    GET_BANK_TRANSFER_STATUS: `${report}/bank/transferstatus`,
    REPORT_TRANSACTION: `${report}/reporttransaction`,
    SHARE_RECEIPT: `${report}/sharereceipt`,
  },

  // fund endpoints
  FUND_WALLET: {
    CARD: {
      FUND: `${fund}/fund`,
      VALIDATE_OTP: `${fund}/validate`,
      CHECK_BIN: `${fund}/checkbin`,
      COMPLETE_FUNDING_ENDPOINT: `${fund}/completevbv`,
      FUND_WITH_TOKEN: `${fund}/fundwithtoken`,
      CHECK_FUNDING_STATUS: `${fund}/checkfundstatus`,
      CHECK_VISA_FUNDING_STATUS: `${BASE_ENDPOINT}/wallet/fundresponse`,

      SAVED: {
        DELETE: `${fund}/deletecard`,
        ADD: `${fund}/addcard`,
        GET_USER_CARDS: `${fund}/tokenizedcards`,
        CHECK: `${fund}/cansavecard`,
      },
    },
  },

  // transfer endpoints
  TRANSFER: {
    AUTHORIZE_TRANSACTION: `${transfer}/generatetoken`,
    WALLET: {
      VALIDATE_PHONE_NUMBER: `${transfer}/wallet/validateaccount`,
      TRANSFER: `${transfer}/wallet/transfer`,
      GET_FREQUENT_BENEFICIARIES: `${transfer}/wallet/getbeneficiaries`,
    },
    TRANSFER_TO_BUSINESS: {
      VALIDATE_BUSINESS: `${transfertobusiness}/transfertobusiness/Confirmbusiness`,
      SEARCH_BUSIBESS: `${transfertobusiness}/transfertobusiness/business`,
      GET_FREQUENT_BENEFICIARIES: `${transfertobusiness}/transfertobusiness/businessbeneficiaries`,
      TRANSFER_TO_BUSINESS: `${transfertobusiness}/transfertobusiness/paybusiness`,
    },
    BANK: {
      TRANSFER_CHARGES: `${transfer}/getcharges`,
      TRANSFER_CHARGE: `${transfer}/disbursementCharge`,
      GET_BANKS: `${transfer}/bank/getbanks`,
      ACCOUNT_ENQUIRE: `${transfer}/bank/validateaccount`,
      TRANSFER: `${transfer}/bank/transfer`,
      GET_FREQUENT_BENEFICIARIES: `${transfer}/bank/getbeneficiaries`,
    },
    BULKBANK: {
      GET_BENEFICIARIES: `${transfer}/bulkbank/getbulkbeneficiaries`,
      CREATE_BULK_LIST: `${transfer}/bulkbank/add`,
      CREATE_BULK_LIST_VIA_FILE: `${transfer}/bulkbank/add/file`,
      DELETE_BULK_LIST: `${transfer}/bulkbank/deletebulkrecipient`,
      BULK_TRANSFER: `${transfer}/bulkbank/bulktransfer`,
      ADD_RECIPIENT: `${transfer}/bulkbank/list/item/add`,
      EDIT_RECIPIENT: `${transfer}/bulkbank/list/item/update`,
      DELETE_RECIPIENT: `${transfer}/bulkbank/list/item/delete`,
    },
    BULKWALLET: {
      GET_BENEFICIARIES: `${transfer}/bulkwallet/getbulkbeneficiaries`,
      CREATE_BULK_LIST: `${transfer}/bulkwallet/add`,
      CREATE_BULK_LIST_VIA_FILE: `${transfer}/bulkwallet/add/file`,
      DELETE_BULK_LIST: `${transfer}/bulkwallet/deletebulkrecipient`,
      BULK_TRANSFER: `${transfer}/bulkwallet/bulktransfer`,
      ADD_RECIPIENT: `${transfer}/bulkwallet/list/item/add`,
      EDIT_RECIPIENT: `${transfer}/bulkwallet/list/item/update`,
      DELETE_RECIPIENT: `${transfer}/bulkwallet/list/item/delete`,
    },
    INTERNATIONAL: {
      TRANSFER: `${transfer}/international/transfer`,
      GET_FREQUENT_BENEFICIARIES: `${transfer}/international/getbeneficiaries`,
      CONVERTCURRENCY: `${transfer}/international/convertinternationaltolocal`,
    },
    BULKINTERNATIONAL: {
      GET_BENEFICIARIES: `${transfer}/bulkinternational/getbulkbeneficiaries`,
      CREATE_BULK_LIST: `${transfer}/bulkinternational/add`,
      CREATE_BULK_LIST_VIA_FILE: `${transfer}/bulkinternational/add/file`,
      DELETE_BULK_LIST: `${transfer}/bulkinternational/deletebulkrecipient`,
      BULK_TRANSFER: `${transfer}/bulkinternational/bulktransfer`,
      ADD_RECIPIENT: `${transfer}/bulkinternational/list/item/add`,
      EDIT_RECIPIENT: `${transfer}/bulkinternational/list/item/update`,
      DELETE_RECIPIENT: `${transfer}/bulkinternational/list/item/delete`,
    },
  },

  INTERNATIONAL_TRANSFER: {
    BANK: {
      GET_BANKS: `${intl}/getbank?countryCode=`,
      GET_BRANCHES: `${intl}/getbranches?bankid=`,
    },
    RATES: `${intl}/rates`,
    TRANSFER: {
      SINGLE: `${intl}/bank`,
    },
  },

  // airtime endpoints
  BILLS: {
    AIRTIME: {
      GET_NETWORKS: `${bill}/airtime/getairtimenetworks`,
      PAY: `${bill}/airtime/pay`,
      GET_BENEFICIARIES: `${bill}/airtime/getbeneficiaries`,
    },
    BULK_AIRTIME: {
      CREATELIST: `${bill}/bulkairtime/add`,
      PAY: `${bill}/bulkairtime/bulktransfer`,
      DELETE_RECIPIENT: `${bill}/bulkairtime/list/item/delete`,
      DELETE_LIST: `${bill}/bulkairtime/deletebulkrecipient`,
      ADD_RECIPIENT: `${bill}/bulkairtime/list/item/add`,
      EDIT_RECIPIENT_LIST: `${bill}/bulkairtime/list/item/update`,
      GET_ALL_LISTS: `${bill}/bulkairtime/getbulkbeneficiaries`,
    },
  },

  // cards endpoints
  CARDS: {
    DOLLAR_CARD: {
      CREATE_NEW_CARD: `${cards}/dollar/add`,
      FUND: `${cards}/dollar/fund`,
      FREEZE: `${cards}/dollar/freeze`,
      UNFREEZE: `${cards}/dollar/unfreeze`,
      GETCARDS: `${cards}/dollar/getcards`,
      WITHDRAW: `${cards}/dollar/withdraw`,
      DATA: `${cards}/dollar/data`,
      TRANSACTIONS: `${cards}/dollar/transactions`,
      DEACTIVATE: `${cards}/dollar/deactivate`,
    },
    NAIRA_CARD: {
      GET_CARDS_REQUESTS: `${cards}/naira/getrequests`,
      GET_CARD_DATA: `${cards}/naira/transactions`,
      FUND: `${cards}/naira/fund`,
      WITHDRAW: `${cards}/naira/fund`,
      GETCARDS: `${cards}/naira/getcards`,
      GETBALANCE: `${cards}/naira/getbalance`,
      WITHDRAW2: `${cards}/naira/withdrawal`,
      REQUEST_NEW_CARD: `${cards}/naira/request/new`,
      UNFREEZE: `${cards}/naira/unblock`,
      FREEZE: `${cards}/naira/block`,
      NAIRACARDDATA: `${cards}/naira/withdrawaldata`,
      STATES: `${cards}/naira/statesandlgas`,
    },
    CARD_FUNNDING: {
      GET_CARDS: `${cards}/bank/getsavedcards`,
      FUNDSAVEDCARDS: `${cards}/bank/fundwalletwithtoken`,
      SUBMITCARDFORFUNDING: `${cards}/bank/fundwallet`,
      VALIDATECARD: `${cards}/bank/validatefund`,
    },
  },
  // request payment endpoints
  REQUEST: {
    CHECK_LINK: `${request}/checkurl`,
    DELETE: `${request}/delete`,
    CREATE_LINK: `${request}/add`,
    PAYMENT_LINKS: `${request}/data`,
    FETCH_TRANSACTIONS: `${request}/link/data`,
  },
  DEVELOPER: {
    UPDATE_WEBHOOK: `${developer}/Webhook`,
  },
  CRYPTO: {
    GENERATEADDRESS: `${crypto}/GenerateAddress`,
    GETFEE: `${crypto}/getfee`,
    GETSTATUS: `${crypto}/status`,
  },
  SETTINGS: {
    CLAIM_OVERDRAFT: `${settings}/ClaimOverdraft`,
    GET_DEVELOPER_KEYS: `${settings}/GetDeveloperKeys`,
    UPDATE_PROFILE: `${settings}/UpdateProfile`,
    UPLOAD_DOCUMENT: `${settings}/UploadDocument`,
    UPLOAD_LOGO: `${settings}/UploadLogo`,
    BUSINESS_DATA: `${settings}/BusinessData`,
    CHANGE_PASSWORD: `${settings}/ChangePassword`,
    CHANGE_TRANSACTION_CODE: `${settings}/ChangeTransactionCode`,
    UPDATE_WEBHOOK: `${settings}/developer/Webhook`,
    UPDATE_KYC: `${settings}/UploadUserDocument`,
    NOTIFICATIONS_SETTINGS: `${settings}/notifications`,
    MERCHANT_TYPE: `${settings}/businesstype/update`,
    TOGGLE_BUSINESSTRANSFER_SETTINGS: `${settings}/BusinessDirectoryListing`,
    ADDPND: `${settings}/pnd/add`,
    REMOVEPND: `${settings}/pnd/remove`,
  },
  SUBWALLETS: {
    DEBIT: `${subwallets}/debit`,
    CREDIT: `${subwallets}/credit`,
    CREATE: `${subwallets}/create`,
    VERIFY: `${subwallets}/verify`,
    GENERATE: `${subwallets}/generate`,
    GENERATEACCOUNT: `${subwallets}/generateaccountnumber`,
    RETRIVEACCOUNT: `${subwallets}/nuban`,
    SETPASSWORD: `${subwallets}/password`,
    SETPIN: `${subwallets}/setpin`,
    TRANSACTIONS: `${subwallets}/subwallet/transactions`,
    VERIFYBVN: `${subwallets}/verifybvn`,
    GET_USER: `${subwallets}/getuser`,
    GET_BALANCE: `${subwallets}/balance`,
    GET_ALL_USERS: `${subwallets}/users`,
    EDIT_USER: `${subwallets}/edit`,
    DEACTIVATE_USER: `${subwallets}/deactivate`,
    ACTIVATE_USER: `${subwallets}/activate`,
    RESET_PASSWORD: `${subwallets}/reset`,
  },
  PERSONALWALLETAUTH: {
    sendotp: `${personalsignup}/Get`,
    resendotp: `${personalsignup}/Resend`,
    validateotp: `${personalsignup}/Validate`,
    personalsignupdata: `${personalsignupData}`,
  },
  NEW_CRYPTO: {
    CREATE_CRYPTO_ADDRESS: `${ether}/activate`,
    GET_USER_CRYPTO_WALLETS: `${CRYPTO_BASE_URL}/getUserCryptoWallets`,
    GET_USER_CRYPTO_WALLET_BY_CURRENCY: `${CRYPTO_BASE_URL}/getUserCryptoWalletByCurrency`,
    FUND_CRYPTO_WALLET: `${CRYPTO_BASE_URL}/fundCryptoWallet`,
    WITHDRAW_IN_FIAT: `${CRYPTO_BASE_URL}/withdrawInFiat`,
    TRANSFER_TO_CRYPTO_ADDRESS: `${ether}/transfer`,
    GET_USER_CRYPTO_TRANSACTIONS: `${ether}/transactions`,
    USER_HAS_CRYPTO_WALLET: `${CRYPTO_BASE_URL}/hasCryptoWallet`,
    FETCH_BENEFICIARIES: `${CRYPTO_BASE_URL}/getBeneficiaries`,
    DASHBOARD_DATA: `${ether}/dashboard/xxxx`,
  }
};
