import { Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import {
  NairaCardDetail,
  DollarCardDetail,
  DollarCards,
  Cardrequests,
} from "../model/response/card.response";
import { CardService } from "../service/cards.service";

interface GetNairaCards {
  isLoading?: boolean;
  nairacards?: NairaCardDetail[];
}

interface GetCardRequests {
  isLoading?: boolean;
  cardRequests?: Cardrequests[];
}

@Injectable()
export class CardsdataProvider {
  nairacard: NairaCardDetail = {
    last4: "",
    nameOnCard: "",
    walletCardId: null,
    dateCreated: null,
    expiryDate: null,
    cardNumber: null,
    expiryMonth: null,
    expiryYear: null,
    state: null,
    isActive: false,
    balance: null,
  };

  dollarcard: DollarCardDetail = {
    last4: null,
    address: null,
    cvv: null,
    expiryMonth: null,
    expiryYear: null,
    city: null,
    state: null,
    nameOnCard: null,
    cardNumber: null,
    isActive: null,
    zipCode: null,
    balance: null,
    isFrozen: null,
    status: null,
    barterCardId: null,
    isLoaded: null,
  };
  isFirstLoad: boolean = false;
  isDollarFirstLoad: boolean = false;
  isNairaFirstLoad: boolean = false;
  isRequestFirstLoad: boolean = false;

  dollarCardList: DollarCardDetail[] = [];
  nairaCardList: NairaCardDetail[] = [];
  fetchNairaCardsList: GetNairaCards = {
    isLoading: true,
    nairacards: [],
  };
  allDollarCards: DollarCards = {
    cards: this.dollarCardList,
    buyFees: 0,
    costFees: 0,
    fundingLimit: 0,
    customerTransactions: [],
    minimumDollarFundFee: 0,
    spendFees: 0,
    isLoading: true,
    isFirstLoad: false,
  };
  moneyRequest: Cardrequests[] = [];

  allMoneyRequest: GetCardRequests = {
    isLoading: true,
    cardRequests: [],
  };
  private nairacardData$ = new BehaviorSubject<GetNairaCards>(
    this.fetchNairaCardsList
  );
  private dollarcardData$ = new BehaviorSubject<DollarCards>(
    this.allDollarCards
  );
  private moneyRequestData$ = new BehaviorSubject<GetCardRequests>(
    this.allMoneyRequest
  );
  constructor(private cardService: CardService) {}
  resetdata() {
this.isDollarFirstLoad=false;
this.isNairaFirstLoad=false;
this.isRequestFirstLoad=false;
    this.updateCardRequests({
      isLoading: true,
      cardRequests: [],
    });
    this.updateDollarDetails({
      cards: [],
      buyFees: 0,
      costFees: 0,
      fundingLimit: 0,
      customerTransactions: [],
      minimumDollarFundFee: 0,
      spendFees: 0,
      isLoading: true,
      isFirstLoad: false,
    });
    this.updateNairaDetails({
      isLoading: true,
      nairacards: [],
    });
  }
  updateDollarDetails(details: DollarCards) {
    this.dollarcardData$.next(details);
  }

  updateNairaDetails(details: GetNairaCards) {
    this.nairacardData$.next(details);
  }

  updateCardRequests(details: GetCardRequests) {
    this.moneyRequestData$.next(details);
  }

  getDollarCards(): Observable<DollarCards> {
    return this.dollarcardData$.asObservable();
  }

  getNairaCards(): Observable<GetNairaCards> {
    return this.nairacardData$.asObservable();
  }

  getCardsRequest(): Observable<GetCardRequests> {
    return this.moneyRequestData$.asObservable();
  }

  fetchData() {
    this.fetchNairaCardRequests();
    this.fetchDollarCards();
    this.fetchNairacardsList();
  }

  fetchNairacardsList() {
    this.cardService.fetchNairaCards().subscribe(
      (data) => {
        const fetchData: GetNairaCards = {
          isLoading: false,
          nairacards: data,
        };
        if (!this.isNairaFirstLoad) {
          this.updateNairaDetails(fetchData);
        }
      },
      (error) => {},
      () => {
        this.isNairaFirstLoad = true;
      }
    );
  }

  fetchDollarCards() {
    let Cards: DollarCards = {};
    this.cardService.getDollarCards().subscribe(

      (data: DollarCards) => {
        if (!this.isDollarFirstLoad) {
          // this.isDollarFirstLoad=true
          const dollarCardFetched: DollarCards = data;
          dollarCardFetched.isLoading = false;
          dollarCardFetched.isFirstLoad = true;
          this.allDollarCards = data;
          this.allDollarCards.isFirstLoad = true;
          this.updateDollarDetails(dollarCardFetched);
        }
      },
      (error) => {},
      () => {
        this.fetchDollarCardsDetails();
        this.isDollarFirstLoad = true;
      }
    );
  }

  fetchDollarCardsDetails() {
    this.allDollarCards.cards.map((cardDetail, index) => {
      this.getCardDetails(cardDetail.barterCardId, index);
    });
  }

  getCardDetails(barterCardId, index) {
    this.cardService.getDollarData(barterCardId).subscribe(
      (data) => {
        data.isLoaded = true;
        this.allDollarCards.cards[index] = data;
      },
      (error) => {
        // this.getCardDetails(barterCardId, index);
      }
    );
  }

  fetchNairaCardRequests() {
    this.cardService.fetchNairaCardRequests().subscribe(
      (data) => {
        const requests: GetCardRequests = {
          cardRequests: data,
          isLoading: false,
        };
        this.updateCardRequests(requests);
      },
      (error) => {}
    );
  }
}
