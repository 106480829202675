import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCase',
    pure: false
})
export class TitleCasePipe implements PipeTransform {

    transform(value: string): string {
        return value.length === 0
            ? ''
            : value
                .replace('_', ' ')
                .replace('_', ' ')
                .replace(/\w\S*/g, (txt) => txt[0].toUpperCase() + txt.substr(1).toLowerCase())
                .replace('And', '&');
    }

}
