import { Action } from "@ngrx/store";
import { LoginRequest } from "src/app/model/request/auth.request";

export enum UserActionTypes {
  ResetUserState = "[USERDATA] Reset Data",
  LogOutUser = "[USERDATA] Log Out User",
  LogOutClearingUser = "[USERDATA] Log  Out Clearing User",
  UpdateTOS = "[USERDATA] Update Terms Of Service",
  LoginUser = "[USERDATA] Login User",
  LoginUserSuccess = "[USERDATA] Login User Success",
  LoginUserFailure = "[USERDATA] Login User Failure",
  UpdateLoggedinUserdata = "[USERDATA]Update Logged in User Data",
  GetUserDataa = "[USERDATA] GetUserData User",
  UpdateToken = "[USERDATA]Update token",
  Donothing = "[USERDATA] Just return nothing",
}

// Action Creators
export class ResetUserState implements Action {
  readonly type = UserActionTypes.ResetUserState;
}
export class Donothing implements Action {
  readonly type = UserActionTypes.Donothing;
}
export class LogOutClearingUser implements Action {
  readonly type = UserActionTypes.LogOutClearingUser;
}
export class LogOutUser implements Action {
  readonly type = UserActionTypes.LogOutUser;
}
export class LoginUser implements Action {
  readonly type = UserActionTypes.LoginUser;

  constructor(public payload: LoginRequest) {
    this.type = UserActionTypes.LoginUser;
  }
}
export class UpdateTOS implements Action {
  readonly type = UserActionTypes.UpdateTOS;

  constructor(public payload: {UserId: number, token: string}) {
    this.type = UserActionTypes.UpdateTOS;
  }
}

export class LoginUserSuccess implements Action {
  readonly type = UserActionTypes.LoginUserSuccess;

  constructor(public payload: any) {
    this.type = UserActionTypes.LoginUserSuccess;
  }
}

export class LoginUserFailure implements Action {
  readonly type = UserActionTypes.LoginUserFailure;

  constructor(public payload: any) {
    this.type = UserActionTypes.LoginUserFailure;
  }
}

export class UpdateLoggedinUserdata implements Action {
  readonly type = UserActionTypes.UpdateLoggedinUserdata;

  constructor(public payload: any) {
    this.type = UserActionTypes.UpdateLoggedinUserdata;
  }
}






export type UserdataActions = LoginUser
  | LoginUserSuccess
  | LoginUserFailure
  | ResetUserState
  | LogOutUser
  | LogOutClearingUser
  | UpdateTOS
  | UpdateLoggedinUserdata;
