import { AppLive } from './../config/endpoints';
import { localStorageSync } from "ngrx-store-localstorage";
import { EffectsModule } from "@ngrx/effects";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { Userdatareducer } from "./userdata/userdata.reducer";
import { UserdataEffects } from "./userdata/userdata.effects";
import { Errordatareducer } from "./errormessages/errormessages.reducer";
import { Dashboarddatareducer } from "./dashboarddata/dashboarddata.reducer";
import { DashboarddataEffects } from "./dashboarddata/dashboarddata.effects";

import { AppState } from "./app.state";
import { SubwalletdataEffects } from "./subwallets/subwallets.effects";
import { Subwalletdatareducer } from "./subwallets/subwallets.reducer";
import { Bulktransferdatareducer } from "./bulktransfers/bulktransfers.reducer";
import { Requestmoneydatareducer } from "./requestmoneylink/requestmoneylink.reducer";
import { Transactiondatareducer, TransactiondatareducerThisMonth, TransactiondatareducerToday, TransactiondatareducerYesterday } from './transactions/transactions.reducer';
import { TransactionsdataEffects } from './transactions/transactions.effects';
import { Settingsdatareducer } from './settingsdata/settingsdata.reducer';
import { SettingsdataEffects } from './settingsdata/settingsdata.effects';
import { Carddatareducer } from './carddata/carddata.reducer';
import { CarddataEffects } from './carddata/carddata.effects';
import { CryptoModule } from '../state/crypto/crypto.module';


const reducers: ActionReducerMap<AppState> = {
  userdata: Userdatareducer,
  errordata: Errordatareducer,
  dashboarddata: Dashboarddatareducer,
  subwalletdata: Subwalletdatareducer,
  bulktransfer: Bulktransferdatareducer,
  requestmoneydata: Requestmoneydatareducer,
  transactiondatas: Transactiondatareducer,
  transactiondatastoday: TransactiondatareducerToday,
  transactiondatasyesterday: TransactiondatareducerYesterday,
  transactiondatasthismonth: TransactiondatareducerThisMonth,
  settingsdata: Settingsdatareducer,
  carddata: Carddatareducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      "userdata",
      "errordata",
      "dashboarddata",
      "recenttransactions",
      "subwalletdata",
      "bulktransfer",
      "requestmoneydata",
      "transactiondatas",
      "settingsdata",
      "carddata",
      'crypto',
      "transactiondatastoday",
      "transactiondatasyesterday",
      "transactiondatasthismonth"
    ], rehydrate: true,
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
const effects = [
  UserdataEffects,
  DashboarddataEffects,
  SubwalletdataEffects,
  TransactionsdataEffects,
  SettingsdataEffects,
  CarddataEffects
];

let importModules = AppLive ? [EffectsModule.forRoot(effects),
StoreModule.forRoot(reducers, { metaReducers }),] : [EffectsModule.forRoot(effects),
StoreModule.forRoot(reducers, { metaReducers }),
StoreDevtoolsModule.instrument({ maxAge: 1000, logOnly: AppLive }),]

@NgModule({
  imports: [...importModules, CryptoModule],
  exports: [

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StateModule { }
