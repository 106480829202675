import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BitcoinComponent } from './bitcoin.component';
import { Routes, RouterModule } from '@angular/router';
import { CreateCryptoWalletModalModule } from 'src/app/shared/components/modal/create-crypto-wallet-modal/create-crypto-wallet-modal.module';
import { FundCryptoWalletModalModule } from 'src/app/shared/components/modal/fund-crypto-wallet/fund-crypto-wallet.module';
import { CommonlyUsedModule } from 'src/app/shared/modules/commonlyused.module';
import { ReactiveFormsModule } from '@angular/forms';

const cryptoRoutes: Routes = [
  { path: '', component: BitcoinComponent },
];

@NgModule({
  declarations: [
    BitcoinComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(cryptoRoutes),
    CreateCryptoWalletModalModule,
    ReactiveFormsModule,
    FundCryptoWalletModalModule,
    CommonlyUsedModule
  ],
  exports: [RouterModule]
})
export class BitcoinModule { }
