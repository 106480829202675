
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { directives } from "./directives";
import { services } from "./services";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { PipesModule } from "./pipes/pipes.module";

@NgModule({
  declarations: [directives],
  exports: [directives, PipesModule, ],
  imports: [
    PipesModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxSpinnerModule,
    CreditCardDirectivesModule,
  ],
  providers: [NgxSpinnerModule, NgbActiveModal, services],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {

}
