import { NavigationEnd, Router } from "@angular/router";
import { Location, JsonPipe } from "@angular/common";
import { filter } from "rxjs/operators";
import { Observable, BehaviorSubject, Subject } from "rxjs";

import { Injectable } from "@angular/core";
import { LoginResponse } from "../model/response/auth.response";
import { Title } from "@angular/platform-browser";
@Injectable()
export class AuthdataProvider {
  defaultData = {
    token: "",
    message: "",
    data: {
      isAccountCompleted: null,
      businessName: "",
      businessLogo: "",
      email: "",
      phoneNumber: "",
      businessAddress: "",
      rcNumber: "",
      hasBVN: null,
      documentUploaded: "",
      hasTransactionPin: null,
      createdAt: "",
    },
  };

  private loginData$ = new BehaviorSubject<LoginResponse>(this.defaultData);
  private loadermessage$ = new BehaviorSubject<string>("");
  constructor(private titleservice: Title) {
    this.loginData$.next(JSON.parse(localStorage.getItem("userdata")));
  }
  setTitle(title) {
    this.titleservice.setTitle(title);
  }


  updateLoaderMessage(details: string) {
    this.loadermessage$.next(details);
  }


  getLoadermessage(): Observable<string> {
    return this.loadermessage$.asObservable();
  }
}
