
import { LoginResponse } from 'src/app/model/response/auth.response';
import { AllTransactionsResponse } from 'src/app/model/response/transactions.response';
import { TransactionsActionTypes, TransactionsdataActions, TransactionsdataActionsThisMonth, TransactionsdataActionsToday, TransactionsdataActionsYesterday, UPDATEUSERSTRANSACTIONSThisMonth, UPDATEUSERSTRANSACTIONSToday, UPDATEUSERSTRANSACTIONSYesterday } from './transactions.actions';
import { DashboardStatsResponse } from 'src/app/model/response/dashboard.response';


export interface TransactionState extends AllTransactionsResponse {
  message?: string;
}

export interface TransactionStateToday extends DashboardStatsResponse {
}
export interface TransactionStateYesterday extends DashboardStatsResponse {
}
export interface TransactionStateThisMonth extends DashboardStatsResponse {
}



const initialState: TransactionState = {
  message: "",
  isLoading: true,
  isFilter: false,
  isFirstLoad: false,
  allTransactions: [],
  transactionsReport: {
    totalPages: 0,
  },
};
const initialStateToday: DashboardStatsResponse = {
  amountReceived: "0",
  amountSpent: "0",
  transactionCount: 0,
  isLoading : true
};
const initialStateYesterday: DashboardStatsResponse = {
  amountReceived: "0",
  amountSpent: "0",
  transactionCount: 0,
  isLoading : true
};
const initialStateThisMonth: DashboardStatsResponse = {
  amountReceived: "0",
  amountSpent: "0",
  transactionCount: 0,
  isLoading : true
};

export function Transactiondatareducer(state = initialState, 
  action: TransactionsdataActions): TransactionState {

  switch (action.type) {

    case TransactionsActionTypes.ResetTranasactionsDataState:

      return {
        ...initialState

      };
    case TransactionsActionTypes.TurnOnLoaderFortransactions:

      return {
        ...state,
       isLoading:true

      };
    case TransactionsActionTypes.UPDATEUSERSTRANSACTIONS:

      return {
        ...state,
        ...action.payload

      };
    // case TransactionsActionTypes.UPDATEUSERSTRANSACTIONSToday:

    //   return {
    //     ...stateToday,
    //     ...action.payload

    //   };
    // case TransactionsActionTypes.UPDATEUSERSTRANSACTIONSYesterday:

    //   return {
    //     ...stateYesterday,
    //     ...action.payload

    //   };
    // case TransactionsActionTypes.UPDATEUSERSTRANSACTIONSThisMonth:

    //   return {
    //     ...stateThisMonth,
    //     ...action.payload

    //   };
    default:
      return {
        ...state
      };
  }
}


export function TransactiondatareducerToday(state = initialStateToday, 
  action: TransactionsdataActionsToday): TransactionStateToday {
  return {
    ...state,
    ...action.payload
  };
}
export function TransactiondatareducerYesterday(state = initialStateYesterday, 
  action: TransactionsdataActionsYesterday): TransactionStateYesterday {
  return {
    ...state,
    ...action.payload
  };
}
export function TransactiondatareducerThisMonth(state = initialStateToday, 
  action: TransactionsdataActionsThisMonth): TransactionStateThisMonth {
  return {
    ...state,
    ...action.payload
  };
}