import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, ReplaySubject, of, throwError } from "rxjs";

import { ApiService } from "./api.service";
import { JwtService } from "./jwt.service";
import { map, distinctUntilChanged, catchError } from "rxjs/operators";
import { User } from "../models/app-models/user.model";
import {
  LoginRequest,
  RequestAccess,
  SetPasswordRequest,
  UpdateProfileRequest,
  VerifyRequest
} from "../../model/request/auth.request";
import { EndPoints } from "../../config/endpoints";
import { LoginResponse, SetPasswordResponse } from "../../model/response/auth.response";
import { RequestAccessResponse } from "../../model/response/auth.response";
import { UpdatePasswordResponse } from "../../model/response/auth.response";
import { VerifyResponse } from "../../model/response/auth.response";



@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService
  ) { }

  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.apiService.get("/user")
        .subscribe(
          data => this.setAuth(data.user),
          err => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(user.token);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  login(loginrequest: LoginRequest): Observable<LoginResponse> {

    return this.http.post<LoginResponse>(
      EndPoints.ACCOUNT.LOGIN,
      loginrequest,
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return throwError(error);
      }));
  }

  requestAccess(requestaccessrequest: RequestAccess): Observable<RequestAccessResponse> {
    return this.http.post<RequestAccessResponse>(
      EndPoints.ACCOUNT.REQUEST_ACCESS,
      requestaccessrequest,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }

  setPassword(setpassword: SetPasswordRequest): Observable<SetPasswordResponse> {
    return this.http.post<SetPasswordResponse>(
      EndPoints.ACCOUNT.SIGNUP,
      setpassword,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }

  updateProfile(updateprofilerequest: UpdateProfileRequest): Observable<UpdatePasswordResponse> {
    return this.http.post<UpdatePasswordResponse>(
      EndPoints.UPDATE_PROFILE,
      updateprofilerequest,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }

  verify(verifyrequest: VerifyRequest): Observable<VerifyResponse> {
    return this.http.post<VerifyResponse>(
      EndPoints.ACCOUNT.VERIFY_EMAIL,
      verifyrequest,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .pipe(map(data => {
        return data;
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  getAvatarImage(): string {
    //todo : user this service to get image for all nav bars
    return "";
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<User> {
    return this.apiService
      .put("/user", { user })
      .pipe(map(data => {
        // Update the currentUser observable
        this.currentUserSubject.next(data.user);
        return data.user;
      }));
  }
}
