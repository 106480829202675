
import { LoginResponse } from 'src/app/model/response/auth.response';
import { ErrorActionTypes, ErrordataActions } from './errormessages.actions';


export interface errorContent {
  message?: string;
  content?: any;
}
export interface ErrordataState {
  loginerror?: errorContent;
}



const initialState: ErrordataState = {

  loginerror: {},

};

export function Errordatareducer(state = initialState, action: ErrordataActions): ErrordataState {

  switch (action.type) {
    case ErrorActionTypes.ResetErrorState:

      return {
        ...initialState
      };
    case ErrorActionTypes.GeneralUpdateError:

      return {
        ...state,
        ...action.payload

      };
    default:
      return {
        ...state,
      };
  }
}

