import { UserActionTypes, UserdataActions } from './userdata.actions'
import { LoginResponse } from 'src/app/model/response/auth.response';


export interface UserdataState extends LoginResponse {
  loading?: boolean;
  error?: string;
  access_token?: string;
  refreshtoken_expires_in?: string;
  scope?: string;
  expires_in?: number;
  token_type?: string;
}



const initialState: UserdataState = {
  token: null,
  message: "",
  data: {
    isAccountCompleted: null,
    businessName: "",
    businessLogo: "",
    email: "",
    phoneNumber: "",
    businessAddress: "",
    rcNumber: "",
    hasBVN: null,
    documentUploaded: "",
    hasTransactionPin: null,
    createdAt: "",
  },
};

export function Userdatareducer(state = initialState, action: UserdataActions): UserdataState {

  switch (action.type) {
    case UserActionTypes.ResetUserState:



      return {
        ...initialState

      };
      break;

    case UserActionTypes.LoginUserSuccess:
      let data: LoginResponse = action.payload;
      return {
        ...state,
        data: data.data,
        message: data.message,
        refreshToken:data.refreshToken,
        refreshtoken_expires_in:data.expiresin

      };
      break;
    case UserActionTypes.UpdateLoggedinUserdata:



      return {
        ...state,
        data: { ...state.data, ...action.payload }

      };
      break;
    default:
      return {
        ...state
      };
  }
}

