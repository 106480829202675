import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './crypto.reducer';
import { CryptoEffects } from './crypto.effects';
import { BitcoinComponent } from '../../pages/bitcoin/bitcoin.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BitcoinModule } from 'src/app/pages/bitcoin/bitcoin.module';
import { BitcoinTransactionsModule } from 'src/app/pages/bitcoin-transactions/bitcoin-transactions.module';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    BitcoinModule,
    BitcoinTransactionsModule,
    StoreModule.forFeature('crypto', reducer),
    EffectsModule.forFeature([ CryptoEffects ])
  ]
})
export class CryptoModule { }
