import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalTitle, DefaultModalTitle } from '../../../model/modal.ui';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";

import * as cryptoReducer from '../../../../state/crypto/crypto.reducer';
import * as cryptoActions from '../../../../state/crypto/crypto.actions';
import * as fromCrypto from '../../../../state/crypto/index';
import { Router } from '@angular/router';
import { CheckTransactionPin, CryptoTransactionType } from 'src/app/model/request/auth.request';
import { AuthProvider } from 'src/app/service/auth.service';
import { FundCryptoWalletRequest, SendCryptoRequest, SendETHRequest } from 'src/app/model/request/fund_crypto_wallet.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { WalletrecentrecipientComponent } from '../walletrecentrecipient/walletrecentrecipient.component';
import { WebStorageService } from 'src/app/service/web-storage.service';
import { LOCAL_STORAGE_ITEMS } from 'src/app/config/constants';
import { ThrowStmt } from '@angular/compiler';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { DashboardState } from 'src/app/store/dashboarddata/dashboarddata.reducer';
import { UserdataState } from 'src/app/store/userdata/userdata.reducer';
import { getLoggedinUserdata } from "src/app/store/userdata";
import { selectAllUserData } from 'src/app/store/userdata';
// import { SignalRService } from 'src/app/service/signal-r.service';
import { LoginResponse } from "../../../../model/response/auth.response";
const helper = new JwtHelperService();

@Component({
  selector: 'app-fund-crypto-wallet',
  templateUrl: './fund-crypto-wallet.component.html',
  styleUrls: ['./fund-crypto-wallet.component.scss']
})
export class FundCryptoWalletModalComponent implements OnInit, OnDestroy {

  fundCryptoWalletForm: FormGroup;
  pinForm: FormGroup;
  modalContent: ModalTitle = DefaultModalTitle;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
  successSubtitle: string;
  successTitle: string;
  actionButtonText: string;
  componentActive = true;
  isFunding: boolean;
  isWithdrawal: boolean;
  isTransfer: boolean;
  userPhoneNumber: string;
  addressId: number;
  address: string;
  minCryptoValue: number;
  BTCExchangeRate: number;
  token: string;
  transactionAmount: number;
  formInvalid: boolean;
  showFormError: boolean = false;
  isPinPage: boolean;
  walletId: number;
  recentRecipientsModal: NgbModalRef;
  walletBalance: number;
  cryptoWalletBalance = '';
  isCryptoAmount: boolean = true;
  amountInNaira: number;
  transactionFee: number;
  amountCurrencyText = ' Naira';
  cryptoTransactionFee = '';
  balance = 0;
  is2FAOn = false;
  LoginData: LoginResponse;
  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<cryptoReducer.CryptoState>,
    private router: Router,
    private authProvider: AuthProvider,
    private loader: NgxSpinnerService,
    private modalService: NgbModal,
    private webStorage: WebStorageService,
    private userStore: Store<UserdataState>,
    // public signalRService: SignalRService
    private store2: Store<{
      userdata: UserdataState,
      dashboarddata: DashboardState
    }>
  ) {

  }

  ngOnInit() {
    this.initializeForm();
    this.walletBalance = JSON.parse(this.webStorage.getItemFromLocalStorage(LOCAL_STORAGE_ITEMS.DASHBOARD_DATA))['walletBalance'];
    // this.signalRService.startConnection();
    this.store2.pipe(select(selectAllUserData),
      takeWhile(() => this.componentActive))
      .subscribe(data => {
        let userData = data;
        this.is2FAOn = data.data.twoFactorAuthEnabled;

        if (this.is2FAOn) {
          this.pinForm.addControl(
            "TwoFA",
            new FormControl("", [
              Validators.required, Validators.minLength(6), Validators.maxLength(6)
            ])
          );
        }
      });
    this.userStore.pipe(select(getLoggedinUserdata),
    takeWhile(() => this.componentActive))
    .subscribe(data => {
      this.LoginData = data;
    });
  }

  private initializeForm() {
    if (!this.isTransfer) {
      this.fundCryptoWalletForm = new FormGroup({
        AddressId: new FormControl(this.addressId, Validators.required),
        Amount: new FormControl(this.minCryptoValue, [Validators.required, Validators.min(this.minCryptoValue)]),
        TransactionToken: new FormControl(''),

      });
    } else {
      this.fundCryptoWalletForm = new FormGroup({
        UserPhoneNumber: new FormControl(""),
        AddressId: new FormControl(String(this.addressId), Validators.required),
        Amount: new FormControl(this.minCryptoValue, [Validators.required, Validators.min(this.minCryptoValue)]),
        DestinationAddress: new FormControl('', [Validators.required, Validators.minLength(34), Validators.maxLength(42)]),
        TransactionToken: new FormControl(''),
        Narration: new FormControl('', [Validators.maxLength(20)]),
        BeneficiaryName: new FormControl('', [Validators.maxLength(20)])
      });
    }
    this.pinForm = new FormGroup({
      TransactionPin: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),

    });

    // this.amountInNaira = this.BTCExchangeRate * this.minCryptoValue;
  }

  getTransactionToken() {
    let request: CheckTransactionPin = {
      TransactionPin: this.pinForm.controls.TransactionPin.value,

    };
    if (this.is2FAOn) {

      request.AuthPin = this.pinForm.controls.TwoFA.value
    }
    this.authProvider.getTransactionToken(request).subscribe(res => {

      this.token = res['token'];
    });
  }

  setWithdrawalAmountToAll() {
    this.fundCryptoWalletForm.controls["Amount"].setValue(this.balance);
    this.isCryptoAmount = true;
  }

  currencyChanged(isCrypto) {
    this.isCryptoAmount = isCrypto == 'true' ? true : false;

    if (!this.isCryptoAmount) {
      this.minCryptoValue = this.minCryptoValue * this.BTCExchangeRate;
      if (!this.fundCryptoWalletForm.controls['Amount'].dirty)
        this.fundCryptoWalletForm.controls["Amount"].setValue(this.minCryptoValue);
      this.amountCurrencyText = ' BTC';
    } else {
      this.minCryptoValue = this.minCryptoValue / this.BTCExchangeRate;
      if (!this.fundCryptoWalletForm.controls['Amount'].dirty)
        this.fundCryptoWalletForm.controls["Amount"].setValue(this.minCryptoValue);
      this.amountCurrencyText = ' Naira';
    }
  }

  CryptoWalletAction() {
    if (this.fundCryptoWalletForm.valid && this.pinForm.valid) {

      let request: CheckTransactionPin = {
        TransactionPin: this.pinForm.controls.TransactionPin.value
      };
      if (this.is2FAOn) {
        request.AuthPin = this.pinForm.controls.TwoFA.value
      }
      this.transactionAmount = this.fundCryptoWalletForm.value['Amount'];
      this.loader.show();
      this.authProvider.getTransactionToken(request).subscribe(res => {
        this.token = res['token'];



        if (this.isFunding) {
          const fundCryptoWalletRequest: FundCryptoWalletRequest = {
            AddressId: this.fundCryptoWalletForm.value['AddressId'],
            Amount: this.fundCryptoWalletForm.value['Amount'],
            TransactionToken: res['token'],
            IsCryptoAmount: this.isCryptoAmount
          };
          this.store.dispatch(new cryptoActions.FundCryptoWallet(fundCryptoWalletRequest));

          this.store.pipe(select(fromCrypto.getFundingResponse),
            takeWhile(() => this.componentActive))
            .subscribe(res => {

              // this.signalRService.addTransferTransactionDataListener(`${this.address}-${CryptoTransactionType.Funding}`);

              if (res != null) {
                this.isSuccess = true;
              }

              if (res == null) {
                this.store.pipe(select(fromCrypto.getError),
                  takeWhile(() => this.componentActive))
                  .subscribe(err => {

                    this.isError = true;
                    this.errorMessage = err;
                  });
              }

            });
        }

        if (this.isWithdrawal) {
          const fundCryptoWalletRequest: FundCryptoWalletRequest = {
            AddressId: this.fundCryptoWalletForm.value['AddressId'],
            Amount: this.fundCryptoWalletForm.value['Amount'],
            TransactionToken: res['token'],
            IsCryptoAmount: this.isCryptoAmount
          };

          this.store.dispatch(new cryptoActions.WithdrawFromCryptoWallet(fundCryptoWalletRequest));

          this.store.pipe(select(fromCrypto.getWithdrawalResponse),
            takeWhile(() => this.componentActive))
            .subscribe(res => {

              // this.signalRService.addTransferTransactionDataListener(`${this.address}-${CryptoTransactionType.Withdrawal}`);
              //
              if (res != null) {
                this.isSuccess = true;
              }

              if (res == null) {
                this.store.pipe(select(fromCrypto.getError),
                  takeWhile(() => this.componentActive))
                  .subscribe(err => {

                    this.isError = true;
                    this.errorMessage = err;
                  });
              }

            });
        }

        if (this.isTransfer) {
          const transferRequest: SendETHRequest = {
            DestinationAddress: this.fundCryptoWalletForm.value['DestinationAddress'],
            Amount: this.fundCryptoWalletForm.value['Amount'],
            TransactionCode: res['token'],
            Narration: this.fundCryptoWalletForm.value['Narration']
          };
          this.store.dispatch(new cryptoActions.TransferToETHAddress(transferRequest));

          this.store.pipe(select(fromCrypto.getTransferResponse),
            takeWhile(() => this.componentActive))
            .subscribe(res => {

              // this.signalRService.addTransferTransactionDataListener(`${this.address}-${CryptoTransactionType.Transfer}`);
              console.log(res);
              if (res != null) {
                this.isSuccess = true;
              }

              if (res == null) {
                this.store.pipe(select(fromCrypto.getError),
                  takeWhile(() => this.componentActive))
                  .subscribe(err => {

                    this.isError = true;
                    this.errorMessage = err;
                  });
              }

            });
        }
      }, (err) => {
        this.loader.hide();
        this.isError = true;
        this.errorMessage = err.error['message']
      });
    } else {

      this.formInvalid = true;
    }
  }

  showPinForm() {
    if(this.fundCryptoWalletForm.valid){
      this.isPinPage = true
    }
    else {
      this.showFormErrorText();
      this.isPinPage = false;
    }
  }

  backToFundCryptoWalletForm() {
    this.isPinPage = false;
    this.pinForm.reset();
  }

  fetchRecentRecipients() {
    this.recentRecipientsModal = this.modalService.open(
      WalletrecentrecipientComponent,
      {
        windowClass: "savedRecipientModal",
        centered: true,
        // backdrop: "static"
      }
    );

    this.recentRecipientsModal.componentInstance.isForCrypto = true;
    this.recentRecipientsModal.componentInstance.walletId = this.walletId;
    // this.recentRecipientsModal.componentInstance.isLoading = this.isRecipientLoading;

    this.recentRecipientsModal.result
      .then((result: any) => {
        if (typeof result === "object") {
          // setting the destinationAddress field to the value of the saved beneficiary address
          const address = result.model.beneficiaryWalletAddress;
          const name = result.model.beneficiaryName;
          this.fundCryptoWalletForm.controls["DestinationAddress"].setValue(address);
          this.fundCryptoWalletForm.controls["BeneficiaryName"].setValue(name);

        }
      })
      .catch((error) => { });
  }

  reloadPage() {
    document.dispatchEvent(
      new KeyboardEvent("keydown", {
        key: "escape"
      })
    );
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/crypto/ethereum']);
  }

  ngOnDestroy() {
    this.componentActive = false;

  }
  showFormErrorText() {
    this.showFormError = true;
    setTimeout(() => {
      this.showFormError = false; 
    }, 3000);
  }

}

