import { Injectable } from '@angular/core';

@Injectable()
export class WebStorageService {
    constructor(){

    }

    keepItemInLocalStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    getItemFromLocalStorage(key: string) {
        return localStorage.getItem(key);
    }

    removeItemFromLocalStorage(key: string) {
        localStorage.removeItem(key);
    }
}  