
import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from "src/app/core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { FecthSubwallets, SubwalletActionTypes, UpdateSubwallets } from './subwallets.actions';
import { SubwalletProvider } from 'src/app/service/subwallet.service';
import { Observable, of } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { GeneralUpdateError } from '../errormessages/errormessages.actions';
import { Donothing } from '../userdata/userdata.actions';

@Injectable({
  providedIn: "root"
})
export class SubwalletdataEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private loader: NgxSpinnerService,
    private userService: UserService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private subwalletService: SubwalletProvider) { }


  fetchCryptoFee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubwalletActionTypes.FecthSubwallets),
      mergeMap(({payload}: any) => {


        return this.subwalletService.getAlluser(payload.page, payload.pageSize, payload.searchtext).pipe(
          map(data => {

            data.isLoading = false;
            data.isFirstLoad = true;
            return new  UpdateSubwallets(data);
          }),
          catchError(err => {
            return of(new Donothing());
          })
        )
      })
    )
  );
}
