import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndPoints } from '../../config/endpoints';
import { Observable } from 'rxjs';
import { CreateCryptoAddressRequest } from '../../model/request/create_crypto_address.request';
import { FundCryptoWalletRequest, ExchangeRateQueryParams, GetUserCryptoTransactionsRequest, GetUserCryptoWalletRequest, SendCryptoRequest } from '../../model/request/fund_crypto_wallet.model';
import { CryptoCurrencyType } from './../../model/request/crypto_currency.type';

@Injectable()
export class WalletsCryptoService {

  headers = new HttpHeaders({
    "Content-Type": "application/json"
  });

  constructor(private http: HttpClient) { }

  createNewCryptoWallet(request: CreateCryptoAddressRequest): Observable<any> {
    return this.http.post(EndPoints.NEW_CRYPTO.CREATE_CRYPTO_ADDRESS, request, {
      headers: this.headers
    });
  }

  fundCryptoWallet(request: FundCryptoWalletRequest): Observable<any> {
    return this.http.post(EndPoints.NEW_CRYPTO.FUND_CRYPTO_WALLET, request, {
      headers: this.headers
    });
  }
  
  withdrawFromCryptoWallet(request: FundCryptoWalletRequest): Observable<any> {
    return this.http.post(EndPoints.NEW_CRYPTO.WITHDRAW_IN_FIAT, request, {
      headers: this.headers
    });
  }

  getUserCryptoWallet(): Observable<any> {
    return this.http.post(`${EndPoints.NEW_CRYPTO.DASHBOARD_DATA}`, {
      headers: this.headers
    });
  }

  getUserCryptoTransactions(request: GetUserCryptoTransactionsRequest): Observable<any> {
    return this.http.post(`${EndPoints.NEW_CRYPTO.GET_USER_CRYPTO_TRANSACTIONS}?${this.toQueryString(request)}`, {
      headers: this.headers
    });
  }
  
  transferToCryptoAddress(request: SendCryptoRequest): Observable<any> {
    return this.http.post(`${EndPoints.NEW_CRYPTO.TRANSFER_TO_CRYPTO_ADDRESS}`, request, {
      headers: this.headers
    });
  }

  hasCryptoWallet(request: CryptoCurrencyType) {
    return this.http.get(`${EndPoints.NEW_CRYPTO.USER_HAS_CRYPTO_WALLET}/${request}`, {
      headers: this.headers
    });
  }

  fetchBeneficiaries(request: number) {
    return this.http.get(`${EndPoints.NEW_CRYPTO.FETCH_BENEFICIARIES}/${request}`, {
      headers: this.headers
    });
  }

  private toQueryString(obj) {
    const parts = [];
    for (const property in obj) {
      const value = obj[property];
      if (value != null && value !== undefined) {
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
      }
    }
    return parts.join('&');
  }

}
