import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, throwError, BehaviorSubject } from "rxjs";
import { JwtService } from "../services/jwt.service";
import { map, tap, catchError, switchMap, filter, take } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthProvider } from "src/app/service/auth.service";


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private jwtService: JwtService, private router: Router, private authService: AuthProvider) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let onlineOffline: boolean = navigator.onLine;
    const headersConfig = {
      // "Content-Type": "application/json",
      Accept: "application/json"
    };

    const token = this.jwtService.getToken();

    if (token) {
      headersConfig["Authorization"] = `Bearer ${token}`;
    }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request).pipe(
      map(evt => {
        if (evt instanceof HttpResponse) {
        }
        return evt;
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (!onlineOffline) {
            err.error.message = "Please check your network connection and try again.";
            // err.error.content = {};
          } else if (err.status === 401) {
            // return this.handle401Error(request, next);
            this.router.navigate(["/login"]);
          } else if (err.status === 0) {
            if (err.url.includes("Refresh")) {
              this.authService.logout();
              return;
            }
            err.error.message = "Sorry we couldn’t complete your request. Please try again.";
          } else if (err.status === 400) {
            if (request.url.includes("Refresh")) {
              this.authService.logout();
            }

          }
          else if (err.url.includes("Refresh")) {
            this.authService.logout();

          } else {
            if (!err.error) {
              err = { ...err, error: {} }
              err.error.message = "Sorry we couldn’t complete your request. Please try again.";
            }


          }


        }
        return throwError(err);
      }));
  }


  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}


