import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import {
  DashboardStatsResponse,
  DashboardResponse,
  BalanceResponse,
} from "../model/response/dashboard.response";
import { EndPoints } from "../config/endpoints";
import { catchError, map } from "rxjs/operators";
import {
  DashboardRequest,
  DashboardPeriod,
} from "../model/request/dashboard.request";
import { FundingResponse, CryptoFee } from "../model/response/funding.response";
import { FundingAmount } from "../model/request/funding.request";

@Injectable()
export class CryptoService {
  constructor(private http: HttpClient) {}

  generateCrptoAddress(data: FundingAmount): Observable<FundingResponse> {
    return this.http
      .post(EndPoints.CRYPTO.GENERATEADDRESS, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getfee(): Observable<CryptoFee> {
    return this.http
      .get(EndPoints.CRYPTO.GETFEE, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  checkstatus(ref): Observable<any> {
    return this.http
      .get(EndPoints.CRYPTO.GETSTATUS + `?reference=${ref}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
