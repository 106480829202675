import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { pipes } from '.';


@NgModule({
  declarations: [pipes],
 exports:[pipes]
})
export class PipesModule { }
