import { Donothing } from "./../userdata/userdata.actions";
import { DashboardActionTypes, FetchDashboardData, UpdataBanks, UpdatacryptoFee, UpdataCurrentUserBalance, UpdataDashboardData, UpdataRecentTransactions, UpdataUsersCards } from "./dashboarddata.actions";

import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from "src/app/core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { Observable, of } from "rxjs";
import { CryptoService } from "src/app/service/crypto.service";
import { Action } from "rxjs/internal/scheduler/Action";
import { map, mergeMap, catchError } from "rxjs/operators";
import { GeneralUpdateError } from "../errormessages/errormessages.actions";
import { LoginUserSuccess } from "../userdata/userdata.actions";
import { DashboardService } from "src/app/service/dashboard.service";
import { CardFundingService } from "src/app/service/cardfunding.service";
import { TransferProvider } from "src/app/service/transfer.service";
import { documentUpledStatus } from 'src/app/model/response/settings.response';

@Injectable({
  providedIn: "root"
})
export class DashboarddataEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private loader: NgxSpinnerService,
    private userService: UserService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private cryptoService: CryptoService,
    private dashboardService: DashboardService,
    private cardFundingService: CardFundingService,
    private transferService: TransferProvider,) { }


  fetchDashboardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.FetchDashboardData),
      mergeMap(() => this.dashboardService.dashboardData().pipe(
        map(data => {
          data.businessdocuments = {
            cacFile:documentUpledStatus.notuploaded,
            co2File:documentUpledStatus.notuploaded,
            mermatFile:documentUpledStatus.notuploaded,
            directorsID:documentUpledStatus.notuploaded,
            utilityBill:documentUpledStatus.notuploaded,
            applicationdoc:documentUpledStatus.notuploaded,
            c11File:documentUpledStatus.notuploaded,
            certificateofReg:documentUpledStatus.notuploaded,
            directorsID2:documentUpledStatus.notuploaded,
          }
          if (!data.documentsUploaded || data.documentsUploaded == null) {
            data.documentsUploaded = []
          }

          for (let index = 0; index < data.documentsUploaded.length; index++) {
            const docs = data.documentsUploaded[index];


            if (docs.documentType == "CACCertificate") {

              data.businessdocuments.cacFile = docs.status;

            }
            if (docs.documentType == "FormC02") {

              data.businessdocuments.co2File = docs.status;
            }
            if (docs.documentType == "MermatFile") {

              data.businessdocuments.mermatFile = docs.status;

            }
            if (docs.documentType == "DirectorsId") {

              data.businessdocuments.directorsID = docs.status;
            }
            if (docs.documentType == "UtilityBill") {

              data.businessdocuments.utilityBill = docs.status;
            }

            if (docs.documentType == "Form11") {

              data.businessdocuments.c11File = docs.status;
            }
            if (docs.documentType == "Director2Id") {

              data.businessdocuments.directorsID2 = docs.status;
            }
            if (docs.documentType == "ApplicationForRegisterationOfBusiness") {

              data.businessdocuments.applicationdoc = docs.status;
            }

            if (docs.documentType == "CertificateOfRegisteration") {

              data.businessdocuments.certificateofReg = docs.status;
            }

          }

          return new UpdataDashboardData(data);
        }),
        catchError(err => {
          return of(new Donothing());
        })
      )
      )
    )
  );

  fetchRecentTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.FetchRecentTransactions),
      mergeMap(() => this.dashboardService.recentTransactions().pipe(
        map(data => {
          
          return new UpdataRecentTransactions(data);
        }),
        catchError(err => {
          return of(new Donothing());
        })
      )
      )
    )
  );

  fetchCryptoFee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.FetchCryptoFee),
      mergeMap(() => this.cryptoService.getfee().pipe(
        map(data => {

          return new UpdatacryptoFee(data.feeInPercentage);
        }),
        catchError(err => {
          return of(new Donothing());
        })
      )
      )
    )
  );

  fetchUserbalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.FetchCurrentUserBalance),
      mergeMap(() => this.dashboardService.fetchingCurrentBalance().pipe(
        map(data => {

          let response = {
            walletBalance: data.balance,
            overdraftAmount: data.overdraftAmount,
            overdraftDate: data.overdraftDate,
            eligibleOverdraftAmount: data.eligibleOverdraftAmount,
          }
          return new UpdataCurrentUserBalance(response);
        }),
        catchError(err => {
          return of(new Donothing());
        })
      )
      )
    )
  );


  fetchCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.FetchUsersCards),
      mergeMap(() => this.cardFundingService.getCards().pipe(
        map(data => {

          return new UpdataUsersCards(data);
        }),
        catchError(err => {
          return of(new Donothing());
        })
      )
      )
    )
  );

  fetchBanks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.FetchBanks),
      mergeMap(() => this.transferService.getBanks().pipe(
        map(data => {

          return new UpdataBanks(data);
        }),
        catchError(err => {
          return of(new Donothing());
        })
      )
      )
    )
  );



}
