import { Action } from "@ngrx/store";

export enum BulktransferActionTypes {
  RESETBULKTRANSFERSTATE = "[BULKTRANSFERDATA] Reset Data",
  //wallet
  UpdateAllBulkwalletLists = "[BULKTRANSFERDATA] Update All Bulkwallet Lists",
  UpdateSingleBulkwalletLists = "[BULKTRANSFERDATA] Update Single Bulkwallet Lists",
  UpdateDeleteBulkwalletLists = "[BULKTRANSFERDATA] Update Delete Bulkwallet Lists",
  AddItemBulkwalletLists = "[BULKTRANSFERDATA] Add  Bulkwallet Lists Item",
  UpdateWalletRecipientsList = "[BULKTRANSFERDATA] UpdateWalletRecipientsList Lists",
  //banks
  UpdateAllBulkBanksLists = "[BULKTRANSFERDATA] Update All BulkBanks Lists",
  UpdateSingleBulkBanksLists = "[BULKTRANSFERDATA] Update Single BulkBanks Lists",
  UpdateDeleteBulkBanksLists = "[BULKTRANSFERDATA] Update Delete BulkBanks Lists",
  AddItemBulkBanksLists = "[BULKTRANSFERDATA] Add  BulkBanks Lists Item",
  UpdateBanksRecipientsList = "[BULKTRANSFERDATA] UpdateBanksRecipientsList Lists",
  //International
  UpdateAllBulkInternationalLists = "[BULKTRANSFERDATA] Update All BulkInternational Lists",
  UpdateSingleBulkInternationalLists = "[BULKTRANSFERDATA] Update Single BulkInternational Lists",
  UpdateDeleteBulkInternationalLists = "[BULKTRANSFERDATA] Update Delete BulkInternational Lists",
  AddItemBulkInternationalLists = "[BULKTRANSFERDATA] Add  BulkInternational Lists Item",
  UpdateInternationalRecipientsList = "[BULKTRANSFERDATA] UpdateInternationalRecipientsList Lists",
  //Airtime
  UpdateAllBulkAirtimeLists = "[BULKTRANSFERDATA] Update All BulkAirtime Lists",
  UpdateSingleBulkAirtimeLists = "[BULKTRANSFERDATA] Update Single BulkAirtime Lists",
  UpdateDeleteBulkAirtimeLists = "[BULKTRANSFERDATA] Update Delete BulkAirtime Lists",
  AddItemBulkAirtimeLists = "[BULKTRANSFERDATA] Add  BulkAirtime Lists Item",
  UpdateAirtimeRecipientsList = "[BULKTRANSFERDATA] UpdateAirtimeRecipientsList Lists",
}


export class RESETBULKTRANSFERSTATE implements Action {
  readonly type = BulktransferActionTypes.RESETBULKTRANSFERSTATE;
}
// wallet
export class UpdateAllBulkwalletLists implements Action {
  readonly type = BulktransferActionTypes.UpdateAllBulkwalletLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateAllBulkwalletLists;
  }
}

export class UpdateSingleBulkwalletLists implements Action {
  readonly type = BulktransferActionTypes.UpdateSingleBulkwalletLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateSingleBulkwalletLists;
  }
}

export class UpdateDeleteBulkwalletLists implements Action {
  readonly type = BulktransferActionTypes.UpdateDeleteBulkwalletLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateDeleteBulkwalletLists;
  }
}

export class AddItemBulkwalletLists implements Action {
  readonly type = BulktransferActionTypes.AddItemBulkwalletLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.AddItemBulkwalletLists;
  }
}



export class UpdateWalletRecipientsList implements Action {
  readonly type = BulktransferActionTypes.UpdateWalletRecipientsList;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateWalletRecipientsList;
  }
}

// Banks
export class UpdateAllBulkBanksLists implements Action {
  readonly type = BulktransferActionTypes.UpdateAllBulkBanksLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateAllBulkBanksLists;
  }
}

export class UpdateSingleBulkBanksLists implements Action {
  readonly type = BulktransferActionTypes.UpdateSingleBulkBanksLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateSingleBulkBanksLists;
  }
}

export class UpdateDeleteBulkBanksLists implements Action {
  readonly type = BulktransferActionTypes.UpdateDeleteBulkBanksLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateDeleteBulkBanksLists;
  }
}

export class AddItemBulkBanksLists implements Action {
  readonly type = BulktransferActionTypes.AddItemBulkBanksLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.AddItemBulkBanksLists;
  }
}

export class UpdateBanksRecipientsList implements Action {
  readonly type = BulktransferActionTypes.UpdateBanksRecipientsList;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateBanksRecipientsList;
  }
}

// Internationnal
export class UpdateAllBulkInternationalLists implements Action {
  readonly type = BulktransferActionTypes.UpdateAllBulkInternationalLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateAllBulkInternationalLists;
  }
}

export class UpdateSingleBulkInternationalLists implements Action {
  readonly type = BulktransferActionTypes.UpdateSingleBulkInternationalLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateSingleBulkInternationalLists;
  }
}

export class UpdateDeleteBulkInternationalLists implements Action {
  readonly type = BulktransferActionTypes.UpdateDeleteBulkInternationalLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateDeleteBulkInternationalLists;
  }
}

export class AddItemBulkInternationalLists implements Action {
  readonly type = BulktransferActionTypes.AddItemBulkInternationalLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.AddItemBulkInternationalLists;
  }
}

export class UpdateInternationalRecipientsList implements Action {
  readonly type = BulktransferActionTypes.UpdateInternationalRecipientsList;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateInternationalRecipientsList;
  }
}

// Airtime
export class UpdateAllBulkAirtimeLists implements Action {
  readonly type = BulktransferActionTypes.UpdateAllBulkAirtimeLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateAllBulkAirtimeLists;
  }
}

export class UpdateSingleBulkAirtimeLists implements Action {
  readonly type = BulktransferActionTypes.UpdateSingleBulkAirtimeLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateSingleBulkAirtimeLists;
  }
}

export class UpdateDeleteBulkAirtimeLists implements Action {
  readonly type = BulktransferActionTypes.UpdateDeleteBulkAirtimeLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateDeleteBulkAirtimeLists;
  }
}

export class AddItemBulkAirtimeLists implements Action {
  readonly type = BulktransferActionTypes.AddItemBulkAirtimeLists;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.AddItemBulkAirtimeLists;
  }
}

export class UpdateAirtimeRecipientsList implements Action {
  readonly type = BulktransferActionTypes.UpdateAirtimeRecipientsList;

  constructor(public payload: any) {
    this.type = BulktransferActionTypes.UpdateAirtimeRecipientsList;
  }
}





export type BulktransferdataActions =
  RESETBULKTRANSFERSTATE
  | UpdateAllBulkwalletLists
  | UpdateSingleBulkwalletLists
  | UpdateDeleteBulkwalletLists
  | AddItemBulkwalletLists
  | UpdateWalletRecipientsList
  | UpdateAllBulkBanksLists
  | UpdateSingleBulkBanksLists
  | UpdateDeleteBulkBanksLists
  | AddItemBulkBanksLists
  | UpdateBanksRecipientsList
  //
  | UpdateAllBulkBanksLists
  | UpdateSingleBulkBanksLists
  | UpdateDeleteBulkBanksLists
  | AddItemBulkBanksLists
  | UpdateBanksRecipientsList
  //
  | UpdateAllBulkInternationalLists
  | UpdateSingleBulkInternationalLists
  | UpdateDeleteBulkInternationalLists
  | AddItemBulkInternationalLists
  | UpdateInternationalRecipientsList
    //
    | UpdateAllBulkAirtimeLists
    | UpdateSingleBulkAirtimeLists
    | UpdateDeleteBulkAirtimeLists
    | AddItemBulkAirtimeLists
    | UpdateAirtimeRecipientsList;
