import { map } from "rxjs/operators";
import { NairacardWithdrawData } from "src/app/model/request/card.request";
import { Cardrequests, DollarCards, NairaCardDetail } from "src/app/model/response/card.response";
import { CardActionTypes, CarddataActions } from "./carddata.actions";

interface GetNairaCards {
  isLoading?: boolean;
  nairacards?: NairaCardDetail[];
}

interface GetCardRequests {
  isLoading?: boolean;
  cardRequests?: Cardrequests[];
}


export interface CardState {
  message?: string;
  isFirstLoad?: boolean;
  isDollarFirstLoad?: boolean;
  isNairaFirstLoad?: boolean;
  isRequestFirstLoad?: boolean;
  allDollarCards?: DollarCards;
  allCardsRequest?: GetCardRequests;
  allNairaCards?: GetNairaCards;
  nairaCardActionData?: NairacardWithdrawData;
}



const initialState: CardState = {

  message: "",
  isFirstLoad: false,
  isDollarFirstLoad: false,
  isNairaFirstLoad: false,
  isRequestFirstLoad: false,
  allNairaCards: {
    isLoading: true,
    nairacards: [],
  },
  allDollarCards: {
    cards: [],
    buyFees: 0,
    costFees: 0,
    fundingLimit: 0,
    customerTransactions: [],
    minimumDollarFundFee: 0,
    spendFees: 0,
    isLoading: true,
    isFirstLoad: false,
  },
  allCardsRequest: {
    isLoading: true,
    cardRequests: [],
  },
  nairaCardActionData: {}
};

export function Carddatareducer(state = initialState, action: CarddataActions): CardState {

  switch (action.type) {
    case CardActionTypes.ResetCardsDataState:

      return {
        ...initialState
      };
    case CardActionTypes.UpdateCardsRequests:

      return {
        ...state,
        ...action.payload

      };

    case CardActionTypes.UpdateNairaCards:

      return {
        ...state,
        allNairaCards: {
          ...state.allNairaCards,
          nairacards: state.allNairaCards.nairacards.map((cards) => {
            if (cards.last4 == action.payload.last4) {
              return action.payload;
            }
            return cards;
          })
        }

      };

    case CardActionTypes.UpdateDollarCards:

      return {
        ...state,
        allDollarCards: {
          ...state.allDollarCards,
          cards: state.allDollarCards.cards.map((cards) => {
            if (cards.barterCardId == action.payload.barterCardId) {
              return action.payload;
            }
            return cards;
          })
        }

      };
    case CardActionTypes.UpdateCardsActiondata:

      return {
        ...state,
        nairaCardActionData: { ...action.payload }

      };
    default:
      return {
        ...state
      };
  }
}

