import { FormatService } from './formatter.service';
import {AnalyticsService} from "./analytics.service";
import {LoaderService} from "./loader.service";
import { UtilitySerivce } from './utility.service';

export const services = [
    AnalyticsService,
    LoaderService,
    FormatService,
    UtilitySerivce
];
