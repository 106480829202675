import { Action } from "@ngrx/store";

export enum ErrorActionTypes {
  ResetErrorState = "[ERRORDATA] Reset Data",
  UpdateLoginError = "[ERRORDATA] Update Login Error",
  GeneralUpdateError = "[ERRORDATA] Update General Error",
}

// Action Creators
export class ResetErrorState implements Action {
  readonly type = ErrorActionTypes.ResetErrorState;
}

export class UpdateLoginError implements Action {
  readonly type = ErrorActionTypes.UpdateLoginError;

  constructor(public payload: any) {
    this.type = ErrorActionTypes.UpdateLoginError;
  }
}

export class GeneralUpdateError implements Action {
  readonly type = ErrorActionTypes.GeneralUpdateError;

  constructor(public payload: any) {
    this.type = ErrorActionTypes.GeneralUpdateError;
  }
}




export type ErrordataActions =
  ResetErrorState
  | UpdateLoginError
  | GeneralUpdateError;
