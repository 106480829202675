import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CryptoCurrencyType } from '../../model/request/crypto_currency.type';
import { EtherWallet, Usertype } from 'src/app/model/response/auth.response';
import { FundCryptoWalletModalComponent } from 'src/app/shared/components/modal/fund-crypto-wallet/fund-crypto-wallet.component';
import { LoginResponse } from "../../model/response/auth.response";
import * as cryptoReducer from '../../state/crypto/crypto.reducer';
import * as cryptoActions from '../../state/crypto/crypto.actions';
import * as fromCrypto from '../../state/crypto/index';
import { Store, select } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { ExchangeRateQueryParams, GetUserCryptoTransactionsRequest, GetUserCryptoWalletRequest } from '../../model/request/fund_crypto_wallet.model';
import { Router } from '@angular/router';
import { FormatService } from 'src/app/shared/services/formatter.service';
import { WebStorageService } from 'src/app/service/web-storage.service';
import { LOCAL_STORAGE_ITEMS, CRYPTO_CONSTANTS } from 'src/app/config/constants';
import { WalletsCryptoService } from 'src/app/state/crypto/crypto.service';
import { CryptoCurrencyWallet, CryptoWalletTransaction, ExchangeRateResponse } from 'src/app/model/response/create_crypto_address.response';
import { FormControl, FormGroup } from '@angular/forms';
import { getLoggedinUserdata } from "src/app/store/userdata";
import { UserdataState } from "src/app/store/userdata/userdata.reducer";
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-crypto',
  templateUrl: './bitcoin.component.html',
  styleUrls: ['./bitcoin.component.scss']
})
export class BitcoinComponent implements OnInit {

  cryptoWalletModalRef: NgbModalRef;
  isLoading: boolean = true;
  usertype: Usertype;
  userPhoneNumber: string;
  componentActive: boolean = true;
  copyToast: boolean = false;
  isError: boolean;
  errorMessage: string;
  BTCExchangeRate: ExchangeRateResponse;
  cryptoTransactions = [];
  BTCWallet: CryptoCurrencyWallet;
  ETHWallet: EtherWallet;
  transactions: CryptoWalletTransaction[];
  hasBTCWallet: boolean;
  isActive: boolean;
  etherBalance = 0;
  dollarRate = 0;
  amtInDollar = 0;
  createCryptoWalletForm: FormGroup;
  createNewCryptoWalletForm: FormGroup;
  search: string = '';
  totalItems: number;
  showRecieveAddress: boolean = false;
  LoginData: LoginResponse;

  constructor(private modalService: NgbModal,
    private store: Store<cryptoReducer.CryptoState>,
    private router: Router,
    private formatService: FormatService,
    private loader: NgxSpinnerService,
    private webStorageService: WebStorageService,
    private userStore: Store<UserdataState>,
    private walletsCryptoService: WalletsCryptoService) {
  }

  ngOnInit() {
    
    this.userStore.pipe(select(getLoggedinUserdata),
    takeWhile(() => this.componentActive))
    .subscribe(data => {
      this.LoginData = data;
      this.ETHWallet = this.LoginData.data.etherWallet;
      this.cryptoTransactions = this.LoginData.data.etherWallet.transactions
    });
    this.cryptoInit();
    // this.hasBTCWallet = this.webStorageService.getItemFromLocalStorage(LOCAL_STORAGE_ITEMS.HAS_BTC_WALLET) == 'true';
    // if (!this.hasBTCWallet) {
    //   this.initializeForm();
    //   this.CreateNewCryptoWallet();
    // }

    // if (this.hasBTCWallet) {
    //   this.BTCWallet = JSON.parse(this.webStorageService.getItemFromLocalStorage(LOCAL_STORAGE_ITEMS.BTC_WALLET));
    //   this.dollarRate = CRYPTO_CONSTANTS.DOLLAR_RATE;
    //   // this.nairaBalance = this.BTCWallet.availableBalance * this.BTCExchangeRate.buyingRate;
    // }

    // this.checkIfUserNowHasWallet();

  }

  cryptoInit() {
    this.loader.show();
    this.isError = false;
    this.errorMessage = "";
    // this.walletsCryptoService.hasCryptoWallet(CryptoCurrencyType.Bitcoin).subscribe(res => {
    //   this.webStorageService.keepItemInLocalStorage("HasBTCWallet", String(res));
    // });

    // const getUserCryptoWalletRequest: GetUserCryptoWalletRequest = {
    //   CurrencyType: CryptoCurrencyType.Bitcoin
    // };

    this.walletsCryptoService.getUserCryptoWallet().subscribe(res => {
      
      this.etherBalance = res.availableBalance;
      this.cryptoTransactions = res.transactions;
      this.isActive = res.isActive;
      this.webStorageService.keepItemInLocalStorage(LOCAL_STORAGE_ITEMS.ETH_WALLET, JSON.stringify(res));
      this.loader.hide();
      this.isLoading = false;
    });
  }

  private getCryptoToFiatExchangeRate() {
    const exchangeRateQueryParams: ExchangeRateQueryParams = {
      Crypto: 'BTC',
      Fiat: 'USD'
    };

    this.store.dispatch(new cryptoActions.GetCryptoToFiatExchangeRate(exchangeRateQueryParams));

    this.store.pipe(select(fromCrypto.getBTCExchangeRate),
      takeWhile(() => this.componentActive))
      .subscribe(res => {
        this.BTCExchangeRate.rate = res['rate'];

        // this.nairaBalance = this.amtInDollar * res['rate'];

        if (res == null) {
          this.store.pipe(select(fromCrypto.getError),
            takeWhile(() => this.componentActive))
            .subscribe(err => {
              this.isError = true;
              this.errorMessage = err;
            });
        }

      });
  }

  getTransactionType(destinationAddress: string) {
    if (destinationAddress.toLowerCase() === this.BTCWallet.address) {
      return 'CREDIT';
    }
    if (destinationAddress.toLowerCase() !== this.BTCWallet.address) {
      return 'DEBIT';
    }
  }


  copyToClipboard() {
    var address = document.createElement('input');
    document.body.appendChild(address);
    address.value = this.LoginData.data.etherWallet.address;
    address.select();
    document.execCommand("copy");
    document.body.removeChild(address);
    this.copyToast = true;
    setTimeout(() => {
      this.copyToast = false; 
    }, 3000);
  }

  showReceiveAddressClick(){
    this.showRecieveAddress = true;
  }

  hideReceiveAddressClick(){
    this.showRecieveAddress = false;
  }


  CreateNewCryptoWallet() {
    this.loader.show();
    this.isError = false;
    this.errorMessage = "";
    this.store.dispatch(new cryptoActions.CreateCryptoAddress());

    this.store.pipe(select(fromCrypto.getCreatedAddress),
      takeWhile(() => this.componentActive))
      .subscribe((res) => {
        console.log(res);
        if(res != null) {
          this.reloadPage();
        }
        if(res == null){
          this.store.pipe(select(fromCrypto.getError),
          takeWhile(() => this.componentActive))
          .subscribe((res) => {
            this.isError = true;
            this.errorMessage = res;
          }, 
          (error) => {
            
          });
        }
      }, 
      (error) => {
        this.loader.hide();
        this.isError = true;
        this.errorMessage = error.message;
      });
    
  }

  reloadPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/dashboard']);
  }


  FundCryptoWallet() {
    this.cryptoWalletModalRef = this.modalService.open(
      FundCryptoWalletModalComponent,
      {
        windowClass: "cardModalForm",
        centered: true,
        backdrop: "static",
      }
    );

    this.cryptoWalletModalRef.componentInstance.modalContent = {
      title: "Buy BTC ",
    };
    this.cryptoWalletModalRef.componentInstance.cryptoCurrencyType = CryptoCurrencyType.Bitcoin;
    this.cryptoWalletModalRef.componentInstance.actionButtonText = 'Fund BTC Wallet';
    this.cryptoWalletModalRef.componentInstance.isWithdrawal = false;
    this.cryptoWalletModalRef.componentInstance.isFunding = true;
    this.cryptoWalletModalRef.componentInstance.isTransfer = false;
    this.cryptoWalletModalRef.componentInstance.isSuccess = false;
    this.cryptoWalletModalRef.componentInstance.successSubtitle = ' It may take some time to process';
    this.cryptoWalletModalRef.componentInstance.successTitle = 'Your transaction is on its way!';
    this.cryptoWalletModalRef.componentInstance.addressId = this.BTCWallet['cryptoCurrencyWalletId'];
    this.cryptoWalletModalRef.componentInstance.address = this.BTCWallet.address;
    this.cryptoWalletModalRef.componentInstance.minCryptoValue = 0.00001;
    this.cryptoWalletModalRef.componentInstance.BTCExchangeRate = this.BTCExchangeRate.sellingRate;
    this.cryptoWalletModalRef.componentInstance.transactionFee = 0;
    this.cryptoWalletModalRef.componentInstance.cryptoTransactionFee = (0).toFixed(6).toString() + ' BTC';

    if (this.cryptoWalletModalRef.componentInstance.isSuccess) {
      this.BTCWallet.availableBalance += this.cryptoWalletModalRef.componentInstance.transactionAmount;
    }

    this.store.pipe(select(fromCrypto.getFundingResponse),
      takeWhile(() => this.componentActive))
      .subscribe(res => {

        if (res != null) {
          // this.BTCWallet.availableBalance = res.currentBalance;
        }

        if (res == null) {
          this.store.pipe(select(fromCrypto.getError),
            takeWhile(() => this.componentActive))
            .subscribe(err => {
              this.isError = true;
              this.errorMessage = err;
            });
        }

      });

    this.cryptoWalletModalRef.result
      .then((result) => {

        // this.cardsdataProvider.fetchData();
      }).catch((error) => { });
  }

  withDrawFromCryptoWallet() {
    this.cryptoWalletModalRef = this.modalService.open(
      FundCryptoWalletModalComponent,
      {
        windowClass: "cardModalForm",
        centered: true,
        backdrop: "static",
      }
    );

    this.cryptoWalletModalRef.componentInstance.modalContent = {
      title: "Send ETH",
    };
    this.cryptoWalletModalRef.componentInstance.cryptoCurrencyType = CryptoCurrencyType.Ethereum;
    this.cryptoWalletModalRef.componentInstance.actionButtonText = 'Send ETH';
    this.cryptoWalletModalRef.componentInstance.isWithdrawal = true;
    this.cryptoWalletModalRef.componentInstance.isFunding = false;
    this.cryptoWalletModalRef.componentInstance.isTransfer = false;
    this.cryptoWalletModalRef.componentInstance.isSuccess = false;
    this.cryptoWalletModalRef.componentInstance.successSubtitle = ' It may take some time to process';
    this.cryptoWalletModalRef.componentInstance.successTitle = 'Your transaction is on its way!';
    this.cryptoWalletModalRef.componentInstance.address = this.ETHWallet.address;
    this.cryptoWalletModalRef.componentInstance.balance = this.ETHWallet.availableBalance;
    this.cryptoWalletModalRef.componentInstance.minCryptoValue = 0.00001;
   

    this.store.pipe(select(fromCrypto.getWithdrawalResponse),
      takeWhile(() => this.componentActive))
      .subscribe(res => {

        if (res != null) {
          // this.BTCWallet.availableBalance = res.currentBalance;
        }

        if (res == null) {
          this.store.pipe(select(fromCrypto.getError),
            takeWhile(() => this.componentActive))
            .subscribe(err => {
              this.isError = true;
              this.errorMessage = err;
            });
        }

      });

    this.cryptoWalletModalRef.result
      .then((result: boolean) => {
        // this.cardsdataProvider.fetchData();
      })
      .catch((error) => { });
  }

  amountclass(destinationAddress: string) {
    return destinationAddress === this.ETHWallet.address.toLowerCase() ? "amount credit" : "amount debit";
  }

  transferToCryptoAddress() {
    this.cryptoWalletModalRef = this.modalService.open(
      FundCryptoWalletModalComponent,
      {
        windowClass: "cardModalForm",
        centered: true,
        backdrop: "static",
      }
    );

    this.cryptoWalletModalRef.componentInstance.modalContent = {
      title: "Send ETH",
    };
    this.cryptoWalletModalRef.componentInstance.cryptoCurrencyType = CryptoCurrencyType.Ethereum;
    this.cryptoWalletModalRef.componentInstance.actionButtonText = 'Send ETH';
    this.cryptoWalletModalRef.componentInstance.isWithdrawal = false;
    this.cryptoWalletModalRef.componentInstance.isFunding = false;
    this.cryptoWalletModalRef.componentInstance.isTransfer = true;
    this.cryptoWalletModalRef.componentInstance.isSuccess = false;
    this.cryptoWalletModalRef.componentInstance.userPhoneNumber = this.userPhoneNumber;
    this.cryptoWalletModalRef.componentInstance.successSubtitle = ' It may take some time to process';
    this.cryptoWalletModalRef.componentInstance.successTitle = 'Your transaction is on its way!';
    this.cryptoWalletModalRef.componentInstance.addressId = this.BTCWallet['cryptoCurrencyWalletId'];
    this.cryptoWalletModalRef.componentInstance.address = this.BTCWallet.address;
    this.cryptoWalletModalRef.componentInstance.minCryptoValue = 0.00001;
    this.cryptoWalletModalRef.componentInstance.BTCExchangeRate = this.BTCExchangeRate.buyingRate;
    this.cryptoWalletModalRef.componentInstance.cryptoWalletBalance = ` ${this.BTCWallet['availableBalance']} BTC`;
    this.cryptoWalletModalRef.componentInstance.walletId = this.BTCWallet['cryptoCurrencyWalletId'];
    this.cryptoWalletModalRef.componentInstance.transactionFee = this.BTCExchangeRate.transactionFee;
    this.cryptoWalletModalRef.componentInstance.cryptoTransactionFee = (this.BTCExchangeRate.transactionFee / this.BTCExchangeRate.sellingRate).toFixed(6).toString() + ' BTC';

    this.store.pipe(select(fromCrypto.getTransferResponse),
      takeWhile(() => this.componentActive))
      .subscribe(res => {

        if (res != null) {
          // this.BTCWallet.availableBalance = res.currentBalance;
        }

        if (res == null) {
          this.store.pipe(select(fromCrypto.getError),
            takeWhile(() => this.componentActive))
            .subscribe(err => {
              this.isError = true;
              this.errorMessage = err;
            });
        }

      });

    this.cryptoWalletModalRef.result
      .then((result: boolean) => {
        // this.cardsdataProvider.fetchData();
      })
      .catch((error) => { });
  }

}
