import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonlyUsedModule } from 'src/app/shared/modules/commonlyused.module';
import { FundCryptoWalletModalComponent } from './fund-crypto-wallet.component';
import { WalletrecentrecipientModule } from '../walletrecentrecipient/walletrecentrecipient.module';


@NgModule({
  declarations: [FundCryptoWalletModalComponent],
  exports: [FundCryptoWalletModalComponent],
  entryComponents: [FundCryptoWalletModalComponent],
  imports: [
    CommonModule,
    CommonlyUsedModule,
    WalletrecentrecipientModule
  ]
})
export class FundCryptoWalletModalModule { }
