
import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from "src/app/core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { FETCHUSERSTRANSACTIONS, FETCHUSERSTRANSACTIONSThisMonth, FETCHUSERSTRANSACTIONSToday, FETCHUSERSTRANSACTIONSYesterday, TransactionsActionTypes, UPDATEUSERSTRANSACTIONS, UPDATEUSERSTRANSACTIONSThisMonth, UPDATEUSERSTRANSACTIONSToday, UPDATEUSERSTRANSACTIONSYesterday } from './transactions.actions';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { UpdataCurrentUserBalance } from '../dashboarddata/dashboarddata.actions';
import { Donothing, LoginUserSuccess } from '../userdata/userdata.actions';
import { ReportService } from 'src/app/service/reports.service';
import { transactionType } from 'src/app/model/response/transactions.response';
import { Action } from '@ngrx/store';
import { GeneralUpdateError } from '../errormessages/errormessages.actions';

@Injectable({
  providedIn: "root"
})
export class TransactionsdataEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private loader: NgxSpinnerService,
    private userService: UserService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService, private reportService: ReportService) { }



  @Effect()
  fetchUserTransactions$: Observable<Action> = this.actions$.pipe(
    ofType(TransactionsActionTypes.FETCHUSERSTRANSACTIONS),
    map((action: FETCHUSERSTRANSACTIONS) => action.payload),
    mergeMap((params) => {
      return  this.reportService.getAllTransaction(params).pipe(
        map(data => {
          data.isLoading = false;
          data.isFirstLoad = true;
          return new UPDATEUSERSTRANSACTIONS(data);
        }),
        catchError(error => {

          return of(new Donothing());
        })
      );
    }
    )
  );

  @Effect()
  fetchUserTransactionsToday$: Observable<Action> = this.actions$.pipe(
    ofType(TransactionsActionTypes.FETCHUSERSTRANSACTIONSToday),
    map((action: FETCHUSERSTRANSACTIONSToday) => null),
    mergeMap((params) => {
      return  this.reportService.getAllTransactionToday().pipe(
        map(data => {
          data.isLoading = false;
          data.isFirstLoad = true;
          localStorage.removeItem("ttransactionstatstoday");
          localStorage.setItem("ttransactionstatstoday", JSON.stringify(data));
          return new UPDATEUSERSTRANSACTIONSToday(data);
        }),
        catchError(error => {

          return of(new Donothing());
        })
      );
    }
    )
  );

  @Effect()
  fetchUserTransactionsYesterday$: Observable<Action> = this.actions$.pipe(
    ofType(TransactionsActionTypes.FETCHUSERSTRANSACTIONSYesterday),
    map((action: FETCHUSERSTRANSACTIONSYesterday) => null),
    mergeMap((params) => {
      return  this.reportService.getAllTransactionYesterday().pipe(
        map(data => {
          data.isLoading = false;
          data.isFirstLoad = true;
          localStorage.removeItem("ttransactionstatsyesterday");
          localStorage.setItem("ttransactionstatsyesterday", JSON.stringify(data));
          return new UPDATEUSERSTRANSACTIONSYesterday(data);
        }),
        catchError(error => {

          return of(new Donothing());
        })
      );
    }
    )
  );

  @Effect()
  fetchUserTransactionsThisMonth$: Observable<Action> = this.actions$.pipe(
    ofType(TransactionsActionTypes.FETCHUSERSTRANSACTIONSThisMonth),
    map((action: FETCHUSERSTRANSACTIONSThisMonth) => null),
    mergeMap((params) => {
      return  this.reportService.getAllTransactionThisMonth().pipe(
        map(data => {
          data.isLoading = false;
          data.isFirstLoad = true;
          console.log("response" + new UPDATEUSERSTRANSACTIONSThisMonth(data));
          localStorage.removeItem("ttransactionstatsthismonth");
          localStorage.setItem("ttransactionstatsthismonth", JSON.stringify(data));
          return new UPDATEUSERSTRANSACTIONSThisMonth(data);
        }),
        catchError(error => {

          return of(new Donothing());
        })
      );
    }
    )
  );

}
