import { NavigationEnd, Router } from "@angular/router";
import { Location, JsonPipe } from "@angular/common";
import { filter } from "rxjs/operators";
import { Observable, BehaviorSubject, Subject } from "rxjs";

import { Injectable } from "@angular/core";
import { LoginResponse } from "../model/response/auth.response";
import { Settingsdetails } from "../model/response/settings.response";
import { SettingsProvider } from "../service/settings.service";
@Injectable()
export class SettingsdataProvider {
  defaultData: Settingsdetails = {
    isLoading: true,
    businessName: null,
    email: null,
    address: null,
    city: null,
    rcNumber: null,
    bvn: null,
    businessDocument: null,
    logo: null,
    hasTransactionPin: false,
    lastPasswordReset: null,
    lastTransactionPinReset: null,
    developerKeys: {
      hasKeys: false,
      requestedKeys: false,
      developerAccountStatus: 0,
      sandboxPublicKey: null,
      sandboxSecretKey: null,
      publicKey: null,
      secretKey: null,
      webHook: null,
    },
  };
  private settingsData$ = new BehaviorSubject<Settingsdetails>(
    this.defaultData
  );
  constructor(private settingsService: SettingsProvider) {}
  resetdata() {
    this.updateDetails({
      isLoading: true,
      businessName: null,
      email: null,
      address: null,
      city: null,
      rcNumber: null,
      bvn: null,
      businessDocument: null,
      logo: null,
      hasTransactionPin: false,
      lastPasswordReset: null,
      lastTransactionPinReset: null,
      developerKeys: {
        hasKeys: false,
        requestedKeys: false,
        developerAccountStatus: 0,
        sandboxPublicKey: null,
        sandboxSecretKey: null,
        publicKey: null,
        secretKey: null,
        webHook: null,
      },
      notifications: {
        emailNotifications: false,
        pushNotifications: false,
        smsNotifications: false,
      },
    });
  }
  fetchSettingsData() {
    this.settingsService.getSettingsPageData().subscribe(
      (data) => {
        // this.isLoadingData = false;
        const businessDetailsData: Settingsdetails = data;
        businessDetailsData.isLoading = false;
        this.updateDetails(businessDetailsData);
      },
      (error) => {}
    );
  }

  updateData(name, detail) {
    let data = this.settingsData$.getValue();
    data[name] = detail;
    this.settingsData$.next(data);
  }

  updateDetails(details: Settingsdetails) {
    this.settingsData$.next(details);
  }

  getSettingsData(): Observable<Settingsdetails> {
    return this.settingsData$.asObservable();
  }
}
