
import { act } from "@ngrx/effects";
import { LoginResponse } from "src/app/model/response/auth.response";
import { BulkAirtimeListRecipientDO } from 'src/app/model/response/bill.response';
import { BulkBankListRecipientDO, BulkInternationalListRecipientDO } from "src/app/model/response/transfer.response";
import { BulktransferdataActions, BulktransferActionTypes } from "./bulktransfers.actions";



export interface BulktransferState {
  message?: string;
  walletBulk?: BulkBankListRecipientDO[];
  bankBulk?: BulkBankListRecipientDO[];
  internationalBulk?: BulkInternationalListRecipientDO[];
  airtimeBulk?: BulkAirtimeListRecipientDO[];
}



const initialState: BulktransferState = {
  message: "",
  walletBulk: [],
  bankBulk: [],
  internationalBulk: [],
  airtimeBulk: []
};

export function Bulktransferdatareducer(state = initialState, action: BulktransferdataActions): BulktransferState {

  switch (action.type) {
    case BulktransferActionTypes.RESETBULKTRANSFERSTATE:
      return {
        ...initialState
      };

    //wallets
    case BulktransferActionTypes.UpdateAllBulkwalletLists:
      return {
        ...state,
        ...action.payload
      };
    case BulktransferActionTypes.UpdateSingleBulkwalletLists:
      return {
        ...state,
        walletBulk: [...state.walletBulk, action.payload]
      };
    case BulktransferActionTypes.UpdateDeleteBulkwalletLists:
      return {
        ...state,
        walletBulk: state.walletBulk.filter((list) => {
          return list.bulkRecipientId !== action.payload.bulkRecipientId;
        })
      };
    case BulktransferActionTypes.AddItemBulkwalletLists:
      return {
        ...state,
        walletBulk: state.walletBulk.map((list, i) => {
          if (list.bulkRecipientId === action.payload.bulkRecipientId) {
            return { ...list, recipients: [...list.recipients, action.payload] };
          }
          return list;
        })
      };
    case BulktransferActionTypes.UpdateWalletRecipientsList:
      return {
        ...state,
        walletBulk: state.walletBulk.map((list, i) => {
          if (list.bulkRecipientId === action.payload.bulkRecipientId) {
            return action.payload;
          }
          return list;
        })
      };
    //Banks
    case BulktransferActionTypes.UpdateAllBulkBanksLists:
      return {
        ...state,
        ...action.payload
      };
    case BulktransferActionTypes.UpdateSingleBulkBanksLists:
      return {
        ...state,
        bankBulk: [...state.bankBulk, action.payload]
      };
    case BulktransferActionTypes.UpdateDeleteBulkBanksLists:
      return {
        ...state,
        bankBulk: state.bankBulk.filter((list) => {
          return list.bulkRecipientId !== action.payload.bulkRecipientId;
        })
      };
    case BulktransferActionTypes.AddItemBulkwalletLists:
      return {
        ...state,
        bankBulk: state.bankBulk.map((list, i) => {
          if (list.bulkRecipientId === action.payload.bulkRecipientId) {
            return { ...list, recipients: [...list.recipients, action.payload] };
          }
          return list;
        })
      };
    case BulktransferActionTypes.UpdateBanksRecipientsList:
      return {
        ...state,
        bankBulk: state.bankBulk.map((list, i) => {
          if (list.bulkRecipientId === action.payload.bulkRecipientId) {
            return action.payload;
          }
          return list;
        })
      };

    //International
    case BulktransferActionTypes.UpdateAllBulkInternationalLists:

      return {
        ...state,
        ...action.payload
      };
    case BulktransferActionTypes.UpdateSingleBulkInternationalLists:
      return {
        ...state,
        internationalBulk: [...state.internationalBulk, action.payload]
      };
    case BulktransferActionTypes.UpdateDeleteBulkInternationalLists:
      return {
        ...state,
        internationalBulk: state.internationalBulk.filter((list) => {
          return list.bulkInternationalRecipientId !== action.payload.bulkInternationalRecipientId;
        })
      };
    case BulktransferActionTypes.AddItemBulkInternationalLists:
      return {
        ...state,
        internationalBulk: state.internationalBulk.map((list, i) => {
          if (list.bulkInternationalRecipientId === action.payload.bulkInternationalRecipientId) {


            return { ...list, recipients: [...list.recipients, action.payload] };
          }
          return list;
        })
      };
    case BulktransferActionTypes.UpdateInternationalRecipientsList:
      return {
        ...state,
        internationalBulk: state.internationalBulk.map((list, i) => {

          if (list.bulkInternationalRecipientId === action.payload.bulkInternationalRecipientId) {
            return action.payload;
          }
          return list;
        })
      };


    //International
    case BulktransferActionTypes.UpdateAllBulkAirtimeLists:

      return {
        ...state,
        ...action.payload
      };
    case BulktransferActionTypes.UpdateSingleBulkAirtimeLists:
      return {
        ...state,
        airtimeBulk: [...state.airtimeBulk, action.payload]
      };
    case BulktransferActionTypes.UpdateDeleteBulkAirtimeLists:
      return {
        ...state,
        airtimeBulk: state.airtimeBulk.filter((list) => {
          return list.bulkRecipientId !== action.payload.bulkRecipientId;
        })
      };
    case BulktransferActionTypes.AddItemBulkAirtimeLists:

      return {
        ...state,
        airtimeBulk: state.airtimeBulk.map((list, i) => {
          if (list.bulkRecipientId === action.payload.bulkAirtimeRecipientId) {


            return { ...list, recipients: [...list.recipients, action.payload] };
          }
          return list;
        })
      };
    case BulktransferActionTypes.UpdateAirtimeRecipientsList:
      return {
        ...state,
        airtimeBulk: state.airtimeBulk.map((list, i) => {

          if (list.bulkRecipientId === action.payload.bulkRecipientId) {
            return action.payload;
          }
          return list;
        })
      };



    default:
      return {
        ...state
      };
  }
}

