import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DefaultModalTitle, ModalTitle } from "../../../model/modal.ui";
import { RecentRecipients } from "../../../../model/response/recentrecipient.response";
import { WalletRecipientDO } from 'src/app/model/response/transfer.response';
import { TransferProvider } from 'src/app/service/transfer.service';
import { WalletsCryptoService } from 'src/app/state/crypto/crypto.service';
import { CryptoWalletBeneficiary } from 'src/app/model/request/fund_crypto_wallet.model';

@Component({
  selector: "app-recentrecipient",
  templateUrl: "./walletrecentrecipient.component.html",
  styleUrls: ["./walletrecentrecipient.component.scss"]
})
export class WalletrecentrecipientComponent implements OnInit {

  @Input() public modalContent: ModalTitle = DefaultModalTitle;
  savedRecipients: WalletRecipientDO[] = [];
  savedRecipientsList: WalletRecipientDO[] = [];
  public isLoading: boolean = true;
  search: string = "";
  isForCrypto: boolean;
  walletId: number;
  cryptoWalletBeneficiaries: CryptoWalletBeneficiary[] = [];
  cryptoWalletBeneficiaryList: CryptoWalletBeneficiary[] = [];

  constructor(public activeModal: NgbActiveModal,
              private transferService: TransferProvider,
              private cryptoService: WalletsCryptoService
              ) { }

  ngOnInit() {
    this.fetchRecipients();
    this.fetchCryptoWalletBeneficiaries();
  }

  fetchRecipients() {
    let fetchList = JSON.parse(localStorage.getItem("walletRecipients"));
    if (fetchList != null) {
      this.isLoading = false;
      this.savedRecipients = fetchList;
      this.savedRecipientsList = fetchList;
      this.transferService.getWalletRecipients().subscribe((data) => {
        this.savedRecipients = data;
        this.savedRecipientsList = data;
        localStorage.setItem("walletRecipients", JSON.stringify(data));
      });
    } else {
      this.transferService.getWalletRecipients().subscribe((data => {
        this.savedRecipients = data;
        this.savedRecipientsList = data;
        this.isLoading = false;
        localStorage.setItem("walletRecipients", JSON.stringify(data));
      }));
    }

  }

  fetchCryptoWalletBeneficiaries() {
    if(this.isForCrypto) {
      this.cryptoService.fetchBeneficiaries(this.walletId).subscribe((res: any[]) => {
        this.cryptoWalletBeneficiaries = res;
        this.cryptoWalletBeneficiaryList = res;
        this.isLoading = false;
      });
    }
  }

  searchList() {
    if (this.savedRecipients == null) {
      return [];
    }
    this.savedRecipientsList = this.savedRecipients.filter((item) => {

      return (
        item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        || item.phoneNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    });

  }

  searchCryptoWalletBeneficiaries() {
    if (this.cryptoWalletBeneficiaries == null) {
      return [];
    }

    this.cryptoWalletBeneficiaryList = this.cryptoWalletBeneficiaries.filter(beneficiary => {

      return beneficiary.beneficiaryName.toLowerCase().includes(this.search.toLowerCase()) || beneficiary.beneficiaryWalletAddress.toLowerCase().includes(this.search.toLowerCase());

    });
    
  }



}
