import { DashboardService } from "./dashboard.service";
import {
  BuyAirtimeDto,
  BulkairtimeListRecipientDTO,
  AddtoAirtimeBulkList,
} from "./../model/request/bill.request";

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import {
  AirtimeNetworksDo,
  BulkAirtimeListRecipientDO,
} from "../model/response/bill.response";
import { EndPoints } from "../config/endpoints";
import { catchError, map } from "rxjs/operators";
import {
  BulkTransferDto,
  DeleteRecipientFromBulkList,
} from "../model/request/transfer.request";

@Injectable()
export class AirtimeProvider {
  constructor(
    private http: HttpClient,
    private dashBoardService: DashboardService
  ) {}

  getNetworkProviders(): Observable<AirtimeNetworksDo[]> {
    return this.http
      .get<Array<any>>(EndPoints.BILLS.AIRTIME.GET_NETWORKS, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getBulkAirtimelists(): Observable<BulkAirtimeListRecipientDO[]> {
    return this.http
      .get<Array<any>>(EndPoints.BILLS.BULK_AIRTIME.GET_ALL_LISTS, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  postAirtime(model: BuyAirtimeDto): Observable<any> {
    return this.http
      .post(EndPoints.BILLS.AIRTIME.PAY, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  payBulkAirtime(model: BulkTransferDto): Observable<any> {
    return this.http
      .post(EndPoints.BILLS.BULK_AIRTIME.PAY, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  createBulkAirtime(model: BulkairtimeListRecipientDTO): Observable<any> {
    return this.http
      .post(EndPoints.BILLS.BULK_AIRTIME.CREATELIST, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deleteRecipientFromAirtimeList(
    model: DeleteRecipientFromBulkList
  ): Observable<any> {
    return this.http
      .post(EndPoints.BILLS.BULK_AIRTIME.DELETE_RECIPIENT, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          this.dashBoardService.fetchTheNewBalance();
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deleteAirtimeList(model: DeleteRecipientFromBulkList): Observable<any> {
    return this.http
      .post(EndPoints.BILLS.BULK_AIRTIME.DELETE_LIST, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  addRecipienttoAirtimeList(model: AddtoAirtimeBulkList): Observable<any> {
    return this.http
      .post(EndPoints.BILLS.BULK_AIRTIME.ADD_RECIPIENT, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  editRecipientAirtimeList(model: AddtoAirtimeBulkList): Observable<any> {
    return this.http
      .post(EndPoints.BILLS.BULK_AIRTIME.EDIT_RECIPIENT_LIST, model, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }
}
