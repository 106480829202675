import { Action } from "@ngrx/store";

export enum DashboardActionTypes {
  ResetDashboardState = "[DashboardDATA] Reset Data",
  FetchDashboardData = "[DashboardDATA] Fetch Dashboard Data",
  FetchRecentTransactions = "[DashboardDATA] Fetch Recent Transactions",
  UpdataRecentTransactions = "[DashboardDATA] Updata Recent Transactions",
  UpdateSOmeDashboardData = "[DashboardDATA] UpdateSOme Dashboard Data",
  UpdataDashboardData = "[DashboardDATA] Updata Dashboard Data",
  FetchUsersCards = "[DashboardDATA] Fetch User Cards",
  UpdataUsersCards = "[DashboardDATA] Updata User Cards",
  FetchCryptoFee = "[DashboardDATA] Fetch Crypto Fee",
  UpdatacryptoFee = "[DashboardDATA] Updata Crypto Fee",
  FetchBanks = "[DashboardDATA] Fetch Banks",
  UpdataBanks = "[DashboardDATA] Updata Banks",
  FetchCurrentUserBalance = "[DashboardDATA] Fetch Current User balance",
  UpdataCurrentUserBalance = "[DashboardDATA] Updata Current User balance",
}

// Action Creators
export class ResetDashboardState implements Action {
  readonly type = DashboardActionTypes.ResetDashboardState;
}

export class FetchDashboardData implements Action {
  readonly type = DashboardActionTypes.FetchDashboardData;
}
export class FetchRecentTransactions implements Action {
  readonly type = DashboardActionTypes.FetchRecentTransactions;
}

export class FetchUsersCards implements Action {
  readonly type = DashboardActionTypes.FetchUsersCards;
}

export class FetchBanks implements Action {
  readonly type = DashboardActionTypes.FetchBanks;
}

export class FetchCryptoFee implements Action {
  readonly type = DashboardActionTypes.FetchCryptoFee;
}

export class FetchCurrentUserBalance implements Action {
  readonly type = DashboardActionTypes.FetchCurrentUserBalance;
}

export class UpdataDashboardData implements Action {

  readonly type = DashboardActionTypes.UpdataDashboardData;

  constructor(public payload: any) {
    this.type = DashboardActionTypes.UpdataDashboardData;
  }
}

export class UpdataRecentTransactions implements Action {

  readonly type = DashboardActionTypes.UpdataRecentTransactions;

  constructor(public payload: any) {
    this.type = DashboardActionTypes.UpdataRecentTransactions;
  }
}

export class UpdataCurrentUserBalance implements Action {

  readonly type = DashboardActionTypes.UpdataCurrentUserBalance;

  constructor(public payload: any) {
    this.type = DashboardActionTypes.UpdataCurrentUserBalance;
  }
}

export class UpdateSOmeDashboardData implements Action {

  readonly type = DashboardActionTypes.UpdateSOmeDashboardData;

  constructor(public payload: any) {
    this.type = DashboardActionTypes.UpdateSOmeDashboardData;
  }
}

export class UpdataUsersCards implements Action {
  readonly type = DashboardActionTypes.UpdataUsersCards;

  constructor(public payload: any) {
    this.type = DashboardActionTypes.UpdataUsersCards;
  }
}

export class UpdatacryptoFee implements Action {
  readonly type = DashboardActionTypes.UpdatacryptoFee;

  constructor(public payload: any) {
    this.type = DashboardActionTypes.UpdatacryptoFee;
  }
}

export class UpdataBanks implements Action {
  readonly type = DashboardActionTypes.UpdataBanks;

  constructor(public payload: any) {
    this.type = DashboardActionTypes.UpdataBanks;
  }
}




export type DashboarddataActions =
  ResetDashboardState
  | UpdataDashboardData
  | UpdataRecentTransactions
  | UpdataUsersCards
  | UpdatacryptoFee
  | UpdataBanks
  | UpdataCurrentUserBalance
  | UpdateSOmeDashboardData;
