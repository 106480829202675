import { Observable, BehaviorSubject } from "rxjs";

import { Injectable } from "@angular/core";
import {
  AllsubwalletDo,
  SubwalletUserDo,
} from "../model/response/subwallet.response";
import { SubwalletProvider } from "../service/subwallet.service";
@Injectable()
export class SubwalletdataProvider {
  defaultData: AllsubwalletDo = {
    isLoading: true,
    isFilter: false,
    isFirstLoad: false,
    users: [],
    page: {
      totalPages: 0,
    },
  };
  private allSUbwallets$ = new BehaviorSubject<AllsubwalletDo>(
    this.defaultData
  );
  constructor(private subwalletService: SubwalletProvider) {}
  resetdata() {
    this.updateDetails({
      isLoading: true,
      isFilter: false,
      isFirstLoad: false,
      users: [],
      page: {
        totalPages: 0,
      },
    });
  }
  getSubwalletsData(): Observable<AllsubwalletDo> {
    return this.allSUbwallets$.asObservable();
  }
  updateDetails(details: AllsubwalletDo) {
    this.allSUbwallets$.next(details);
  }

  fetchSubwallets(page = 1, pageSize = 15, searchtext = "") {
    this.subwalletService.getAlluser(page, pageSize, searchtext).subscribe(
      (data) => {
        data.isLoading = false;
        data.isFirstLoad = true;
        this.updateDetails(data);
      },
      (error) => {}
    );
  }

  updateUserState(userdetails: SubwalletUserDo) {
    let allUsers = this.allSUbwallets$.getValue();
    let userIndex = allUsers.users.findIndex((user) => {
      return user.phoneNumber === userdetails.phoneNumber;
    });
    if (userIndex !== -1) {
      allUsers.users[userIndex] = userdetails;
      this.updateDetails(allUsers);
    }
  }

  deleteUserlState(userdetails: SubwalletUserDo) {
    let allUsers = this.allSUbwallets$.getValue();
    let userIndex = allUsers.users.findIndex((user) => {
      return user.phoneNumber === userdetails.phoneNumber;
    });
    if (userIndex !== -1) {
      allUsers.users.splice(userIndex, 1);
      this.updateDetails(allUsers);
    }
  }
}
