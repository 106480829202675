import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { EndPoints } from "../config/endpoints";
import { catchError, map } from "rxjs/operators";
import {
  FundingResponse,
  FullcardResponse,
} from "../model/response/funding.response";
import {
  FundingAmount,
  SavedCardsResponse,
  CardFunding,
  ValidateCard,
  FundViaSavedCards,
} from "../model/request/funding.request";

@Injectable()
export class CardFundingService {
  constructor(private http: HttpClient) {}

  cardFunding(data: CardFunding): Observable<FullcardResponse> {
    return this.http
      .post(EndPoints.CARDS.CARD_FUNNDING.SUBMITCARDFORFUNDING, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  
  verifyVisaCard(data: string): Observable<any> {
    return this.http
      .get(`${EndPoints.FUND_WALLET.CARD.CHECK_VISA_FUNDING_STATUS}?resp=${data}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getCards(): Observable<SavedCardsResponse> {
    return this.http
      .get(EndPoints.CARDS.CARD_FUNNDING.GET_CARDS, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  validateCard(data: ValidateCard): Observable<FullcardResponse> {
    return this.http
      .post(EndPoints.CARDS.CARD_FUNNDING.VALIDATECARD, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  useSavedCardtoFund(data: FundViaSavedCards): Observable<FullcardResponse> {
    return this.http
      .post(EndPoints.CARDS.CARD_FUNNDING.FUNDSAVEDCARDS, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .pipe(
        map((data) => {
          return data as any;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
