
import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from "src/app/core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { CardActionTypes, FethDollarCards, FethNairaCards, UpdateCardsRequests, UpdateNairaCards } from './carddata.actions';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { CardService } from 'src/app/service/cards.service';
import { Action } from 'rxjs/internal/scheduler/Action';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { Donothing } from '../userdata/userdata.actions';

@Injectable({
  providedIn: "root"
})
export class CarddataEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private loader: NgxSpinnerService,
    private userService: UserService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private cardService: CardService) { }




  fetchNairaCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CardActionTypes.FethNairaCards),
      mergeMap(() => this.cardService.fetchNairaCards().pipe(
        map(response => {
          let fetchData = {
            isLoading: false,
            nairacards: response,
          };

          return new UpdateCardsRequests({ allNairaCards: fetchData, isNairaFirstLoad: true });
        }),
        catchError(err => {
          return of(new Donothing());
        })
      )
      )
    )
  );

  fetchCardRequests$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CardActionTypes.FethCardsRequests),
    mergeMap(() =>  this.cardService.fetchNairaCardRequests().pipe(
      map(response => {
        const fetchData = {
          cardRequests: response,
          isLoading: false,
        };

        return new UpdateCardsRequests({ allCardsRequest: fetchData, isRequestFirstLoad: true });
      }),
      catchError(err => {
        return of(new Donothing());
      })
    )
    )
  )
);


fetchDollarCards$ = createEffect(() =>
this.actions$.pipe(
  ofType(CardActionTypes.FethDollarCards),
  mergeMap(() => this.cardService.getDollarCards().pipe(
    map(response => {


      const dollarCardFetched = response;
      dollarCardFetched.isLoading = false;
      dollarCardFetched.isFirstLoad = true;

      return new UpdateCardsRequests({ allDollarCards: dollarCardFetched, isDollarFirstLoad: true });
    }),
    catchError(err => {
      return of(new Donothing());
    })
  )
  )
)
);



}
