import { Action } from "@ngrx/store";

export enum RequestmoneyLinkActionTypes {
  ResetRequestMoneyLink = "[RequestmoneyLinkDATA] Reset Data",
  UpdateRequestmoney = "[RequestmoneyLinkDATA] UpdateRequestmoney Data",
  AddItemRequestmoney = "[RequestmoneyLinkDATA] AddItemRequestmoney Data",
  DeleteItemRequestmoney = "[RequestmoneyLinkDATA] DeleteItemRequestmoney Data",
}

// Action Creators
export class ResetRequestMoneyLink implements Action {
  readonly type = RequestmoneyLinkActionTypes.ResetRequestMoneyLink;
}

export class UpdateRequestmoney implements Action {
  readonly type = RequestmoneyLinkActionTypes.UpdateRequestmoney;
  constructor(public payload: any) {
    this.type = RequestmoneyLinkActionTypes.UpdateRequestmoney;
  }
}

export class AddItemRequestmoney implements Action {
  readonly type = RequestmoneyLinkActionTypes.AddItemRequestmoney;
  constructor(public payload: any) {
    this.type = RequestmoneyLinkActionTypes.AddItemRequestmoney;
  }
}

export class DeleteItemRequestmoney implements Action {
  readonly type = RequestmoneyLinkActionTypes.DeleteItemRequestmoney;
  constructor(public payload: any) {
    this.type = RequestmoneyLinkActionTypes.DeleteItemRequestmoney;
  }
}




export type RequestmoneydataActions =
  ResetRequestMoneyLink
  | UpdateRequestmoney
  | AddItemRequestmoney
  | DeleteItemRequestmoney;
