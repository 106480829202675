import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../state/app.state';
import * as fromCrypto from './crypto.reducer';
import { CryptoCurrencyType } from '../../model/request/crypto_currency.type';

export interface State extends fromRoot.AppState {
    crypto: fromCrypto.CryptoState;
}

// Selector functions
const getCryptoFeatureState = createFeatureSelector<fromCrypto.CryptoState>('crypto');

export const getCreatedAddress = createSelector(
    getCryptoFeatureState,
    state => state.cryptoAddressCreated
);

export const getError = createSelector(
    getCryptoFeatureState,
    state => state.error
);

// export const getBtcWallet = createSelector(
//     getCryptoFeatureState,
//     state => state.cryptoWallets.find(cw => cw.CryptoCurrencyType == CryptoCurrencyType.Bitcoin)
// );

// export const getEthWallet = createSelector(
//     getCryptoFeatureState,
//     state => state.cryptoWallets.find(cw => cw.CryptoCurrencyType == CryptoCurrencyType.Ethereum)
// );

export const getBTCWallet = createSelector(
    getCryptoFeatureState,
    state => state.BTCWallet
);

export const getFundingResponse = createSelector(
    getCryptoFeatureState,
    state => state.fundWalletResponse
);

export const getWithdrawalResponse = createSelector(
    getCryptoFeatureState,
    state => state.withdrawFromWalletResponse
);

export const getTransferResponse = createSelector(
    getCryptoFeatureState,
    state => state.transferToCryptoAddressResponse
);


export const getBTCExchangeRate = createSelector(
    getCryptoFeatureState,
    state => state.exchangeRates.find(er => er.CryptoCurrencyType == CryptoCurrencyType.Bitcoin)
);

export const getETHExchangeRate = createSelector(
    getCryptoFeatureState,
    state => state.exchangeRates.find(er => er.CryptoCurrencyType == CryptoCurrencyType.Ethereum)
);

export const getCryptoTransactions = createSelector(
    getCryptoFeatureState,
    state => state.cryptoTransactions
);