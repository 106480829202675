import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { LandingComponent } from "./pages/landing/landing.component";

const config: ExtraOptions = {
  useHash: false,
};
const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./layout/mainlayout/mainlayout.module").then(
        (m) => m.MainlayoutModule
      ),
  },
  // {
  //   path: "landing",
  //   component: LandingComponent
  // },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginpageModule),
  },
  {
    path: "authenticate/switchdevice",
    loadChildren: () =>
      import("./pages/authpage/authpage.module").then((m) => m.AuthPageModule),
  },
  {
    path: "authenticate/twofa",
    loadChildren: () =>
      import("./pages/authpage/authpage.module").then((m) => m.AuthPageModule),
  },
  {
    path: "signup",
    redirectTo: "signup/personal",
    // loadChildren: () =>
    //   import("./pages/signup/selectsignuptype/selectsignuptype.module").then(
    //     (m) => m.SelectsignupModule
    //   ),
      pathMatch: "full"
  },
  {
    path: "signup/business",
    loadChildren: () =>
      import("./pages/signup/business_signup/business_signup.module").then(
        (m) => m.BusinessSignupModule
      ),
  },
  {
    path: "signup/personal",
    loadChildren: () =>
      import("./pages/signup/walletsignup/walletsignup.module").then(
        (m) => m.WalletsignupModule
      ),
  },
  {
    path: "signup/personal/enterotp",
    loadChildren: () =>
      import("./pages/signup/walletotp/walletotp.module").then(
        (m) => m.WalletenterotpModule
      ),
  },
  {
    path: "signup/personal/enterdetails",
    loadChildren: () =>
    import("./pages/signup/personaldetails/personaldetails.module").then(
      (m) => m.EnterpersonaldetailsModule
    ),
  },
  {
    path: "verifyaccount",
    loadChildren: () =>
      import("./pages/setup/verify/verify.module").then(
        (m) => m.VerifyUserModule
      ),
  },
  {
    path: "verifydeveloper",
    loadChildren: () =>
      import("./pages/setup/verifydeveloper/verifydeveloper.module").then(
        (m) => m.VerifyDeveloperModule
      ),
  },
  {
    path: "forgotpassword",
    loadChildren: () =>
      import("./pages/forgotpassword/forgotpassword.module").then(
        (m) => m.ForgotpasswordModule
      ),
  },
  {
    path: "setup/setpassword",
    loadChildren: () =>
      import("./pages/setup/setpassword/setpassword.module").then(
        (m) => m.SetpasswordModule
      ),
  },
  {
    path: "setup/updateprofile",
    loadChildren: () =>
      import("./pages/setup/updateprofile/updateprofile.module").then(
        (m) => m.UpdateprofileModule
      ),
  },
  {
    path: "resetpassword",
    loadChildren: () =>
      import("./pages/resetpassword/resetpassword.module").then(
        (m) => m.ResetpasswordModule
      ),
  },
  {
    path: "**",
    loadChildren: () =>
      import("./pages/pagenotfound/pagenotfound.module").then(
        (m) => m.PagenotfoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, config), ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
