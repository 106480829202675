import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location, JsonPipe } from "@angular/common";
import { filter, takeWhile } from "rxjs/operators";
import { LoginResponse } from "src/app/model/response/auth.response";
import { select, Store } from '@ngrx/store';
import { CardState } from 'src/app/store/carddata/carddata.reducer';
import { DashboardState } from 'src/app/store/dashboarddata/dashboarddata.reducer';
import { SettingsState } from 'src/app/store/settingsdata/settingsdata.reducer';
import { UserdataState } from 'src/app/store/userdata/userdata.reducer';
import { selectAllUserData } from 'src/app/store/userdata';

declare const ga: any;

@Injectable()
export class AnalyticsService {
  private enabled: boolean;
  private businessdata: string;
  componentActive: true;
  constructor(
    private store: Store<{
      userdata: UserdataState,
      settingsdata: SettingsState,
      dashboarddata: DashboardState,
      carddata: CardState
    }>
  ) {
    this.enabled = true;
    this.store.pipe(select(selectAllUserData),
      takeWhile(() => this.componentActive))
      .subscribe(data => {
        let businessdata = data;
        if (!this.isEmpty(businessdata)) {
          let jsonData = {
            email: businessdata.data.email,
            name: businessdata.data.businessName,
            isvalidatad: businessdata.data.isAccountCompleted,
          };
          this.businessdata = JSON.stringify(jsonData);
        } else {
          this.businessdata = "Not logged in ";
        }
      });

  }

  trackPageViews(event: any) {
    if (this.enabled) {
      (window as any).ga("set", "page", event.urlAfterRedirects);
      (window as any).ga("send", "pageview");
    }
  }
  isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  trackEvent(eventCategory, eventAction, eventLabel, eventValue) {
    eventLabel = eventLabel === null ? this.businessdata : eventLabel;
    if (this.enabled) {
      (window as any).ga("send", "event", {
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
      });
    }
  }
}
